import { Layout, Government } from '../components';
import { ResultDetailWrapper } from '../components/results';

const ResultsGovernment = () => {
  return (
    <Layout
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
    >
      <ResultDetailWrapper flexDirection="column">
        <Government />
      </ResultDetailWrapper>
    </Layout>
  );
};

export default ResultsGovernment;
