import { useState } from 'react';
import { TrackingSignatureValue, useTracking } from '@facephi/sdk-web';
import { useRecoilValue } from 'recoil';
import { RequestMethods, useRequest, useLogger } from '../hooks';
import { useAuth } from '../providers';
import { endPoints } from '../states';
import { operationIdState } from '../states/atoms/operationId';
import {
  LivenessStatusResponse,
  OperationResultReason,
  ResponseFacialAuthentication,
  ResponseLiveness,
  FacialAuthenticationStatus,
  ResponseAntispoof,
} from '../states/model';
import { getBase64Image } from '../utils';
import { useVariables } from './useVariables';

export function useFacialValidation() {
  const { sessionId, trackingSignature } = useTracking();
  const { demoVersion } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const { apiDemoUrl } = useVariables();
  const { request: validateFacialRequest } = useRequest(apiDemoUrl);
  const operationId = useRecoilValue(operationIdState);
  const { captureException } = useLogger();

  const onValidateTransfer = async (
    image: string,
    selfie: string
  ): Promise<boolean> => {
    setLoading(true);
    try {
      const [responseFacial, responseLiveness] = await Promise.all([
        onValidateFacial(image, selfie),
        onValidateLiveness(image),
      ]);
      setLoading(false);
      return (
        responseFacial.authStatus === FacialAuthenticationStatus.positive &&
        responseLiveness.diagnostic === LivenessStatusResponse.Live
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      captureException(error, { operation: 'validateTransfer' });
      setError(OperationResultReason.facialLivenessError);
      setLoading(false);
      throw new Error(error);
    }
  };

  const onValidateFacial = async (
    image: string,
    selfie: string,
    operation?: string,
    session?: string
  ): Promise<ResponseFacialAuthentication> => {
    return validateFacialRequest(endPoints.Authenticate.Matching, {
      method: RequestMethods.post,
      headers: {
        'x-inphinite-sessionid': sessionId || session,
        'x-inphinite-operationid': operationId || operation,
        'x-demo-app-version': demoVersion,
      },
      data: {
        image1: selfie && getBase64Image(selfie),
        image2: image && getBase64Image(image),
      },
    });
  };

  const onValidateLiveness = async (
    image: string,
    operation?: string,
    session?: string
  ): Promise<ResponseLiveness> => {
    return validateFacialRequest(endPoints.Authenticate.Liveness, {
      method: RequestMethods.post,
      headers: {
        'x-inphinite-sessionid': sessionId || session,
        'x-inphinite-operationid': operationId || operation,
        'x-demo-app-version': demoVersion,
      },
      data: {
        image: getBase64Image(image),
      },
    });
  };

  const onSignDocument = (
    signatureValue: TrackingSignatureValue
  ): Promise<TrackingSignatureValue> => {
    try {
      trackingSignature(signatureValue, operationId);
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      captureException(e as any, {
        operation: 'signDocument',
        value: signatureValue,
      });
      throw new Error(e as string);
    }

    return new Promise((resolve) => {
      setTimeout(() => resolve(signatureValue), 2000);
    });
  };

  const onValidateSpoof = async (
    encryptedLivenessRaw: Blob | File,
    operation?: string,
    session?: string
  ): Promise<ResponseAntispoof> => {
    return validateFacialRequest(endPoints.Authenticate.Antispoofing, {
      method: RequestMethods.post,
      headers: {
        'Content-Type': 'application/octet-stream',
        'x-inphinite-sessionid': sessionId || session,
        'x-inphinite-operationid': operationId || operation,
        'x-demo-app-version': demoVersion,
      },
      data: encryptedLivenessRaw,
    });
  };

  return {
    loading,
    error,
    onValidateTransfer,
    onValidateFacial,
    onValidateLiveness,
    onSignDocument,
    onValidateSpoof,
  };
}
