type VideoAssistanceContent = {
  name: string;
  testId: string;
};

type SignContent = VideoAssistanceContent;

export enum ISignType {
  videoRecording = 'videoRecording',
  selfie = 'selfie',
}

export enum IVideoAssistanceType {
  start = 'start',
  error = 'error',
}

export const SignList: { [key in ISignType]: SignContent } = {
  [ISignType.videoRecording]: {
    name: 'Document and face',
    testId: 'settings_signature_video_face_document_option',
  },
  [ISignType.selfie]: {
    name: 'Face',
    testId: 'settings_signature_face_option',
  },
};

export const VideoAssistanceList: {
  [key in IVideoAssistanceType]: VideoAssistanceContent;
} = {
  [IVideoAssistanceType.start]: {
    name: 'From the start',
    testId: 'settings_video_videocall_before_onboarding_option',
  },
  [IVideoAssistanceType.error]: {
    name: 'When an error occurs',
    testId: 'settings_video_videocall_after_onboarding_option',
  },
};
