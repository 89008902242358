import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { WorkflowDTO } from '@facephi/sdk-web';
import { CaptureMode } from '@facephi/selphid-widget';
import { useRecoilValue } from 'recoil';
import { useVariables, useWorkflow } from '../hooks';
import {
  documentTypeState,
  logImagesState,
  previewCaptureState,
  countryState,
} from '../states/atoms';
import { DEFAULT_COUNTRY, TypeDocument } from '../states/constants';

type IProps = {
  children: React.ReactNode;
};

type ContextProps = {
  getWorkflow(): WorkflowDTO;
};

const Ctx = createContext<ContextProps>({
  getWorkflow: () => [] as WorkflowDTO,
});

export const ConfigProvider = ({ children }: IProps) => {
  const { loading, getWorkflow: getConfigWorkflow } = useWorkflow();
  const { licenseKeyThirdParty } = useVariables();
  const documentType = useRecoilValue(documentTypeState);
  const previewCapture = useRecoilValue(previewCaptureState);
  const logImages = useRecoilValue(logImagesState);
  const country = useRecoilValue(countryState);
  const [captureMode, setCaptureMode] = useState<CaptureMode>(CaptureMode.Full);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [licenseKey, setLicenseKey] = useState<string | undefined>(
    licenseKeyThirdParty
  );

  const countryConfig = useMemo(
    () => (country === DEFAULT_COUNTRY ? '' : country),
    [country]
  );

  useEffect(() => {
    if (documentType === TypeDocument.passport) {
      setCaptureMode(CaptureMode.Front);
    } else {
      setCaptureMode(CaptureMode.Full);
    }
  }, [documentType]);

  const getWorkflow = () => {
    return getConfigWorkflow({
      previewCapture,
      logImages,
      country: countryConfig,
      captureMode: captureMode,
      licenseKey: licenseKey,
      retryOnlyCurrentSide: true,
      documentType,
    });
  };

  return (
    <Ctx.Provider
      value={{
        getWorkflow,
      }}
    >
      {!loading && children}
    </Ctx.Provider>
  );
};

export const useConfig = () => useContext(Ctx);
