import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { IVideoAssistanceType } from '../model';

const { persistAtom } = recoilPersist();

export const videoAssistanceState = atom<boolean>({
  key: 'videoAssistanceState',
  default: false,
  effects_UNSTABLE: [persistAtom],
});

export const videoAssistanceTypeState = atom<IVideoAssistanceType>({
  key: 'videoAssistanceTypeState',
  default: IVideoAssistanceType.error,
  effects_UNSTABLE: [persistAtom],
});
