import { GridContainer, Icon, Label, useDeviceSize } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { ConfigurationLink } from './Styles';

type Props = {
  route: string;
  linkLabel: string;
  title: string;
};

export const GoBackHeader = ({ route, title, linkLabel }: Props) => {
  const { t } = useTranslation();
  const { isMobile } = useDeviceSize();

  const isConfigPage = title === 'Configuration';

  return (
    <GridContainer gridTemplateColumns="1fr 1fr 1fr" alignItems="center">
      <ConfigurationLink to={route} data-test="go-back-button">
        <Icon iconName="CaretLeft" size="32" />
        {!isMobile && (
          <Label
            as="span"
            fontSize={isMobile ? '12' : '14'}
            semibold
            color="purple400"
          >
            {t(linkLabel)}
          </Label>
        )}
      </ConfigurationLink>
      <Label
        as="h1"
        fontSize={isMobile ? (isConfigPage ? '16' : '12') : '32'}
        semibold
        textAlign="center"
      >
        {t(title)}
      </Label>
    </GridContainer>
  );
};
