import { Label } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import facial from '../../assets/json/facialRecognition.json';
import { useDeviceSize } from '../../hooks/useDeviceSize';
import { LottieAnimation } from '../lottieAnimation';
import { ContainerValidating } from './Styles';

export const ValidatingIdentity = () => {
  const { t } = useTranslation();
  const { isDesktop } = useDeviceSize();

  return (
    <ContainerValidating
      flexDirection="column"
      flex="1"
      alignItems="center"
      rowGap={isDesktop ? '4' : '1.6'}
    >
      <LottieAnimation animation={facial} style={{ height: '30.5rem' }} />
      <Label fontSize="16" semibold textAlign="center">
        {t('Validating your identity')}...
      </Label>
    </ContainerValidating>
  );
};
