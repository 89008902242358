import { useEffect, useState } from 'react';
import {
  Authentication,
  FlowNode,
  isNode,
  Onboarding,
  WorkflowDTO,
} from '@facephi/sdk-web';
import { CaptureMode } from '@facephi/selphid-widget';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import workFlowJson from '../assets/json/workflow.json';
import { WidgetIdList, WidgetNameList } from '../states';
import { antispoofingState, widgetLogState } from '../states/atoms';

type Widgets = {
  [key in WidgetNameList]: Authentication | Onboarding;
};

type IConfig = {
  logImages: boolean;
  previewCapture: boolean;
  country: string;
  captureMode: CaptureMode;
  licenseKey?: string;
  documentType?: string;
  retryOnlyCurrentSide: boolean;
  antispoofEnabled?: boolean;
};

type OnboardingDemo = Onboarding & {
  cameraHeight?: number;
  cameraWidth?: number;
};

export function useWorkflow(): {
  loading: boolean;
  config: Authentication & OnboardingDemo;
  getWorkflow(config: IConfig): WorkflowDTO;
} {
  const { i18n } = useTranslation();
  const [workflow] = useState<WorkflowDTO>(
    workFlowJson.data.flow.definition as WorkflowDTO
  );

  const [loading, setLoading] = useState<boolean>(true);
  const [config, setConfig] = useState<Widgets>();
  const antispoofing = useRecoilValue(antispoofingState);
  const widgetLog = useRecoilValue(widgetLogState);

  const getWidgetCofig = (id: string) => {
    const node = workflow.find(
      (item) => isNode(item) && (item as FlowNode).data.widgetId === id
    );
    return (node as FlowNode)?.data?.configuration?.general || {};
  };

  useEffect(() => {
    setConfig({
      [WidgetNameList.selphi]: getWidgetCofig(
        WidgetIdList[WidgetNameList.selphi]
      ) as Authentication,
      [WidgetNameList.selphid]: getWidgetCofig(
        WidgetIdList[WidgetNameList.selphid]
      ) as OnboardingDemo,
    });

    setLoading(false);
  }, [workflow]);

  const getWorkflow = ({
    logImages,
    previewCapture,
    country,
    captureMode,
    licenseKey,
    documentType,
    retryOnlyCurrentSide,
  }: IConfig) =>
    workflow.map((item) => {
      if (isNode(item)) {
        const node = cloneDeep(item as FlowNode);
        if (node.data.configuration) {
          node.data.configuration.general = {
            ...node.data.configuration?.general,
            language: i18n.language,
          };
          if (node.data.widgetId === WidgetIdList.selphi) {
            node.data.configuration.general = {
              ...node.data.configuration.general,
              logImages,
              antispoofEnabled: antispoofing,
              showLog: widgetLog,
              interactible: false,
            };
          }
          if (node.data.widgetId === WidgetIdList.selphid) {
            node.data.configuration.general = {
              ...node.data.configuration.general,
              logImages,
              previewCapture,
              // TO DO REPLACE THIS PATCH WHEN WIDGETS TEAM MAKE ADJUSMENTS
              country: country === 'BR' ? undefined : country,
              captureMode,
              licenseKey,
              documentType,
              allowUncertain:
                country === '' || country === 'BR' || country === 'ZA',
              retryOnlyCurrentSide,
              cameraWidth: 1920,
              cameraHeight: 1080,
              showLog: widgetLog,
            };
          }
        }
        return node;
      }
      return item;
    });

  const getSelphiConfig = () =>
    (config?.[WidgetNameList.selphi] || {}) as Authentication;

  const getSelphidConfig = () =>
    (config?.[WidgetNameList.selphid] || {}) as OnboardingDemo;

  return {
    loading,
    config: {
      previewCapture: getSelphidConfig().previewCapture,
      country: getSelphidConfig().country,
      logImages: getSelphiConfig().logImages,
      className: '',
      language: '',
      captureMode: getSelphidConfig().captureMode || CaptureMode.Full,
      licenseKey: getSelphidConfig().licenseKey,
      documentType: getSelphidConfig().documentType,
      cameraHeight: getSelphidConfig().cameraHeight,
      cameraWidth: getSelphidConfig().cameraWidth,
    },
    getWorkflow,
  };
}
