/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import { CSSProperties } from 'styled-components';

type Props = {
  animation?: Record<string, string | unknown>;
  className?: string;
  style?: CSSProperties;
  loop?: boolean;
  preserveAspectRatio?: string;
};

export const LottieAnimation = ({
  animation,
  className,
  style,
  loop = true,
  preserveAspectRatio = 'xMidYMid meet',
}: Props) => {
  const innerRef = useRef<HTMLDivElement>(null);

  const renderAnimation = () => {
    lottie.loadAnimation({
      renderer: 'svg',
      loop: loop,
      autoplay: true,
      animationData: animation,
      container: innerRef.current as any,
      rendererSettings: {
        preserveAspectRatio: preserveAspectRatio,
      },
    });
  };

  useEffect(() => {
    renderAnimation();

    return () => lottie.destroy();
  }, [innerRef]);

  return <div className={className} ref={innerRef} style={style} />;
};
