/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react';
import { Button } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../providers';
import { ModalDecline } from './ModalDecline';
import {
  ModalTermsContent,
  ModalTermsStyles,
  ButtonsContainer,
  ButtonLink,
} from './Styles';
import { TermsCondition } from './TermsCondition';

export const ModalRgpd = ({
  switchModal,
  rgpdRead,
  changeRgpdRead,
}: {
  switchModal: any;
  rgpdRead: boolean;
  changeRgpdRead: any;
}) => {
  const [bottom, setBottom] = useState<boolean>(false);
  const { t } = useTranslation();
  const { logout, acceptTerms } = useAuth();
  const [showDeclineModal, setShowDeclineModal] = useState<boolean>(false);

  const handleDeclineTerms = () => {
    setShowDeclineModal(true);
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    if (
      Math.round(
        event.currentTarget.scrollHeight - event.currentTarget.scrollTop
      ) -
        100 <=
      event.currentTarget.clientHeight
    ) {
      setBottom(true);
      changeRgpdRead(true);
    }
  };

  const handleAcceptTerms = () => {
    acceptTerms();
  };

  useEffect(() => {
    if (rgpdRead) {
      setBottom(true);
      const objElement = document.getElementById('disclaimer_content_text');
      (objElement as HTMLDivElement).scrollTop =
        objElement?.scrollHeight as number;
    }
  }, []);

  return (
    <ModalTermsStyles flexDirection="column" rowGap="1">
      <ModalTermsContent
        onScroll={handleScroll}
        data-test="rgpd"
        id="disclaimer_content_text"
      >
        <TermsCondition keyValue="rgpd" data-test="disclaimer_content_text" />{' '}
        <ButtonLink
          size="S"
          variant="text"
          onClick={() => switchModal(true)}
          testId="login_terms_and_conditions_text_button"
          color="primary"
        >
          {t('Terms and conditions')}
        </ButtonLink>
      </ModalTermsContent>
      <ButtonsContainer columnGap="1.6" justifyContent="flex-end">
        <Button
          variant="text"
          color="error"
          size="L"
          testId="disclaimer_decline_button"
          onClick={handleDeclineTerms}
        >
          {t('Decline').toLocaleUpperCase()}
        </Button>
        <Button
          size="L"
          disabled={!bottom}
          onClick={handleAcceptTerms}
          testId="disclaimer_accept_button"
          color="primary"
        >
          {t('Accept').toLocaleUpperCase()}
        </Button>
      </ButtonsContainer>

      <ModalDecline
        show={showDeclineModal}
        onChangeShow={(showModal) => setShowDeclineModal(showModal)}
        onDecline={logout}
      />
    </ModalTermsStyles>
  );
};
