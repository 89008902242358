export const EmptyFilesError = ({
  width,
  height,
}: {
  width?: string;
  height?: string;
}) => (
  <svg
    width={width || '147'}
    height={height || '147'}
    viewBox="0 0 147 147"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.6215 70.5146C29.1416 68.5082 31.4578 67.3579 33.9746 67.3579H42.2557C42.8404 63.5325 46.1519 60.5912 50.137 60.5912H107.481L108.075 59.1924C109.328 56.2418 112.209 54.3364 115.412 54.3364H120.058C112.344 29.498 89.1936 11.4609 61.8346 11.4609C28.1676 11.4609 0.875 38.7712 0.875 72.4599C0.875 98.7995 17.5596 121.239 40.9296 129.776L26.2979 77.4874C25.6189 75.0621 26.1027 72.5211 27.6228 70.5146H27.6215Z"
      fill="#EAEEF6"
    />
    <path
      d="M142.888 65.9098H137.824C136.759 65.9098 135.795 65.2728 135.379 64.2916L134.096 61.2745C133.679 60.2931 132.717 59.6562 131.65 59.6562H115.41C114.345 59.6562 113.381 60.2931 112.964 61.2745L111.682 64.2916C111.264 65.2728 110.302 65.9098 109.235 65.9098H50.1361C48.6678 65.9098 47.4785 67.0998 47.4785 68.569V72.6765H33.9737C32.2143 72.6765 30.94 74.3571 31.4144 76.0525L46.935 131.515C47.2565 132.663 48.3024 133.458 49.4943 133.458H142.888C144.356 133.458 145.545 132.267 145.545 130.798V68.569C145.545 67.0998 144.356 65.9098 142.888 65.9098Z"
      fill="white"
    />
    <path
      d="M142.889 64.5811H137.826C137.292 64.5811 136.813 64.2632 136.604 63.7713L135.321 60.7542C134.695 59.2795 133.255 58.3262 131.653 58.3262H115.413C113.812 58.3262 112.371 59.2795 111.744 60.7542L110.462 63.7713C110.253 64.2632 109.774 64.5811 109.239 64.5811H50.1376C47.9397 64.5811 46.1511 66.3707 46.1511 68.57V71.3477H33.9752C32.7167 71.3477 31.5593 71.9236 30.7993 72.9261C30.0392 73.9287 29.7974 75.1998 30.1362 76.4125L45.6568 131.874C46.1365 133.59 47.7151 134.788 49.4958 134.788V134.736C49.7044 134.771 49.9196 134.788 50.1376 134.788H142.889C145.087 134.788 146.876 132.999 146.876 130.799V68.57C146.876 66.3707 145.087 64.5811 142.889 64.5811ZM46.1511 123.778L32.6955 75.6958C32.5826 75.2916 32.6623 74.8687 32.9161 74.5336C33.1699 74.1986 33.5552 74.0071 33.9752 74.0071H46.1511V123.778ZM144.218 130.799C144.218 131.532 143.621 132.129 142.889 132.129H50.1376C49.4054 132.129 48.8088 131.532 48.8088 130.799V68.57C48.8088 67.8374 49.4054 67.2403 50.1376 67.2403H109.239C110.841 67.2403 112.281 66.2871 112.908 64.8124L114.191 61.7953C114.399 61.3034 114.879 60.9855 115.413 60.9855H131.653C132.187 60.9855 132.667 61.3034 132.876 61.7953L134.158 64.8124C134.785 66.2871 136.223 67.2403 137.826 67.2403H142.889C143.621 67.2403 144.218 67.8374 144.218 68.57V130.799Z"
      fill="#EAEEF6"
    />
    <path
      d="M113.498 112.33C114.159 112.998 114.53 113.9 114.53 114.839C114.53 115.779 114.159 116.681 113.498 117.348C112.825 117.999 111.926 118.363 110.99 118.363C110.055 118.363 109.155 117.999 108.483 117.348L96.9114 105.728L85.3403 117.348C84.6675 117.999 83.7683 118.363 82.8325 118.363C81.8967 118.363 80.9975 117.999 80.3247 117.348C79.6638 116.681 79.293 115.779 79.293 114.839C79.293 113.9 79.6638 112.998 80.3247 112.33L91.9398 100.755L80.3247 89.1786C79.7634 88.4945 79.4766 87.6259 79.52 86.7419C79.5634 85.8579 79.9339 85.0216 80.5595 84.3958C81.1851 83.77 82.021 83.3993 82.9046 83.3559C83.7882 83.3124 84.6564 83.5994 85.3403 84.1609L96.9114 95.7808L108.483 84.1609C109.166 83.5994 110.035 83.3124 110.918 83.3559C111.802 83.3993 112.638 83.77 113.263 84.3958C113.889 85.0216 114.259 85.8579 114.303 86.7419C114.346 87.6259 114.059 88.4945 113.498 89.1786L101.883 100.755L113.498 112.33Z"
      fill="#FB8D89"
    />
  </svg>
);
