import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { DEFAULT_COUNTRY } from '../constants';

const { persistAtom } = recoilPersist();
export const countryState = atom<string>({
  key: 'countryState',
  default: DEFAULT_COUNTRY,
  effects_UNSTABLE: [persistAtom],
});
