export enum ActionRequest {
  'RESET',
  'SET_DATA',
  'START_REQUEST',
  'SET_ERROR',
}

export type StateRequest<T> = {
  loading: boolean;
  data?: T;
  error: boolean;
};

export type ActionTypeRequest<T> =
  | { type: ActionRequest.RESET }
  | { type: ActionRequest.SET_DATA; payload: T }
  | { type: ActionRequest.START_REQUEST }
  | { type: ActionRequest.SET_ERROR };

export const createRequestReducer =
  <T>() =>
  (state: StateRequest<T>, action: ActionTypeRequest<T>): StateRequest<T> => {
    switch (action.type) {
      case ActionRequest.RESET: {
        return {
          ...state,
          loading: false,
          data: undefined,
          error: false,
        };
      }
      case ActionRequest.SET_DATA: {
        return {
          ...state,
          loading: false,
          data: { ...state.data, ...action.payload },
        };
      }
      case ActionRequest.START_REQUEST: {
        return {
          ...state,
          loading: true,
          error: false,
          data: undefined,
        };
      }
      case ActionRequest.SET_ERROR: {
        return {
          ...state,
          loading: false,
          error: true,
        };
      }
    }
  };
