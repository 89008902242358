import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useGeolocation } from '../../providers';
import { geolocationState } from '../../states/atoms';

export const Geolocation = () => {
  const { geolocate } = useGeolocation();
  const geolocation = useRecoilValue(geolocationState);

  useEffect(() => {
    if (geolocation) {
      geolocate();
    }
  }, [geolocation]);

  return <></>;
};
