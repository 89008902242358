import { FlexContainer } from '@facephi/ui-react';
import styled from 'styled-components/macro';

export const TransitionWrapper = styled(FlexContainer)`
  padding-top: 3.2rem;
`;

export const LabelsWrapper = styled(FlexContainer)`
  max-width: 36rem;
`;
