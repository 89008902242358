import { Storage } from '../states';

export const useOperationId = () => {
  const arrOperationsId = JSON.parse(
    localStorage.getItem(Storage.operationId) || '[]'
  );

  const checkOperationIdExist = (operationId?: string) => {
    return operationId && arrOperationsId.includes(operationId);
  };

  const clearStorage = () => localStorage.removeItem(Storage.operationId);

  const setStorage = (operationId?: string) => {
    if (!checkOperationIdExist(operationId)) {
      arrOperationsId.push(operationId);
      localStorage.setItem(
        Storage.operationId,
        JSON.stringify(arrOperationsId)
      );
    }
  };

  return { checkOperationIdExist, clearStorage, setStorage };
};
