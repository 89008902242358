import {
  Button,
  FlexContainer,
  Input,
  Label,
  LogoOnboarding,
  setColorTheme,
  setShadowTheme,
  setSizeLabel,
} from '@facephi/ui-react';
import styled from 'styled-components/macro';
import { desktopStyles, mobileStyles } from '../../styles';
import { Layout } from '../layout';

const GeneralCard = styled(FlexContainer).attrs({
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})``;

export const CardContainer = styled(GeneralCard)`
  perspective: 100rem;
`;

export const Card = styled(GeneralCard)<{ $flipped: boolean }>`
  margin-top: 11.5rem;
  transition: transform 0.8s;
  transform-style: preserve-3d;

  ${mobileStyles} {
    margin-top: 0;
  }

  ${({ $flipped }) =>
    $flipped &&
    `
  transform: rotateY(180deg);
`}
`;

export const FrontFace = styled(GeneralCard)`
  backface-visibility: hidden;
`;

export const LoginMessage = styled(Label)`
  margin-bottom: 3.2rem;
  color: ${setColorTheme('grey500')};
  ${setSizeLabel('14')};
`;

export const BackFace = styled.div`
  position: absolute;
  inset: 0;
  transform: rotateY(180deg);
  backface-visibility: hidden;

  ${LoginMessage} {
    white-space: nowrap;
    margin-bottom: 0;
  }
`;

export const LoginLayout = styled(Layout)`
  ${mobileStyles} {
    background-color: ${setColorTheme('white')};
  }
`;

export const LogoLogin = styled(LogoOnboarding)`
  margin-bottom: 2.4rem;
`;

const WIDTH_ELEMENTS = '35rem';

export const LoginCard = styled(GeneralCard)`
  border-radius: 2.4rem;
  padding: 6.4rem;
  background-color: ${setColorTheme('white')};

  ${desktopStyles} {
    box-shadow: ${setShadowTheme('big')};
  }

  ${mobileStyles} {
    padding: 1.6rem;
  }
`;

export const InputLogin = styled(Input)`
  width: 100%;
  margin-bottom: 2.4rem;

  label {
    color: ${setColorTheme('grey700')};
    font-weight: 600;
    ${setSizeLabel('12')};
  }

  input {
    ${setSizeLabel('12')};
  }
`;

export const LoginForm = styled(FlexContainer)`
  width: ${WIDTH_ELEMENTS};
  ${mobileStyles} {
    width: 100%;
  }
`;

export const ButtonLogin = styled(Button)`
  width: 100%;
  margin-bottom: 1.6rem;
`;

export const LoginError = styled(FlexContainer)`
  width: 100%;
  border-radius: 0.8rem;
  margin-bottom: 2.4rem;
  padding: 1.2rem 1rem;
  background-color: ${setColorTheme('error100')};

  > p {
    color: ${setColorTheme('error500')};
  }
`;

export const FlipFrontLink = styled.span`
  text-decoration: underline;
  color: ${setColorTheme('purple400')};
  cursor: pointer;
`;

export const GoBackContainer = styled(FlexContainer)`
  position: absolute;
  top: 1.6rem;
  left: 1.6rem;
  cursor: pointer;
`;

export const GoBackLabel = styled(Label)`
  font-weight: 600;
`;

export const QRCodeImg = styled.img`
  min-width: 28rem;
  min-height: 28rem;
  aspect-ratio: 1/1;
`;
