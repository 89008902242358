export enum StateType {
  tipSelphid = 'tipSelphid',
  tipSelphi = 'tipSelphi',
  document = 'document',
  face = 'face',
}

export const StateList: {
  [key in StateType]: { step?: string; title: string };
} = {
  tipSelphid: {
    step: 'Step 1 of 2',
    title: 'Document picture',
  },
  tipSelphi: {
    step: 'Step 2 of 2',
    title: 'Take a picture',
  },
  document: {
    title: 'Document capture',
  },
  face: {
    title: 'Take a selfie',
  },
};
