import { useEffect, useState } from 'react';
import { DeviceSize } from '../states/constants';

export function useDeviceSize() {
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width < DeviceSize.desktop;
  const isDesktop = width >= DeviceSize.desktop;

  return { isMobile, isDesktop };
}
