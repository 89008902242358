export const WarningViolet = ({
  width,
  height,
}: {
  width?: string;
  height?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '166'}
    height={height || '148'}
    viewBox="0 0 166 148"
    fill="none"
  >
    <g clipPath="url(#clip0_4717_182541)">
      <path
        d="M69.9744 7.48733C75.7075 -2.49773 90.0425 -2.49773 95.7756 7.48733L129.477 66.1664L163.178 124.845C168.911 134.828 161.745 147.307 150.278 147.307H15.4737C4.00464 147.307 -3.15946 134.828 2.57162 124.843L36.273 66.1644L69.9744 7.48733Z"
        fill="#BA9DF9"
      />
      <path
        d="M157.822 127.951L90.4191 10.5967C89.6543 9.26491 88.5541 8.15896 87.2293 7.39004C85.9044 6.62111 84.4016 6.21631 82.8718 6.21631C81.342 6.21631 79.8391 6.62111 78.5143 7.39004C77.1894 8.15896 76.0893 9.26491 75.3244 10.5967L7.92258 127.954C7.15808 129.286 6.75569 130.796 6.75586 132.334C6.75603 133.871 7.15874 135.382 7.92354 136.713C8.68834 138.045 9.78828 139.15 11.1128 139.919C12.4374 140.688 13.9399 141.093 15.4695 141.093H150.276C151.806 141.093 153.308 140.688 154.633 139.919C155.957 139.151 157.057 138.045 157.822 136.713C158.587 135.382 158.989 133.871 158.989 132.333C158.989 130.796 158.587 129.285 157.822 127.954V127.952V127.951ZM16.6532 131.645L82.8732 16.349L149.092 131.645H16.6532Z"
        fill="#243760"
      />
      <path
        d="M82.876 64.6266C88.3293 64.6266 92.7501 60.1827 92.7501 54.7008C92.7501 49.2189 88.3293 44.7749 82.876 44.7749C77.4227 44.7749 73.002 49.2189 73.002 54.7008C73.002 60.1827 77.4227 64.6266 82.876 64.6266Z"
        fill="#243760"
      />
      <path
        d="M84.0799 106.808C86.9315 106.141 88.7052 103.276 88.0416 100.41C87.378 97.5434 84.5285 95.7604 81.6769 96.4274C78.8253 97.0945 77.0516 99.959 77.7152 102.826C78.3788 105.692 81.2284 107.475 84.0799 106.808Z"
        fill="#243760"
      />
      <path
        d="M73.002 54.7007L77.674 102.653L88.1105 102.426L92.7501 54.7007H73.002Z"
        fill="#243760"
      />
      <path
        d="M83.8961 124.76C87.3842 124.198 89.7585 120.899 89.1992 117.393C88.6399 113.886 85.3587 111.5 81.8706 112.062C78.3824 112.624 76.0081 115.923 76.5675 119.429C77.1268 122.936 80.4079 125.322 83.8961 124.76Z"
        fill="#243760"
      />
    </g>
    <defs>
      <clipPath id="clip0_4717_182541">
        <rect
          width="164.895"
          height="148"
          fill="white"
          transform="translate(0.552734)"
        />
      </clipPath>
    </defs>
  </svg>
);
