import { Trans } from 'react-i18next';

export const TermsCondition = ({ keyValue }: { keyValue: string }) => {
  return (
    <Trans
      i18nKey={keyValue}
      components={{
        h4: <h4 />,
        ul: <ul />,
        li: <li />,
        a: <a />,
        p: <p />,
        strong: <strong />,
        u: <u />,
      }}
    />
  );
};
