import {
  InphiniteThemeProvider,
  ToastProvider,
  TimezoneProvider,
} from '@facephi/ui-react';
import { SkeletonTheme } from 'react-loading-skeleton';
import { RecoilRoot } from 'recoil';
import {
  AuthProvider,
  ConfigProvider,
  ExtendedSdkProvider,
  GeolocationProvider,
  LogProvider,
  TranslationProvider,
  OperationProvider,
} from './providers';
import { Routes } from './routes/Routes';
import { timeZoneVar } from './states/cache';
import { GlobalStyles } from './styles';

export const App = () => {
  return (
    <InphiniteThemeProvider>
      <GlobalStyles />
      <SkeletonTheme baseColor="#D3D9ED" highlightColor="#F3F4F5">
        <TimezoneProvider setTimezone={timeZoneVar} timezone={timeZoneVar()}>
          <ToastProvider>
            <TranslationProvider>
              <LogProvider>
                <RecoilRoot>
                  <AuthProvider>
                    <GeolocationProvider>
                      <ConfigProvider>
                        <ExtendedSdkProvider>
                          <OperationProvider>
                            <Routes />
                          </OperationProvider>
                        </ExtendedSdkProvider>
                      </ConfigProvider>
                    </GeolocationProvider>
                  </AuthProvider>
                </RecoilRoot>
              </LogProvider>
            </TranslationProvider>
          </ToastProvider>
        </TimezoneProvider>
      </SkeletonTheme>
    </InphiniteThemeProvider>
  );
};
