/* eslint-disable @typescript-eslint/no-explicit-any */

export const generateConfigObject = (data: any) => {
  const configObject = {
    customerId: data.customerId,
    onboardingConfig: {
      document: {
        country: data.country,
        type: data.documentType,
        validateGeolocation: false,
      },
      video: {
        videoAssistance: {
          enabled: data.videoAssistance,
          type: data.videoAssistanceType,
        },
        recording: data.recording,
      },
      signature: {
        enabled: data.signature,
        type: data.signatureType,
      },
      security: {
        previousOnboardingValidation: data.previousOnboardings,
        blacklistValidation: data.blacklistValidation,
        governmentalValidation: data.governmentalValidation,
        documentValidation: data.documentValidation,
        nfcValidation: false,
      },
      fingerPrints: {
        enabled: false,
      },
      liveness: {
        passiveLiveness: data.liveness,
      },
    },
  };
  return configObject;
};
