export const useDownloadBase64 = () => {
  const download = (data: string, fileName: string) => {
    const downloadLink = document.createElement('a');
    downloadLink.href = data;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  return { download };
};
