import { useMemo } from 'react';
import { FlexContainer, Label, useDeviceSize } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router-dom';
import { useOperation } from '../../providers';
import {
  RoutesName,
  FacialAuthenticationStatus,
  circleIconData,
  IconInfo,
  fullNameKey,
  MimeType,
  VerificationResults,
  DOCUMENT_FACIAL,
  Asset,
  FacialAuthenticateResult,
  GovernmentServices,
} from '../../states';
import { CardResults, CardResultsContent } from '../cards';
import { CardList, DataList } from '../cards/CardList';
import { GoBackHeader } from '../goBackHeader';
import { Banner } from './Banner';
import { SelfieValidations } from './SelfieValidation';
import { ResultsWrapper } from './Styles';

export const Government = () => {
  const { isMobile } = useDeviceSize();
  const { t } = useTranslation();
  const { operationId } = useParams();
  const { assets, governmentValidation, operation } = useOperation();

  const { loading, error, data: governmentData } = governmentValidation;
  const { facialMatching } = governmentData?.summary || {};

  const verifResult: DataList[] = useMemo(() => {
    const hasData = !!Object.keys(governmentData?.data || {}).length;

    return [
      {
        title: VerificationResults.verificationResult,
        value: error
          ? VerificationResults.VALIDATION_CONNECTION_ERROR
          : hasData
          ? VerificationResults.VALIDATION_PROVEN_CONTENT
          : VerificationResults.VALIDATION_NOT_PROVEN_CONTENT,
        valueStatus: error ? 'warning400' : hasData ? 'success400' : 'error400',
      },
    ];
  }, [governmentData]);

  const imageFromFacialResult: string = useMemo(() => {
    const governmentFacialResult: FacialAuthenticateResult | undefined =
      operation?.data?.facialAuthenticateResultList?.find(
        (facialResult: FacialAuthenticateResult) =>
          Object.values(GovernmentServices).includes(
            facialResult.source as GovernmentServices
          )
      );
    return governmentFacialResult?.assetsUrl[0] || '';
  }, [operation.data?.facialAuthenticateResultList]);

  const governmentValidationData = Object.entries(
    governmentData?.data || {}
  ).reduce((acc, [currentKey, currentValue]) => {
    acc.push({
      title: currentKey,
      value: currentValue as string,
      valueStatus: currentKey === fullNameKey ? 'success400' : undefined,
    });
    return acc;
  }, [] as DataList[]);

  const govermentAssets: Asset[] = useMemo(() => {
    const govAssets = Array.from(assets || []);
    if (imageFromFacialResult || governmentData?.image) {
      govAssets.push({
        contentType: MimeType.jpeg,
        type: DOCUMENT_FACIAL,
        url: governmentData?.image || imageFromFacialResult,
        source: governmentData?.source,
      });
    }
    return govAssets;
  }, [assets, governmentData]);

  const governmentFacialResult: FacialAuthenticationStatus | undefined =
    useMemo(() => {
      return facialMatching || governmentData?.succeed
        ? FacialAuthenticationStatus.positive
        : FacialAuthenticationStatus.negative;
    }, [governmentData?.succeed]);

  const cardListIcon: IconInfo | undefined = useMemo(
    () =>
      governmentValidationData.length
        ? circleIconData['success']
        : loading
        ? undefined
        : circleIconData['warning'],
    [governmentValidationData]
  );

  governmentValidationData.unshift(...verifResult);

  return (
    <>
      <GoBackHeader
        route={generatePath(RoutesName.results, {
          operationId,
        })}
        linkLabel="Go back"
        title="Government"
      />
      <ResultsWrapper flexDirection="column" rowGap="1.6">
        <Banner margin={false} />

        {(loading || governmentFacialResult) && (
          <CardResults title={t('GOVERNMENT SERVICE') || ''}>
            <CardResultsContent
              flexDirection="column"
              alignItems={isMobile ? 'start' : 'center'}
              rowGap="1.6"
            >
              <Label fontSize="21" semibold upperCase>
                {t('FACE VERIFICATION')}
              </Label>

              <SelfieValidations
                loading={loading}
                assets={govermentAssets}
                facialState={governmentFacialResult}
                provider={governmentData?.source}
              />
            </CardResultsContent>
          </CardResults>
        )}
        <FlexContainer
          columnGap={isMobile ? '0' : '0.8'}
          rowGap={isMobile ? '0.8' : '0'}
          as="section"
          flexDirection={isMobile ? 'column' : 'row'}
        >
          <CardList
            title={t('Obtained data')}
            subTitle={t('DATA VERIFICATION')}
            icon={cardListIcon}
            data={error ? verifResult : governmentValidationData}
            loading={loading}
            error={error}
            columnsPerRow={isMobile || loading || error ? '1' : '2'}
            testId="gvmnt-personal-data"
          />
        </FlexContainer>
      </ResultsWrapper>
    </>
  );
};
