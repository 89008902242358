import { FlexContainer, setColorTheme } from '@facephi/ui-react';
import styled from 'styled-components/macro';
import { desktopStyles } from '../../styles';

export const MainStyles = styled(FlexContainer)<{
  $negative?: boolean;
  $padding?: boolean;
}>`
  padding: ${({ $padding }) => ($padding ? '1.6rem' : '0')};
  position: relative;
  overflow-y: auto;

  ${({ $negative }) =>
    $negative
      ? `background-color: ${setColorTheme('purple500')};`
      : `background-color: ${setColorTheme('grey50')};`}

  ${desktopStyles} {
    padding: ${({ $padding }) => ($padding ? '2.4rem' : '0')};
  }
`;
