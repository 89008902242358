/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChangeEvent, ReactNode } from 'react';
import { FlexContainer, RadioButton, Label, Switch } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import packageJson from '../../../package.json';
import { useVariables } from '../../hooks';
import { useAuth } from '../../providers';
import {
  ISignType,
  ModelingList,
  ResultsScreenType,
  RoutesName,
  SignList,
  VideoAssistanceList,
  TypeDocument,
  IVideoAssistanceType,
} from '../../states';
import {
  AMLListState,
  antifraudState,
  documentTypeState,
  documentValidationState,
  governmentValidationState,
  livenessValidationState,
  logImagesState,
  previewCaptureState,
  previousOnboardingsValidationState,
  resultsScreenState,
  recordOnboardingState,
  signState,
  signTypeState,
  videoAssistanceState,
  videoAssistanceTypeState,
  behaviourCaptureState,
  antispoofingState,
  widgetLogState,
} from '../../states/atoms';
import { GoBackHeader } from '../goBackHeader';
import {
  ConfigurationCard,
  ConfigurationCardElement,
  ConfigurationCardHeader,
  ConfigurationCardRadio,
  ConfigurationWrapper,
} from './Styles';

type Options = {
  title: string;
  sectionTestId?: string;
  hide?: boolean;
  element: {
    message: string;
    titleTestId?: string;
    subMessage?: string;
    onChange?(value: boolean): void;
    checked?: boolean;
    idSwitch?: string;
    switchTestId?: string;
    children?: ReactNode;
    description?: string;
    descriptionTestId?: string;
    borderTop?: boolean;
    padding?: string;
    hide?: boolean;
    separator?: string;
    disabled?: boolean;
  }[];
};

export const Configuration = () => {
  const { t } = useTranslation();
  const [governmentValidation, setGovernmentValidation] = useRecoilState(
    governmentValidationState
  );
  const [antifraud, setAntifraud] = useRecoilState(antifraudState);
  const [documentType, setdocumentType] = useRecoilState(documentTypeState);
  const [documentValidation, setDocumentValidation] = useRecoilState(
    documentValidationState
  );
  const [previousOnboardingsValidation, setPreviousOnboardingsValidation] =
    useRecoilState(previousOnboardingsValidationState);
  const [livenessValidation, setLivenessValidation] = useRecoilState(
    livenessValidationState
  );
  const [previewCapture, setPreviewCapture] =
    useRecoilState(previewCaptureState);
  const [logImages, setLogImages] = useRecoilState(logImagesState);
  const [resultsScreen, setResultsScreen] = useRecoilState(resultsScreenState);
  const [AMLList, setAMLList] = useRecoilState(AMLListState);
  const [recordOnboarding, setRecordOnboarding] = useRecoilState(
    recordOnboardingState
  );
  const [sign, setSign] = useRecoilState(signState);
  const [signType, setSignType] = useRecoilState(signTypeState);
  const [videoAssistance, setVideoAssistance] =
    useRecoilState(videoAssistanceState);
  const [videoAssistanceType, setVideoAssistanceType] = useRecoilState(
    videoAssistanceTypeState
  );
  const [behaviourCapture, setBehaviourCapture] = useRecoilState(
    behaviourCaptureState
  );
  const [antispoofing, setAntispoofing] = useRecoilState(antispoofingState);
  const [widgetLog, setWidgetLog] = useRecoilState(widgetLogState);

  const { email, hasAntifraud, hasVideoAssistance, canDisableLiveness } =
    useAuth();
  const { appVersion } = useVariables();

  const configurationOptions: Options[] = [
    {
      title: 'Modeling',
      sectionTestId: 'settings_model_section_title_text',
      element: [
        {
          message: 'Document type',
          titleTestId: 'settings_model_document_title_text',
          padding: '1.6rem 1.6rem 0',
          children: (
            <ConfigurationCardRadio
              name="document"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setdocumentType(e.currentTarget.value as TypeDocument)
              }
              defaultSelected={documentType}
            >
              {Object.entries(ModelingList).map(([id, item]) => (
                <RadioButton
                  key={id}
                  label={t(item.title)}
                  value={id}
                  disabled={!item.active}
                  testId={item.testId}
                />
              ))}
            </ConfigurationCardRadio>
          ),
        },
      ],
    },
    {
      title: 'Video',
      sectionTestId: 'settings_video_section_title_text',
      element: [
        {
          message: 'Video assistance',
          titleTestId: 'settings_video_videocall_title_text',
          hide: !hasVideoAssistance,
          description:
            'Allows for an assistant to help during the onboarding process.',
          descriptionTestId: 'settings_video_videocall_description_text',
          idSwitch: 'VideoAssistance',
          switchTestId: 'settings_video_videocall_switch',
          checked: videoAssistance,
          onChange: setVideoAssistance,
          children: (
            <ConfigurationCardRadio
              name="videoAssistance"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setVideoAssistanceType(
                  e.currentTarget.value as IVideoAssistanceType
                )
              }
              defaultSelected={videoAssistanceType}
            >
              {Object.entries(VideoAssistanceList).map(([id, item]) => (
                <RadioButton
                  key={id}
                  label={t(item.name)}
                  value={id}
                  disabled={!videoAssistance}
                  testId={item.testId}
                />
              ))}
            </ConfigurationCardRadio>
          ),
        },
        {
          borderTop: true,
          message: 'Record onboarding',
          titleTestId: 'settings_video_videorecording_title_text',
          description: 'Allows recording the onboarding process.',
          descriptionTestId: 'settings_video_videorecording_description_text',
          checked:
            recordOnboarding &&
            !(
              videoAssistance &&
              videoAssistanceType === IVideoAssistanceType.start
            ),
          onChange: setRecordOnboarding,
          idSwitch: 'RecordOnboarding',
          switchTestId: 'settings_video_videorecording_switch',
          disabled:
            videoAssistance &&
            videoAssistanceType === IVideoAssistanceType.start,
          hide: false,
        },
      ],
    },
    {
      title: 'Signature',
      sectionTestId: 'settings_signature_section_title_text',
      element: [
        {
          message: 'Allow signature',
          titleTestId: 'settings_signature_title_text',
          onChange: setSign,
          checked: sign,
          idSwitch: 'Sign',
          switchTestId: 'settings_signature_switch',
          description:
            'Allows the signature on the results screen (will only appear in case of success).',
          descriptionTestId: 'settings_signature_description_text',
          padding: '1.6rem 1.6rem 1rem',
          children: (
            <ConfigurationCardRadio
              name="sign"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setSignType(e.currentTarget.value as ISignType)
              }
              defaultSelected={signType}
            >
              {Object.entries(SignList).map(([id, item]) => (
                <RadioButton
                  key={id}
                  label={t(item.name)}
                  value={id}
                  disabled={!sign}
                  testId={item.testId}
                />
              ))}
            </ConfigurationCardRadio>
          ),
        },
      ],
    },
    {
      title: 'Security',
      sectionTestId: 'settings_security_section_title_text',
      element: [
        {
          message: 'Verification of previous onboarding',
          titleTestId: 'settings_security_previous_onboarding_title_text',
          onChange: setPreviousOnboardingsValidation,
          checked: previousOnboardingsValidation,
          idSwitch: 'PreviousOnboardings',
          switchTestId: 'settings_security_previous_onboarding_switch',
          description:
            'Compare with previous records if the biometric pattern already exists.',
          descriptionTestId:
            'settings_security_previous_onboarding_description_text',
        },
        {
          borderTop: true,
          message: 'Validation in the blocked lists',
          titleTestId: 'settings_security_bannedlist_title_text',
          onChange: setAntifraud,
          checked: antifraud,
          idSwitch: 'Antifraud',
          switchTestId: 'settings_security_bannedlist_switch',
          description: 'Allows to check in the blocked lists.',
          descriptionTestId: 'settings_security_bannedlist_description_text',
          hide: !hasAntifraud,
        },
        {
          message: 'Check the banned list',
          onChange: setAMLList,
          checked: AMLList,
          idSwitch: 'AMLList',
          hide: true,
        },
        {
          borderTop: true,
          message: 'Government Validation',
          titleTestId: 'settings_security_govermental_title_text',
          onChange: setGovernmentValidation,
          checked: governmentValidation,
          idSwitch: 'GovernmentValidation',
          switchTestId: 'settings_security_govermental_switch',
          description:
            'Allows to request to the government provider, if there is one.',
          descriptionTestId: 'settings_security_govermental_description_text',
        },
        {
          borderTop: true,
          message: 'Morphological validation',
          titleTestId: 'settings_security_document_validation_title_text',
          onChange: setDocumentValidation,
          checked: documentValidation,
          idSwitch: 'Morphological',
          switchTestId: 'settings_security_document_validation_switch',
          description: 'Validates the authenticity of the identity document.',
          descriptionTestId:
            'settings_security_document_validation_description_text',
        },
      ],
    },
    {
      title: 'Images preview',
      sectionTestId: 'settings_image_preview_section_title_text',
      element: [
        {
          message: 'Preview images',
          titleTestId: 'settings_image_preview_title_text',
          onChange: setPreviewCapture,
          checked: previewCapture,
          idSwitch: 'Capture',
          switchTestId: 'settings_image_preview_switch',
          description: 'Allows to see the captured document or images.',
          descriptionTestId: 'settings_image_preview_description_text',
        },
      ],
    },
    {
      title: 'Download of images',
      sectionTestId: 'settings_download_images_section_title_text',
      element: [
        {
          message: 'Save images',
          titleTestId: 'settings_download_images_title_text',
          onChange: setLogImages,
          checked: logImages,
          idSwitch: 'SaveImages',
          switchTestId: 'settings_download_images_switch',
          description: 'Save the captured images (document, pictures, etc).',
          descriptionTestId: 'settings_download_images_description_text',
        },
      ],
    },
    {
      title: 'Antispoofing',
      sectionTestId: 'settings_liveness_antispoofing_section_title_text',
      element: [
        {
          message: 'Liveness passive',
          titleTestId: 'settings_liveness_title_text',
          onChange: setLivenessValidation,
          checked: livenessValidation,
          idSwitch: 'Liveness',
          switchTestId: 'settings_liveness_switch',
          description: 'Allows to check if the user is a live person.',
          descriptionTestId: 'settings_liveness_description_text',
          hide: !canDisableLiveness,
        },
        {
          borderTop: true,
          message: 'Antispoof validation',
          titleTestId: 'settings_antispoofing_title_text',
          onChange: setAntispoofing,
          checked: antispoofing,
          idSwitch: 'Antispoof',
          switchTestId: 'settings_antispoofing_switch',
          description: 'Check antispoof in the process.',
          descriptionTestId: 'settings_antispoofing_description_text',
        },
      ],
    },
    {
      title: 'Debugging',
      sectionTestId: 'settings_debugging_mode_section_title_text',
      element: [
        {
          message: 'Widget Logs',
          titleTestId: 'settings_debugging_mode_title_text',
          onChange: setWidgetLog,
          checked: widgetLog,
          idSwitch: 'WidgetLog',
          switchTestId: 'settings_debugging_mode_switch',
          description: 'Allows to activate widget console logs.',
          descriptionTestId: 'settings_debugging_mode_description_text',
        },
      ],
    },
    {
      title: 'Results screen',
      hide: true,
      element: [
        {
          message: 'Detailed results screen',
          onChange: (value: boolean) =>
            setResultsScreen(
              value ? ResultsScreenType.detail : ResultsScreenType.reduced
            ),
          checked: resultsScreen === ResultsScreenType.detail,
          idSwitch: 'ResultsScreen',
        },
      ],
    },
    {
      title: 'Additional information',
      sectionTestId: 'settings_additional_info_section_title_text',
      element: [
        {
          message: 'Credentials',
          titleTestId: 'settings_additional_info_credentials_title_text',
          subMessage: email,
          separator: ':',
        },
        {
          borderTop: true,
          message: 'Version',
          titleTestId: 'settings_additional_info_version_title_text',
          subMessage: appVersion || packageJson.version,
          separator: ':',
        },
        {
          borderTop: true,
          message: 'URL',
          titleTestId: 'settings_additional_info_url_title_text',
          separator: ':',
          subMessage: window.location.origin,
        },
      ],
    },
  ];

  return (
    <>
      <ConfigurationWrapper flexDirection="column" rowGap="3.2">
        <GoBackHeader
          route={RoutesName.home}
          linkLabel="Go back"
          title="Configuration"
        />
        {configurationOptions
          .filter(({ hide }) => !hide)
          .map((item, index) => (
            <ConfigurationCard as="ul" key={index}>
              <ConfigurationCardHeader
                forwardedAs="h2"
                fontSize="16"
                testId={item.sectionTestId}
                id={item.sectionTestId}
                semibold
              >
                {t(item.title)}
              </ConfigurationCardHeader>

              {item.element
                ?.filter(({ hide }) => !hide)
                .map((value, index) => (
                  <>
                    <ConfigurationCardElement
                      key={index}
                      alignItems="center"
                      justifyContent="space-between"
                      forwardedAs="li"
                      $boxPadding={value.padding || '1.6rem'}
                      $borderTop={value.borderTop}
                    >
                      <FlexContainer flexDirection="column" rowGap="0.6">
                        <Label
                          fontSize="14"
                          semibold
                          testId={value.titleTestId}
                          id={value.titleTestId}
                        >
                          {t(value.message)}
                          {value?.separator}
                          {value.subMessage && ` ${value.subMessage}`}
                        </Label>

                        {value.description && (
                          <Label
                            fontSize="12"
                            color="grey500"
                            testId={value.descriptionTestId}
                            id={value.descriptionTestId}
                          >
                            {t(value.description)}
                          </Label>
                        )}
                      </FlexContainer>

                      {value.onChange && (
                        <Switch
                          id={value.idSwitch as string}
                          onChange={value.onChange}
                          checked={value.checked}
                          disabled={value.disabled}
                          testId={value.switchTestId}
                        />
                      )}
                    </ConfigurationCardElement>
                    {value.children}
                  </>
                ))}
            </ConfigurationCard>
          ))}
      </ConfigurationWrapper>
    </>
  );
};
