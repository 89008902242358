import { setColorTheme } from '@facephi/ui-react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

export const ConfigurationLink = styled(Link)`
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    color: ${setColorTheme('purple400')};
  }
`;
