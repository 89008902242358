import { FlexContainer, Label } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { StateList, StateType } from './model';

export const WorkflowTitle = ({ state }: { state?: StateType }) => {
  const { t } = useTranslation();

  return (
    <FlexContainer
      flexDirection="column"
      rowGap="0.8"
      alignItems="center"
      justifyContent="center"
    >
      {state && (
        <>
          {StateList[state].step && (
            <Label fontSize="18">{t(StateList[state].step || '')}</Label>
          )}
          {StateList[state].title && (
            <Label fontSize="18" semibold>
              {t(StateList[state].title)}
            </Label>
          )}
        </>
      )}
    </FlexContainer>
  );
};
