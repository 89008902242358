import { Icon, Label } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import {
  IconInfo,
  REFUSAL_REASON,
  VerificationStatusValues,
} from '../../states';
import { EmptyFilesError, GenericError } from '../icons';
import {
  CardListElement,
  CardListHeader,
  CardListList,
  CardListStyles,
  EmptyStateContent,
  CardListContentWrap,
} from './Styles';

export type DataList = {
  title: string;
  value: string;
  valueStatus?: 'error400' | 'success400' | 'warning400';
};

type Props = {
  title: string;
  subTitle?: string | null;
  columnsPerRow?: number | string;
  data?: DataList[];
  isRow?: boolean;
  loading?: boolean;
  showEmptyState?: boolean;
  error?: boolean;
  fitContent?: boolean;
  icon?: IconInfo;
  testId?: string;
};

export const CardList = ({
  title,
  subTitle,
  columnsPerRow = 1,
  data,
  isRow,
  loading,
  showEmptyState,
  error,
  fitContent,
  icon,
  testId,
}: Props) => {
  const { t } = useTranslation();

  const getSkeleton = () =>
    Array.from({ length: 4 }).map((_, index) => (
      <CardListElement
        columnGap="2"
        key={index}
        rowGap="1"
        flexDirection={isRow ? 'row' : 'column'}
      >
        <div style={{ width: '50%' }}>
          <Skeleton height={18} width="70%" borderRadius={8} />
        </div>
        <div style={{ width: '50%' }}>
          <Skeleton height={18} width="100%" borderRadius={8} />
        </div>
      </CardListElement>
    ));

  const defineColor = (key: string, value: unknown) => {
    if (key === REFUSAL_REASON) return 'error400';

    switch (value) {
      case VerificationStatusValues.verified:
      case VerificationStatusValues.done:
      case (value as string).includes('$'):
        return 'success400';
      case VerificationStatusValues.error:
      case (value as string).includes('*'):
        return 'error400';
      default:
        return undefined;
    }
  };

  if (data?.length && data?.length !== 1 && data?.length % 2 !== 0) {
    data?.push({ title: '', value: '' });
  }

  const showValue = (value: string | boolean) => {
    if (typeof value === 'boolean') {
      return value ? 'OK' : 'KO';
    }

    const valueCopy = value.replaceAll('$', '').replaceAll('*', '');
    return t(valueCopy);
  };

  const getComponent = () => {
    if (loading) {
      return getSkeleton();
    }
    if (!data && showEmptyState) {
      return (
        <EmptyStateContent
          $error={error}
          image={
            error ? (
              <GenericError />
            ) : (
              <EmptyFilesError width="220" height="220" />
            )
          }
          title={error ? t('Something went wrong') : t('No data found')}
          subtitle={error ? (t('Please, try again') as string) : undefined}
        />
      );
    }
    return data?.map((element, index) => (
      <CardListElement
        forwardedAs="li"
        rowGap={isRow ? '0' : '0.4'}
        columnGap={isRow ? '0.8' : '0'}
        flexDirection={isRow ? 'row' : 'column'}
        justifyContent={isRow ? 'space-between' : 'center'}
        key={index}
        $isRow={isRow}
      >
        <Label fontSize={isRow ? '16' : '14'}>{t(element.title)}</Label>
        <Label
          fontSize="16"
          semibold
          color={
            element.valueStatus || defineColor(element.title, element.value)
          }
          textAlign={isRow ? 'right' : 'left'}
        >
          {showValue(element.value)}
        </Label>
      </CardListElement>
    ));
  };

  return (
    <CardListStyles
      flexDirection="column"
      fitContent={fitContent}
      testId={testId}
    >
      <CardListHeader rowGap="0.4" flexDirection="column">
        <CardListContentWrap>
          <Label fontSize="12" semibold upperCase>
            {title}
          </Label>
          {icon && (
            <Icon
              iconName={icon.iconName}
              size="32"
              weight="fill"
              color={icon.iconColor}
            />
          )}
        </CardListContentWrap>
        {subTitle ? (
          <Label fontSize="21" semibold>
            {subTitle}
          </Label>
        ) : (
          loading && <Skeleton height={22} width="70%" borderRadius={8} />
        )}
      </CardListHeader>

      <CardListList forwardedAs="ul" $columnsPerRow={columnsPerRow}>
        {getComponent()}
      </CardListList>
    </CardListStyles>
  );
};
