import { Label } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { WarningViolet } from '../icons';
import { ModalDeclineContent, ModalDeclineStyles } from './Styles';

type ModalDeclineProps = {
  show?: boolean;
  onChangeShow(show: boolean): void;
  onDecline(): void;
};

export const ModalDecline = ({
  show,
  onChangeShow,
  onDecline,
}: ModalDeclineProps) => {
  const { t } = useTranslation();

  return (
    <ModalDeclineStyles
      show={show}
      onChangeShow={onChangeShow}
      header={{ noBorder: true }}
      footer={{
        onSubmitButton: () => onDecline(),
        onCloseButton: () => onChangeShow(false),
        closeButtonLabel: t('Cancel').toLocaleUpperCase() || '',
        submitButtonLabel: t('Logout').toLocaleUpperCase() || '',
      }}
      hasPadding
    >
      <ModalDeclineContent
        flexDirection="column"
        rowGap="3.2"
        alignItems="center"
      >
        <WarningViolet />
        <Label fontSize="14" textAlign="center">
          {t(
            'Declining the GDPR will cause the current session to end. Do you want to logout?'
          )}
        </Label>
      </ModalDeclineContent>
    </ModalDeclineStyles>
  );
};
