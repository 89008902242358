/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { MimeType, Storage } from '../states';
import { useLogger } from './useLogger';

export function useLoadAsset(assetUrl?: string, contentType?: MimeType) {
  const [hasError, setError] = useState<boolean>(false);
  const [resourceUrl, setResourceUrl] = useState<string | undefined>();
  const [loadingResource, setLoadingResource] = useState<boolean>(false);
  const [cancelTokenSource, setCancelToken] =
    useState<CancelTokenSource | null>(null);
  const { captureException } = useLogger();

  const getImageUrl = async (assetUrl: string) => {
    return await axios
      .get(assetUrl, {
        responseType: 'blob',
        cancelToken: cancelTokenSource?.token,
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            Storage.trackingToken
          )}`,
        },
      })
      .then((response) => response.data);
  };

  const getImage = async (assetUrl: string): Promise<string> => {
    const response = await getImageUrl(assetUrl);

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = () => reject();
      reader.readAsDataURL(response);
    });
  };

  const loadAsset = async (assetUrl: string) => {
    try {
      const response = await getImage(assetUrl);
      setResourceUrl(response);
    } catch (error: any) {
      captureException(error, { operation: 'loadAsset' });
      setError(true);
    }
    setLoadingResource(false);
    setCancelToken(null);
  };

  useEffect(() => {
    if (assetUrl && cancelTokenSource) {
      loadAsset(assetUrl);
    }
  }, [assetUrl, cancelTokenSource]);

  const cancelLoadAsset = useCallback(() => {
    cancelTokenSource && cancelTokenSource.cancel();
  }, [cancelTokenSource]);

  useEffect(() => {
    setError(false);
    setLoadingResource(true);
    setResourceUrl(undefined);
    if (assetUrl && contentType) {
      setCancelToken(axios.CancelToken.source());
    }
  }, [assetUrl]);

  return {
    hasError,
    resourceUrl,
    loadingResource,
    cancelSource: cancelLoadAsset,
    getImage,
    getImageUrl,
  };
}
