import { useEffect, useState } from 'react';
import { Label, PositionX, useDeviceSize } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import {
  Languages,
  languagesObject,
} from '../../providers/TranslationsProvider';
import { RoutesName } from '../../states';
import {
  ButtonLanguage,
  OptionMenuHeader,
  OptionMenuItemHeader,
  LanguageMenuWrapper,
} from './Styles';

export const LanguageSelection = () => {
  const { i18n, t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { isMobile } = useDeviceSize();

  const changeLanguageHandler = (language: Languages) => {
    i18n.changeLanguage(language);
  };

  const formatLanguage = i18n.language.split('-')[0];
  const isGdprMobile =
    isMobile && window.location.pathname === RoutesName.terms;

  useEffect(() => {
    const targetNode: Node | null = document.getElementById('popup-root');
    const config = { attributes: false, childList: true, subtree: false };

    const callback = (mutationList: MutationRecord[]) => {
      for (const mutation of mutationList) {
        setIsOpen(Boolean(mutation.addedNodes.length));
      }
    };

    const observer = new MutationObserver(callback);
    targetNode && observer.observe(targetNode, config);

    return () => observer.disconnect();
  }, []);

  return (
    <LanguageMenuWrapper
      flexDirection={isGdprMobile ? 'row' : 'row-reverse'}
      justifyContent="space-between"
      $asLayout={isGdprMobile}
    >
      {isGdprMobile && (
        <Label fontSize="16" textAlign="left" semibold>
          {t('General Data Protection Regulation')}
        </Label>
      )}
      <OptionMenuHeader
        language
        $isOpen={isOpen}
        isMobile={isMobile}
        testId="change-language"
        positionX={PositionX.right}
        hidePanelOnClick={true}
        renderItem={
          <ButtonLanguage
            flexDirection="row-reverse"
            columnGap="1"
            alignItems="center"
            forwardedAs="button"
            $isOpen={isOpen}
          >
            <span>{formatLanguage}</span>
            {languagesObject[formatLanguage as Languages]}
          </ButtonLanguage>
        }
      >
        {Object.entries(languagesObject)
          .filter(([value]) => value !== formatLanguage)
          .map(([value, flag]) => (
            <OptionMenuItemHeader
              color="white"
              key={value}
              onClick={() => changeLanguageHandler(value as Languages)}
            >
              {flag}
              <span>{value}</span>
            </OptionMenuItemHeader>
          ))}
      </OptionMenuHeader>
    </LanguageMenuWrapper>
  );
};
