import { useMemo } from 'react';
import { Icon, StatusType } from '@facephi/ui-react';
import {
  IconInfo,
  statusIconData,
  StatusTypeList,
  StatusOperations,
} from '../../states';
import { CardResultsHeaderSpinner } from '../cards';
import { ResultsIconWrapper } from './Styles';

export type StatusTypeProps = StatusType | StatusOperations;

type TProps = {
  status: StatusTypeProps;
  invert?: boolean;
  className?: string;
};

export const StatusIcon = ({ status, invert, className }: TProps) => {
  const iconData: IconInfo | undefined = useMemo(() => {
    switch (status) {
      case StatusTypeList.SUCCEEDED:
      case StatusTypeList.SAVED:
        return statusIconData['success'];

      case StatusTypeList.DENIED:
        return statusIconData['error'];

      case StatusTypeList.PENDENT:
      case StatusOperations.CONNECTION_ERROR:
      case StatusOperations.EXPIRED:
        return statusIconData['warning'];

      case StatusTypeList.IN_PROGRESS:
      case StatusTypeList.STARTED:
        return undefined;
    }
  }, [status]);

  return (
    <>
      {iconData ? (
        <ResultsIconWrapper
          className={className}
          background={invert ? 'white' : iconData.iconColor}
        >
          <Icon
            iconName={iconData.iconName}
            size="16"
            weight="bold"
            color={invert ? iconData.iconColor : 'white'}
          />
        </ResultsIconWrapper>
      ) : (
        <CardResultsHeaderSpinner
          genericSpinner
          size="S"
          insideComponent
          className={className}
        />
      )}
    </>
  );
};
