/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import {
  ModalTermsContent,
  ModalTermsStyles,
  ButtonsContainer,
} from './Styles';
import { TermsCondition } from './TermsCondition';

export const ModalTerms = ({ switchModal }: { switchModal: any }) => {
  const { t } = useTranslation();

  return (
    <ModalTermsStyles flexDirection="column" rowGap="1">
      <ModalTermsContent data-test="terms&conditions">
        <TermsCondition keyValue="terms&conditions" />
      </ModalTermsContent>
      <ButtonsContainer columnGap="1.6" justifyContent="flex-end">
        <Button
          size="L"
          onClick={() => switchModal(false)}
          testId="accept-conditions"
          color="primary"
        >
          {t('Go back').toLocaleUpperCase()}
        </Button>
      </ButtonsContainer>
    </ModalTermsStyles>
  );
};
