/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from '@sentry/react';

export function useLogger() {
  const captureException = (error: Error, data?: any) => {
    Sentry.captureException(error, { extra: data });
  };

  const captureMessage = (message: string) => {
    Sentry.captureMessage(message);
  };

  const setUser: any = (user: Sentry.User) => {
    Sentry.setUser(user);
  };

  const captureEvent = (event: Sentry.Event) => {
    Sentry.captureEvent(event);
  };

  return {
    captureException,
    captureMessage,
    captureEvent,
    setUser,
  };
}
