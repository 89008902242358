/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
  useMemo,
  Dispatch,
} from 'react';
import { useTracking } from '@facephi/sdk-web';
import { useRecoilValue } from 'recoil';
import { StatusTypeProps } from '../components';
import {
  RequestMethods,
  useLogger,
  useRequest,
  useVariables,
  useFacialValidation,
  useLoadAsset,
} from '../hooks';
import {
  ActionRequest,
  ActionTypeRequest,
  StateRequest,
  createRequestReducer,
} from '../reducers';
import {
  OcrData,
  Asset,
  GovernmentValidationDto,
  endPoints,
  Operation,
  Security,
  OperationResponse,
  ocrDataGovKeys,
  ExtractedDocumentsData,
  TypeDocumentDTO,
  UncroppedImages,
  TrackingFamily,
  governmentValitionKeys,
  datesKeys,
  GovernmentServices,
  GovernmentPersonalInfo,
  GovernmentCountryCodes,
  StatusOperations,
} from '../states';
import {
  antifraudState,
  documentValidationState,
  governmentValidationState,
  previousOnboardingsValidationState,
  operationIdState,
  documentTypeState,
  livenessValidationState,
} from '../states/atoms';
import { getBase64Image, objectMapping, transformDate } from '../utils';
import { useAuth } from './AuthProvider';

type IProps = {
  children: React.ReactNode;
};

export type SecurityDto = {
  frontImage: string;
  backImage: string;
};

export type AntiFraudDto = {
  matches: {
    operationId: string;
    assets: Asset[];
    similarity: number;
  }[];
};

type SignedDocument = {
  signed: boolean | null;
};

type TemplateDto = {
  template: string;
};

type CreatePreviousOnboardingDto = {
  assets: {
    type: string;
    data: string;
  }[];
};

type CheckPreviousOnboardingDto = AntiFraudDto;

type ContextProps = {
  setSelfie(value: string): void;
  checkAntifraud(data: any): Promise<void>;
  setDocumentSides(value: SecurityDto): void;
  selfie: string;
  documentSides: SecurityDto;
  facialCapture: string;
  setFacialCapture(value: string): void;
  ocrData?: OcrData;
  assets?: Asset[];
  antifraud: {
    loading: boolean;
    error: boolean;
    data?: AntiFraudDto;
    status?: StatusTypeProps;
  };
  dispatchDocumentSignature: Dispatch<ActionTypeRequest<SignedDocument>>;
  documentSignature: {
    loading: boolean;
    error: boolean;
    data?: SignedDocument;
    status?: StatusTypeProps;
  };
  morphologicalValidation: {
    loading: boolean;
    error: boolean;
    data?: Security;
    status?: StatusTypeProps;
  };
  previousOnboardings: {
    loading: boolean;
    error: boolean;
    data?: CheckPreviousOnboardingDto;
    status?: StatusTypeProps;
  };
  operation: {
    loading: boolean;
    error: boolean;
    data?: Operation;
    status?: StatusTypeProps;
  };
  governmentValidation: {
    loading: boolean;
    error: boolean;
    data?: GovernmentValidationDto;
    status?: StatusTypeProps;
  };
  pending?: boolean;
  setStatusPending(value: boolean): void;
  signedDocument?: boolean;
  signDocument(value: boolean): void;
  governmentCard: boolean;
  setGovernmentCard(value: boolean): void;
  setDocumentsData(data: ExtractedDocumentsData): void;
  workflow?: boolean;
  setWorkflow(value: boolean): void;
  shouldCheckGovernment?: boolean;
  setShouldCheckGovernment(value: boolean): void;
  governmentCountry: string;
  finishedOperation?: boolean;
  setFinishedOperation(value: boolean): void;
  uncroppedImages?: UncroppedImages;
  setUncroppedImages(value: UncroppedImages): void;
  matchingResult: any;
  setMatchingResult(value: any): void;
  livenessResult: any;
  setLivenessResult(value: any): void;
  antispoofResult: any;
  setAntispoofResult(value: any): void;
};

const initialStateAntifraud: StateRequest<AntiFraudDto> = {
  loading: false,
  error: false,
};

const initialStateSecurity: StateRequest<Security> = {
  loading: false,
  error: false,
};

const initialStatePreviousOnboardings: StateRequest<CheckPreviousOnboardingDto> =
  {
    loading: false,
    error: false,
  };

const initialStateOperation: StateRequest<Operation> = {
  loading: true,
  error: false,
};

const initialStateDocumentSignature: StateRequest<SignedDocument> = {
  loading: false,
  error: false,
};

const initialStateGovernmentValidation: StateRequest<GovernmentValidationDto> =
  {
    loading: false,
    error: false,
  };

const Ctx = createContext<ContextProps>({
  selfie: '',
  setSelfie: () => {},
  setDocumentsData: () => {},
  checkAntifraud: () => new Promise((resolve) => resolve()),
  documentSides: {
    frontImage: '',
    backImage: '',
  },
  dispatchDocumentSignature: () => {},
  documentSignature: initialStateDocumentSignature,
  setDocumentSides: () => {},
  facialCapture: '',
  setFacialCapture: () => {},
  antifraud: initialStateAntifraud,
  morphologicalValidation: initialStateSecurity,
  previousOnboardings: initialStatePreviousOnboardings,
  operation: initialStateOperation,
  governmentValidation: initialStateGovernmentValidation,
  signDocument: () => {},
  governmentCard: false,
  setGovernmentCard: () => {},
  workflow: false,
  setWorkflow: () => {},
  shouldCheckGovernment: false,
  setShouldCheckGovernment: () => {},
  governmentCountry: '',
  finishedOperation: false,
  setFinishedOperation: () => {},
  uncroppedImages: {
    frontFull: '',
    backFull: '',
  },
  setUncroppedImages: () => {},
  setLivenessResult: () => {},
  livenessResult: {},
  setMatchingResult: () => {},
  matchingResult: {},
  setStatusPending: () => {},
  antispoofResult: {},
  setAntispoofResult: () => {},
});

export const OperationProvider = ({ children }: IProps) => {
  const [images, setImages] = useState<string[]>([]);
  const [selfie, setSelfie] = useState<string>('');
  const [documentSides, setDocumentSides] = useState<SecurityDto>({
    frontImage: '',
    backImage: '',
  });
  const [facialCapture, setFacialCapture] = useState<string>('');
  const [signedDocument, signDocument] = useState(false);
  const { captureException, captureMessage } = useLogger();
  const { apiDemoUrl, trackingUrlAssets, appTesting } = useVariables();
  const operationId = useRecoilValue(operationIdState);
  const documentValidation = useRecoilValue(documentValidationState);
  const antifraud = useRecoilValue(antifraudState);
  const previousOnboardingsValidation = useRecoilValue(
    previousOnboardingsValidationState
  );
  const documentType = useRecoilValue(documentTypeState);

  const governmentValidation = useRecoilValue(governmentValidationState);
  const [governmentCard, setGovernmentCard] = useState<boolean>(false);
  const [shouldCheckGovernment, setShouldCheckGovernment] =
    useState<boolean>(false);

  const requestReducerAntifraud = createRequestReducer<AntiFraudDto>();
  const [stateAntifraud, dispatchAntifraud] = useReducer(
    requestReducerAntifraud,
    initialStateAntifraud
  );
  const requestReducerMorphologicalValidation =
    createRequestReducer<Security>();
  const [stateMorphologicalValidation, dispatchMorphologicalValidation] =
    useReducer(requestReducerMorphologicalValidation, initialStateSecurity);
  const requestReducerPreviousOnboardings =
    createRequestReducer<CheckPreviousOnboardingDto>();
  const [statePreviousOnboardings, dispatchPreviousOnboardings] = useReducer(
    requestReducerPreviousOnboardings,
    initialStatePreviousOnboardings
  );
  const requestReducerOperation = createRequestReducer<Operation>();
  const [stateOperation, dispatchOperation] = useReducer(
    requestReducerOperation,
    initialStateOperation
  );
  const requestReducerSignature = createRequestReducer<SignedDocument>();
  const [stateDocumentSignature, dispatchDocumentSignature] = useReducer(
    requestReducerSignature,
    initialStateDocumentSignature
  );
  const requestGovernmentValidation =
    createRequestReducer<GovernmentValidationDto>();
  const [stateGovernmentValidation, dispatchGovernmentValidation] = useReducer(
    requestGovernmentValidation,
    initialStateGovernmentValidation
  );
  const [documentsData, setDocumentsData] = useState<ExtractedDocumentsData>();
  const { requestBasic: demoRequest } = useRequest(apiDemoUrl);
  const [ocrData, setOCRData] = useState<OcrData>();
  const [assets, setAssets] = useState<Asset[]>();
  const [statusPending, setStatusPending] = useState(true);
  const { getImage } = useLoadAsset();
  const { sessionId } = useTracking();
  const { demoVersion } = useAuth();
  const [workflow, setWorkflow] = useState<boolean>(false);
  const liveness = useRecoilValue(livenessValidationState);
  const [finishedOperation, setFinishedOperation] = useState<boolean>(false);
  const [uncroppedImages, setUncroppedImages] = useState<UncroppedImages>({
    frontFull: '',
    backFull: '',
  });
  const [matchingResult, setMatchingResult] = useState();
  const [livenessResult, setLivenessResult] = useState();
  const [antispoofResult, setAntispoofResult] = useState();

  const getAsset = (assets: Asset[], type: string) =>
    assets?.find((item: { type: string }) => item.type === type);

  const getAssetsImage = async (assets: Asset[]) => {
    const imagesUrl = ['DOCUMENT_FRONT', 'DOCUMENT_BACK', 'SELFIE'].reduce(
      (acc, type) => {
        acc.push(getAsset(assets, type)?.url || '');
        return acc;
      },
      [] as string[]
    );

    if (imagesUrl.length) {
      try {
        const response = await Promise.all(
          imagesUrl.map((item) => {
            if (!item) return '';
            return /^data:((?:\w+\/(?:(?!;).)+)?)((?:;[\w=]*[^;])*),(.+)$/.test(
              item
            )
              ? item
              : getImage(trackingUrlAssets + item);
          })
        );

        setImages(response);
        if (!selfie && response[2]) {
          setSelfie(response[2]);
        }
      } catch {
        // Empty block
      }
    }
  };

  const validateOperationStatus = (status?: StatusOperations) =>
    status && Object.values(StatusOperations).includes(status);

  const ocrDataGovernment = useMemo(
    () =>
      stateOperation.data?.ocrDataList?.length
        ? Object.fromEntries(
            Object.entries(
              stateOperation.data?.ocrDataList?.[0].dataMap
            ).filter(([key]) => Object.values(ocrDataGovKeys).includes(key))
          )
        : undefined,
    [stateOperation.data?.ocrDataList]
  );

  useEffect(() => {
    const assets = stateOperation.data?.assets;
    if (!images.length && assets) {
      getAssetsImage(assets);
    }
  }, [stateOperation, images]);

  const governmentalData = useMemo(
    () => ({
      country:
        documentsData?.extractionData?.documentCountryIssuer ||
        documentsData?.extractionData?.nationality ||
        documentsData?.extractionRaw.mrz.documentIssuer ||
        ocrDataGovernment?.[ocrDataGovKeys.countryCode] ||
        ocrDataGovernment?.[ocrDataGovKeys.backIssuingCountry] ||
        ocrDataGovernment?.[ocrDataGovKeys.backNationality] ||
        ocrDataGovernment?.[ocrDataGovKeys.frontSideCountry],
      documentId:
        documentsData?.extractionData?.documentId?.replace(/[.\-/]/g, '') ||
        documentsData?.extractionData?.documentNumber?.replace(/[.\-/]/g, '') ||
        ocrDataGovernment?.[ocrDataGovKeys.frontDocumentId]?.replace(
          /[.\-/]/g,
          ''
        ) ||
        ocrDataGovernment?.[ocrDataGovKeys.backDocumentId]?.replace(
          /[.\-/]/g,
          ''
        ),
      sex:
        documentsData?.extractionData?.gender ||
        documentsData?.extractionRaw.mrz.gender ||
        documentsData?.extractionRaw.ocr.gender ||
        ocrDataGovernment?.[ocrDataGovKeys.backSex] ||
        ocrDataGovernment?.[ocrDataGovKeys.frontSex],
    }),
    [documentsData, ocrDataGovernment]
  );

  const canValidateGovernment: boolean =
    governmentValidation &&
    !stateGovernmentValidation.loading &&
    !stateGovernmentValidation.data &&
    !!selfie &&
    !!operationId &&
    Object.values(GovernmentCountryCodes).includes(governmentalData.country);

  const ocrCountry = useMemo(() => {
    return ocrData?.personalInformation?.nationality?.length === 3
      ? ocrData?.personalInformation?.nationality
      : documentsData?.extractionData?.documentCountryIssuer;
  }, [ocrData?.personalInformation]);

  const dataSettled = (data: OperationResponse): boolean => {
    let result = false;
    result = data?.details.facialAuthenticateResultList.length > 0;
    return !liveness ? result : result && data.details.facialLivenessResult;
  };

  useEffect(() => {
    let intervalOperation: NodeJS.Timer;
    let data: OperationResponse | any = {};

    if ((appTesting && operationId) || (operationId && matchingResult)) {
      intervalOperation = setInterval(async () => {
        try {
          setStatusPending(true);
          data = await demoRequest(endPoints.Onboardings.Detail, {
            method: RequestMethods.get,
            headers: {
              'x-demo-app-version': demoVersion,
              'x-inphinite-operationid': operationId,
            },
            data: {
              operationId,
            },
          });

          data.operation = {
            ...data.operation,
            ...data.details,
          };

          if (
            data.details.results.length &&
            data.details?.results[data.details.results.length - 1].status
          ) {
            data.operation.status =
              data.details.results[data.details.results.length - 1].status;
          }
          if (dataSettled(data)) {
            dispatchOperation({
              type: ActionRequest.SET_DATA,
              payload: data.operation,
            });
            setStatusPending(false);
            setOCRData(data.details.ocrDataList[0]);
            setAssets(data.details.assets);
            clearInterval(intervalOperation);
            clearTimeout(timeout);
            setGovernmentDataFromSecurity(data.details.securityInfoDataList);
          }
        } catch (err) {
          captureException(err as Error, {
            operation: 'onboardings detail',
            operationId,
          });
          onSetOperationError(true);
          clearInterval(intervalOperation);
        }
      }, 1500);
    }

    const timeout = setTimeout(() => {
      if (
        !validateOperationStatus(data?.operation?.status) &&
        !documentValidation
      ) {
        dispatchOperation({
          type: ActionRequest.SET_DATA,
          payload: data?.operation,
        });
        setStatusPending(false);
        setOCRData(data?.details?.ocrDataList[0]);
        setAssets(data?.details?.assets);
        clearInterval(intervalOperation);
      }
    }, 10000);

    return () => {
      intervalOperation && clearInterval(intervalOperation);
      timeout && clearTimeout(timeout);
    };
  }, [operationId, demoVersion, matchingResult, livenessResult]);

  const setGovernmentDataFromSecurity = (
    securityData: Operation['securityInfoDataList']
  ): void => {
    const securityGovernmentData = securityData?.find((data) =>
      Object.values(GovernmentServices).includes(data.source)
    );

    if (!governmentCard && Object.keys(securityGovernmentData || {}).length) {
      setGovernmentCard(true);
      dispatchGovernmentValidation({
        type: ActionRequest.SET_DATA,
        payload: {
          ...(securityGovernmentData as GovernmentValidationDto),
          data: transformGovernmentData(
            securityGovernmentData as GovernmentValidationDto
          ),
        },
      });
    }
  };

  const transformGovernmentData = (
    governmentData: GovernmentValidationDto
  ): GovernmentPersonalInfo => {
    const isReversed = governmentData.source === GovernmentServices.reniec;
    return transformDate(
      datesKeys,
      objectMapping(governmentData.data, governmentValitionKeys),
      isReversed
    );
  };

  useEffect(() => {
    if (canValidateGovernment) {
      setGovernmentCard(true);
      setShouldCheckGovernment(true);
      checkGovernment();
    }
  }, [selfie, operationId]);

  const checkGovernment = async () => {
    try {
      dispatchGovernmentValidation({ type: ActionRequest.START_REQUEST });
      const governmentResponse: GovernmentValidationDto = await demoRequest(
        endPoints.Governmental,
        {
          method: RequestMethods.post,
          data: {
            family: TrackingFamily.ONBOARDING,
            country: governmentalData.country,
            document: governmentalData.documentId,
            parameters: {
              gender: governmentalData.sex,
              faceImage: getBase64Image(selfie),
            },
          },
          headers: {
            'x-demo-app-version': demoVersion,
            'x-inphinite-sessionid': sessionId,
            'x-inphinite-operationid': operationId,
          },
        }
      );

      if (governmentResponse)
        dispatchGovernmentValidation({
          type: ActionRequest.SET_DATA,
          payload: {
            ...governmentResponse,
            image:
              governmentResponse.image &&
              `data:image/png;base64,${governmentResponse.image}`,
            data: transformGovernmentData(governmentResponse),
          },
        });
    } catch (e: any) {
      dispatchGovernmentValidation({ type: ActionRequest.SET_ERROR });
      captureMessage('Error in governmental validation');
      captureException(e as Error, {
        operation: 'governmental validation',
        data: governmentalData,
        operationId,
        sessionId,
      });
    }
  };

  const checkMorphologicalValidation = async ({
    frontImage,
    backImage,
  }: SecurityDto) => {
    try {
      const response = await demoRequest(endPoints.MorphologicalValidation, {
        method: RequestMethods.post,
        data: {
          family: 'ONBOARDING',
          document: {
            front: frontImage.split(',')[1],
            back: backImage ? backImage.split(',')[1] : '',
            country: ocrCountry,
            idType:
              TypeDocumentDTO[documentType] === TypeDocumentDTO.Custom
                ? TypeDocumentDTO.IDCard
                : TypeDocumentDTO[documentType],
          },
        },
        headers: {
          'x-demo-app-version': demoVersion,
          'x-inphinite-sessionid': sessionId,
          'x-inphinite-operationid': operationId,
        },
      });
      dispatchMorphologicalValidation({
        type: ActionRequest.SET_DATA,
        payload: response,
      });
    } catch (error: any) {
      captureMessage('Error in Morphological validation');
      captureException(error, {
        operation: 'Morphological validation',
        country:
          ocrCountry ?? documentsData?.extractionData?.documentCountryIssuer,
        operationId,
        sessionId,
      });
      dispatchMorphologicalValidation({
        type: ActionRequest.SET_ERROR,
      });
    }
  };

  const checkAntifraud = async ({ selphi }: any) => {
    dispatchAntifraud({ type: ActionRequest.START_REQUEST });
    try {
      const response = await demoRequest(endPoints.AntiFraud, {
        method: RequestMethods.post,
        data: {
          image: selphi,
        },
        headers: {
          'x-demo-app-version': demoVersion,
        },
      });

      dispatchAntifraud({
        type: ActionRequest.SET_DATA,
        payload: response,
      });
    } catch (err) {
      captureException(err as Error, { operation: 'Antifraud check' });
      dispatchAntifraud({
        type: ActionRequest.SET_ERROR,
      });
    }
  };

  const checkPreviousOnboardings = async ({
    assets,
  }: CreatePreviousOnboardingDto) => {
    await demoRequest(endPoints.Onboardings.Create, {
      method: RequestMethods.post,
      data: {
        assets: assets.filter((element) => !!element.data),
      },
      headers: {
        'x-demo-app-version': demoVersion,
        'x-inphinite-sessionid': sessionId,
        'x-inphinite-operationid': operationId,
      },
    }).catch((error) => {
      captureException(error as Error, {
        operation: 'create Previous Onboardings',
        operationId,
        sessionId,
      });
      return;
    });

    const response = await demoRequest(endPoints.Onboardings.Check, {
      method: RequestMethods.post,
      data: {
        image: assets[2].data,
      },
      headers: {
        'x-demo-app-version': demoVersion,
      },
    }).catch((err) => {
      captureException(err, {
        operation: 'check Previous Onboardings',
        operationId,
        sessionId,
      });
      dispatchPreviousOnboardings({
        type: ActionRequest.SET_ERROR,
      });
    });

    dispatchPreviousOnboardings({
      type: ActionRequest.SET_DATA,
      payload: response,
    });
  };

  const onSetOperationError = (error: boolean) => {
    setStatusPending(false);
    if (error) {
      dispatchOperation({ type: ActionRequest.SET_ERROR });
      dispatchAntifraud({ type: ActionRequest.SET_ERROR });
      dispatchMorphologicalValidation({ type: ActionRequest.SET_ERROR });
      dispatchPreviousOnboardings({ type: ActionRequest.SET_ERROR });
    }
  };

  useEffect(() => {
    if (operationId && images.length && antifraud) {
      checkAntifraud({
        selphi: images[2]?.split(',')[1],
      });
    }
  }, [operationId, images.length, antifraud]);

  useEffect(() => {
    if (operationId && images.length && previousOnboardingsValidation) {
      dispatchPreviousOnboardings({ type: ActionRequest.START_REQUEST });
      checkPreviousOnboardings({
        assets: [
          {
            type: 'DOCUMENT_FRONT',
            data: images[0]?.split(',')[1],
          },
          {
            type: 'DOCUMENT_BACK',
            data: images[1]?.split(',')[1],
          },
          {
            type: 'SELFIE',
            data: images[2]?.split(',')[1],
          },
        ],
      });
    }
  }, [operationId, images.length, previousOnboardingsValidation]);

  useEffect(() => {
    if (
      operationId &&
      images.length &&
      documentValidation &&
      ocrData?.personalInformation
    ) {
      // Backend Request: send full frame images instead of cropped images
      checkMorphologicalValidation({
        frontImage: uncroppedImages.frontFull,
        backImage: uncroppedImages.backFull,
      });
    }
  }, [
    operationId,
    images.length,
    documentValidation,
    ocrData?.personalInformation,
  ]);

  useEffect(() => {
    dispatchOperation({ type: ActionRequest.RESET });
    dispatchPreviousOnboardings({ type: ActionRequest.RESET });
    dispatchAntifraud({ type: ActionRequest.RESET });
    dispatchMorphologicalValidation({ type: ActionRequest.RESET });
    dispatchMorphologicalValidation({ type: ActionRequest.START_REQUEST });
    dispatchDocumentSignature({ type: ActionRequest.RESET });
    signDocument(false);
    setImages([]);
    setOCRData(undefined);
    return () => {
      dispatchGovernmentValidation({ type: ActionRequest.RESET });
    };
  }, [operationId]);

  const checkStatus = <T,>(
    item: StateRequest<T>,
    check: () => boolean
  ): StatusOperations => {
    return (!item.data && !item.error) || item.loading
      ? StatusOperations.STARTED
      : item.error
      ? StatusOperations.CONNECTION_ERROR
      : check()
      ? StatusOperations.SUCCEEDED
      : StatusOperations.DENIED;
  };

  return (
    <Ctx.Provider
      value={{
        setSelfie,
        setDocumentsData,
        setGovernmentCard,
        selfie,
        documentSides,
        setDocumentSides,
        facialCapture,
        setFacialCapture,
        checkAntifraud,
        pending: statusPending,
        setStatusPending,
        ocrData,
        assets,
        documentSignature: {
          ...stateDocumentSignature,
          status: checkStatus<SignedDocument>(
            stateDocumentSignature,
            () => !!stateDocumentSignature.data?.signed
          ),
        },
        dispatchDocumentSignature,
        operation: {
          ...stateOperation,
          status: checkStatus<Operation>(
            stateOperation,
            () => stateOperation.data?.status === StatusOperations.SUCCEEDED
          ),
        },
        antifraud: {
          ...stateAntifraud,
          status: checkStatus<AntiFraudDto>(
            stateAntifraud,
            () => !stateAntifraud.data?.matches.length
          ),
        },
        morphologicalValidation: {
          ...stateMorphologicalValidation,
          status: checkStatus<Security>(
            stateMorphologicalValidation,
            () => !!stateMorphologicalValidation.data?.succeed
          ),
        },
        previousOnboardings: {
          ...statePreviousOnboardings,
          status: checkStatus<CheckPreviousOnboardingDto>(
            statePreviousOnboardings,
            () => !statePreviousOnboardings.data?.matches.length
          ),
        },
        governmentValidation: {
          ...stateGovernmentValidation,
          status: checkStatus<GovernmentValidationDto>(
            stateGovernmentValidation,
            () => !!stateGovernmentValidation.data?.succeed
          ),
        },
        signedDocument,
        signDocument,
        governmentCard,
        shouldCheckGovernment,
        setShouldCheckGovernment,
        governmentCountry: governmentalData.country,
        workflow,
        setWorkflow,
        finishedOperation,
        setFinishedOperation,
        uncroppedImages,
        setUncroppedImages,
        matchingResult,
        setMatchingResult,
        livenessResult,
        setLivenessResult,
        antispoofResult,
        setAntispoofResult,
      }}
    >
      {children}
    </Ctx.Provider>
  );
};

export const useOperation = () => useContext(Ctx);
