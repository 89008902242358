import { FlexContainer, useDeviceSize } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router-dom';
import { useOperation } from '../../providers';
import { RoutesName } from '../../states';
import { CardList, DataList } from '../cards/CardList';
import { GoBackHeader } from '../goBackHeader';
import { Banner } from './Banner';

export const OCR = () => {
  const { isMobile } = useDeviceSize();
  const { t } = useTranslation();
  const { operationId } = useParams();

  const { ocrData, pending, operation } = useOperation();

  const ocrDataArray = Object.entries(ocrData?.dataMap || {});

  const data: DataList[] | undefined =
    ocrData &&
    ocrDataArray
      .filter((element) => element[0] != 'documentDataFieldsSerialized')
      .map((item) => ({
        title: item[0],
        value: item[1],
      }));

  const pdfData: DataList[] | undefined =
    ocrData &&
    data?.reduce((acc: DataList[], dataField: DataList) => {
      dataField.title.includes('PDF') && acc.push(dataField);
      return acc;
    }, []);

  return (
    <>
      <GoBackHeader
        route={generatePath(RoutesName.results, {
          operationId,
        })}
        linkLabel="Go back"
        title="Extracted data"
      />
      <Banner />
      <FlexContainer
        columnGap={isMobile ? '0' : '0.8'}
        rowGap={isMobile ? '0.8' : '0'}
        as="section"
        flexDirection={isMobile ? 'column' : 'row'}
      >
        <CardList
          title={t('MRZ Detail')}
          subTitle={t('MRZ') as string}
          data={data?.length ? data : undefined}
          loading={data?.length ? false : pending}
          showEmptyState
          error={!data?.length ? operation.error : false}
        />

        {!!pdfData?.length && (
          <CardList
            title={t('PDF Detail')}
            subTitle={'PDF'}
            data={pdfData?.length ? pdfData : undefined}
            loading={pdfData?.length ? false : pending}
            showEmptyState
            error={!pdfData?.length ? operation.error : false}
            fitContent
          />
        )}
      </FlexContainer>
    </>
  );
};
