import { FlexContainer } from '@facephi/ui-react';
import styled from 'styled-components/macro';
import { mobileStyles } from '../../styles';

export const WorflowWrapper = styled(FlexContainer)<{ $isTip?: boolean }>`
  max-width: ${({ $isTip }) => ($isTip ? '32.6rem' : 'unset')};
  margin-top: 3.2rem;
  ${mobileStyles} {
    max-width: ${({ $isTip }) => ($isTip ? '36rem' : 'unset')};
    margin-top: 0.4rem;
  }
`;
