import { lazy, Suspense } from 'react';
import { Spinner } from '@facephi/ui-react';
import { BrowserRouter, Route, Routes as Switch } from 'react-router-dom';
import Results from '../pages/Results';
import ResultsGovernment from '../pages/ResultsGovernment';
import ResultsOCR from '../pages/ResultsOCR';
import ResultsPreviousOnboardings from '../pages/ResultsPreviousOnboardings';
import ResultsSecurity from '../pages/ResultsSecurity';
import ResultsVerification from '../pages/ResultsVerification';
import Transition from '../pages/Transition';
import { RoutesName } from '../states/constants';
import { lazyWithRetry } from '../utils';
import { ProtectedRoute } from './ProtectedRoute';

const Login = lazy(() => import('../pages/Login'));
const Page404 = lazy(() => import('../pages/Error404'));
const Terms = lazy(() => import('../pages/Terms'));
const Home = lazy(() => import('../pages/Home'));
const Configuration = lazy(() => import('../pages/Configuration'));
const Workflow = lazy(() => import('../pages/Workflow'));

const NoAuthorized = lazyWithRetry(() => import('../pages/NoAuthorized'));

export const Routes = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route path={RoutesName.login} element={<Login />} />
          <Route element={<ProtectedRoute />}>
            <Route path="*" element={<Page404 />} />
            <Route path={RoutesName.notFound} element={<Page404 />} />
            <Route path={RoutesName.terms} element={<Terms />} />
            <Route element={<ProtectedRoute acceptedTerms />}>
              <Route path={RoutesName.home} element={<Home />} />
              <Route
                path={RoutesName.configuration}
                element={<Configuration />}
              />
              <Route element={<ProtectedRoute operationProvider />}>
                <Route path={RoutesName.workflow} element={<Workflow />} />
              </Route>
              <Route element={<ProtectedRoute operation />}>
                <Route path={RoutesName.transition} element={<Transition />} />
              </Route>
              <Route
                path={RoutesName.results}
                element={<ProtectedRoute operationProvider ownOperationId />}
              >
                <Route index element={<Results />} />
                <Route path={RoutesName.ocr} element={<ResultsOCR />} />
                <Route
                  path={RoutesName.security}
                  element={<ResultsSecurity />}
                />
                <Route
                  path={RoutesName.verification}
                  element={<ResultsVerification />}
                />
                <Route
                  path={RoutesName.antiFraud}
                  element={<ResultsPreviousOnboardings />}
                />
                <Route
                  path={RoutesName.government}
                  element={<ResultsGovernment />}
                />
              </Route>
              <Route
                path={RoutesName.noAuthorized}
                element={<NoAuthorized />}
              />
            </Route>
          </Route>
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};
