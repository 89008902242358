/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';
import {
  FlexContainer,
  Icon,
  Label,
  useDeviceSize,
  StatusType,
  IconName,
} from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useRecoilValue } from 'recoil';
import {
  Asset,
  DOCUMENT_FACIAL,
  DOCUMENT_SELFIE,
  FacialAuthenticationStatus,
  FacialLivenessDiagnostic,
  infoFacial,
  StatusTypeList,
  GovernmentServices,
  external,
  StatusOperations,
} from '../../states';
import { livenessValidationState } from '../../states/atoms';
import { EmptyFilesUpload, EmptyFilesError } from '../icons';
import { ImageLoad } from './ImageLoad';
import {
  FacialContainer,
  ResultsInfoBar,
  SelfieValidationResult,
  FacialCheckGrid,
} from './Styles';

type Props = {
  facialState?: FacialAuthenticationStatus;
  livenessState?: FacialLivenessDiagnostic;
  assets?: Asset[];
  loading?: boolean;
  isDetail?: boolean;
  governmentService?: GovernmentServices;
  provider?: string;
  operationStatus?: StatusType | StatusOperations;
  antispoofValidation?: any;
};

const ImageSkeleton = () => (
  <div>
    <Skeleton width={120} height={120} borderRadius={8} />
    <div style={{ marginTop: '0.8rem' }}>
      <Skeleton width="50%" height={18} borderRadius={8} />
    </div>
  </div>
);

export const SelfieValidations = ({
  facialState,
  livenessState,
  assets = [],
  loading,
  isDetail = false,
  provider,
  operationStatus,
  antispoofValidation,
}: Props) => {
  const { t } = useTranslation();
  const { isMobile } = useDeviceSize();
  const livenessValidation = useRecoilValue(livenessValidationState);
  const operationError =
    operationStatus === StatusOperations.CONNECTION_ERROR ||
    operationStatus === StatusOperations.CANCELLED ||
    operationStatus === StatusOperations.ERROR;
  const isExternalProvider = Object.values(GovernmentServices).includes(
    provider as GovernmentServices
  );

  const documentImage = useMemo(
    () =>
      assets.length
        ? isExternalProvider
          ? assets.find(({ source }) =>
              Object.values(GovernmentServices).some(
                (service) => source === service
              )
            )
          : assets.find(({ type }) => type === DOCUMENT_FACIAL)
        : undefined,
    [assets, isExternalProvider]
  );

  const selfieImage = useMemo(
    () =>
      assets.length
        ? assets.find(({ type }) => type === DOCUMENT_SELFIE)
        : undefined,
    [assets]
  );

  const getLoadingImages = () => (
    <FlexContainer columnGap="2" alignItems="center">
      <ImageSkeleton />
      <Skeleton width={60} height={60} circle />
      <ImageSkeleton />
    </FlexContainer>
  );

  const info = useMemo(() => {
    const type = facialState
      ? facialState.toUpperCase() ===
        FacialAuthenticationStatus.positive.toUpperCase()
        ? `match${isExternalProvider ? `_${external}` : ''}`
        : `notMatch${isExternalProvider ? `_${external}` : ''}`
      : operationStatus === StatusTypeList.EXPIRED
      ? 'expired'
      : operationStatus === StatusTypeList.CANCELLED
      ? 'cancelled'
      : !loading
      ? 'failure'
      : undefined;

    return type && infoFacial[type];
  }, [facialState, operationStatus, loading, isExternalProvider]);

  const governementServiceIcon: IconName | undefined = useMemo(() => {
    switch (provider) {
      case GovernmentServices.renaper:
        return 'RenaperIcon';
      case GovernmentServices.reniec:
        return 'ReniecIcon';
      case GovernmentServices.serpro:
        return 'SerproIcon';
      default:
        return undefined;
    }
  }, [provider]);

  const getImages = () => (
    <>
      <FacialContainer>
        {selfieImage ? (
          <ImageLoad asset={selfieImage} testId="image-1" />
        ) : (
          <EmptyFilesError />
        )}
      </FacialContainer>

      {info && (
        <Icon
          testId="selfie-validation-icon"
          key={`provider-${provider}`}
          iconName={info.iconName}
          size="48"
          weight={info.iconWeight}
          color={`${info.iconColor}400`}
        />
      )}

      <FacialContainer>
        {documentImage ? (
          <ImageLoad asset={documentImage} testId="image-2" />
        ) : !isExternalProvider || operationError ? (
          <EmptyFilesError />
        ) : (
          <EmptyFilesUpload />
        )}
      </FacialContainer>
    </>
  );

  const liveness = useMemo(
    () =>
      livenessState
        ? livenessState.toUpperCase() ===
          FacialLivenessDiagnostic.live.toUpperCase()
          ? StatusTypeList.VALIDATE
          : StatusTypeList.DENIED
        : StatusTypeList.IN_PROGRESS,
    [livenessState]
  );

  const antispoofText = useMemo(
    () =>
      antispoofValidation?.attack
        ? StatusTypeList.DENIED
        : StatusTypeList.VALIDATE,
    [antispoofValidation]
  );

  return (
    <>
      {loading ? (
        getLoadingImages()
      ) : info ? (
        <FacialCheckGrid
          alignItems="center"
          columnGap={isMobile ? '0' : '1.4'}
          justifyContent="space-between"
          gridTemplateColumns={
            isMobile ? '12rem auto 12rem' : '15rem auto 15rem'
          }
          rowGap="0.6"
        >
          {getImages()}
          <>
            <Label
              className="asset-text selfie-text"
              fontSize="12"
              textAlign={isMobile ? 'left' : 'center'}
            >
              {t('SELFIE')}
            </Label>
            {(documentImage || operationError) && (
              <Label
                className="asset-text document-text"
                fontSize="12"
                textAlign={isMobile ? 'left' : 'center'}
              >
                {t('DOCUMENT_FACIAL_CAPTURE')}
              </Label>
            )}
          </>
        </FacialCheckGrid>
      ) : null}
      {loading ? (
        <SelfieValidationResult $generalColor="grey">
          <Label fontSize="14" textAlign="center" semibold>
            {t("We're checking faces. Please wait a moment")}
          </Label>
        </SelfieValidationResult>
      ) : info ? (
        <SelfieValidationResult
          columnGap="1.2"
          alignItems="center"
          $generalColor={info.iconColor}
          data-test={`selfie-result-${facialState}`}
          key={`provider-${provider}`}
        >
          {governementServiceIcon && (
            <Icon iconName={governementServiceIcon} size="32" />
          )}
          <Label
            fontSize="14"
            textAlign={governementServiceIcon ? 'left' : 'center'}
            semibold
          >
            {t(info.message || '', { governmentService: provider || '' })}
          </Label>
        </SelfieValidationResult>
      ) : null}

      {livenessValidation && (loading || livenessState) && !isDetail && (
        <ResultsInfoBar
          fontSize="14"
          textAlign="center"
          semibold
          $negative={liveness === StatusTypeList.IN_PROGRESS}
          type={liveness}
          data-test="liveness-status"
        >
          {t(`SELFIE_VALIDATION_${liveness}`)}
        </ResultsInfoBar>
      )}

      {antispoofValidation && (
        <ResultsInfoBar
          fontSize="14"
          textAlign="center"
          semibold
          $negative={false}
          type={
            antispoofValidation.attack
              ? StatusTypeList.DENIED
              : StatusTypeList.SUCCEEDED
          }
          data-test="liveness-status"
        >
          {t(
            `ANTISPOOF_${loading ? StatusTypeList.IN_PROGRESS : antispoofText}`
          )}
        </ResultsInfoBar>
      )}
    </>
  );
};
