/* eslint-disable @typescript-eslint/no-explicit-any */
import { isNil } from 'lodash';
import { flattenDeepObject } from './flattenobject';

type TargetStructure = {
  [key in string]: string[];
};

export const objectMapping = (
  input: object,
  targetStructure: TargetStructure
) => {
  const mappedObject = {};
  const flattenObject = flattenDeepObject(input, true);

  /* Takes the flatten object, deletes the potential blanks in string values, formalise the data, and transforms booleans into No or Yes to be pinted*/

  Object.keys(flattenObject).forEach((key) => {
    const value = flattenObject[key];
    if (typeof value === 'string') {
      const trimmedValue = value.trim();
      if (!trimmedValue) {
        delete flattenObject[key];
      } else {
        flattenObject[key] = trimmedValue;
      }
    }

    if (value === 'true') flattenObject[key] = 'no';
    if (value === 'false') flattenObject[key] = 'yes';
  });

  /* Declares the new properties desired in the resulting object and assigns the
    desired values to these properties
    */

  Object.entries(targetStructure).forEach(([mainKey, innerKeys]) => {
    Object.keys(flattenObject).forEach((internalKey) => {
      if (innerKeys.includes(internalKey)) {
        Object.defineProperty(mappedObject, mainKey, {
          writable: true,
          enumerable: true,
          value: innerKeys.reduce((acc, value) => {
            let retValue;
            if (!isNil(flattenObject[value]) && flattenObject[value] !== '') {
              retValue = `${acc} ${flattenObject[value]}`;
            } else {
              retValue = `${acc}`;
            }
            return retValue.trim();
          }, ''),
        });
      }
    });
  });
  return mappedObject;
};

export const transformDate = (
  dateKeys: string[],
  object: any,
  reversed?: boolean
) => {
  Object.keys(object).forEach((key) => {
    if (dateKeys.includes(key)) {
      object[key] = reversed
        ? new Date(
            object[key].replace(/(\d{4})(\d{2})(\d{2})/, '$1/$2/$3')
          ).toLocaleDateString()
        : object[key].replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3');
    }
  });
  return object;
};
