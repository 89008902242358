import { Layout } from '../components';
import { ResultDetailWrapper, Security } from '../components/results';

const ResultsSecurity = () => {
  return (
    <Layout
      padding
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
    >
      <ResultDetailWrapper flexDirection="column">
        <Security />
      </ResultDetailWrapper>
    </Layout>
  );
};

export default ResultsSecurity;
