export const Hourglass = ({
  width,
  height,
}: {
  width?: string;
  height?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '221'}
    height={height || '220'}
    viewBox="0 0 221 220"
    fill="none"
  >
    <path
      d="M110.012 220C170.762 220 220.012 170.75 220.012 110C220.012 49.25 170.762 0 110.012 0C49.2617 0 0.0117188 49.25 0.0117188 110C0.0117188 170.75 49.2617 220 110.012 220Z"
      fill="#F24B45"
    />
    <path
      d="M89.792 152.43V152.54C89.792 155.56 92.242 158.02 95.272 158.02H124.762C127.782 158.02 130.242 155.57 130.242 152.54V152.32C130.252 152.19 130.792 139.98 122.952 132.41C121.832 131.33 120.122 131.24 118.802 132.07C117.882 132.65 116.892 133.1 115.832 133.41C111.842 134.58 107.542 133.94 103.492 133.5C102.182 133.36 100.822 133.2 99.452 133.11C97.512 132.99 95.622 133.87 94.512 135.47C89.382 142.88 89.802 152.21 89.802 152.33V152.43H89.792Z"
      fill="white"
    />
    <path
      d="M147.232 165H142.802C143.812 163.15 144.392 161.03 144.392 158.77V154.36C144.502 151.59 144.792 129.47 126.722 118.54C121.712 115.51 121.322 112.07 121.312 111.27L121.412 110.74L121.332 110.6V108.74L121.312 108.68C121.342 107.8 121.802 104.43 126.722 101.45C144.802 90.52 144.502 68.4 144.392 65.63V61.22C144.392 58.97 143.812 56.85 142.802 55H147.222C149.982 55 152.222 52.76 152.222 50C152.222 47.24 149.982 45 147.222 45H72.782C70.022 45 67.782 47.24 67.782 50C67.782 52.76 70.022 55 72.782 55H77.202C76.192 56.85 75.612 58.97 75.612 61.22V65.63C75.502 68.4 75.212 90.52 93.282 101.45C98.292 104.48 98.682 107.92 98.692 108.72L98.592 109.25L98.672 109.39V111.25L98.692 111.31C98.662 112.19 98.202 115.56 93.282 118.54C75.202 129.47 75.502 151.59 75.612 154.36V158.77C75.612 161.02 76.192 163.15 77.202 165H72.772C70.012 165 67.772 167.24 67.772 170C67.772 172.76 70.012 175 72.772 175H147.212C149.972 175 152.212 172.76 152.212 170C152.212 167.24 149.972 165 147.212 165H147.232ZM96.912 124.53C106.592 118.68 105.682 110.6 105.682 110.6V109.4C105.682 109.4 106.592 101.32 96.912 95.47C81.412 86.1 82.622 65.8 82.622 65.8V61.23C82.622 57.92 85.312 55.23 88.622 55.23H131.402C134.712 55.23 137.402 57.92 137.402 61.23V65.8C137.402 65.8 138.602 86.1 123.112 95.47C113.432 101.32 114.342 109.4 114.342 109.4V110.6C114.342 110.6 113.432 118.68 123.112 124.53C138.612 133.9 137.402 154.2 137.402 154.2V158.77C137.402 162.08 134.712 164.77 131.402 164.77H88.622C85.312 164.77 82.622 162.08 82.622 158.77V154.2C82.622 154.2 81.422 133.9 96.912 124.53Z"
      fill="white"
    />
  </svg>
);
