import { Label } from '@facephi/ui-react';
import styled from 'styled-components/macro';
import { mobileStyles } from '../../styles';
import { LottieAnimation } from '../lottieAnimation';
import { ValidStatusProps } from './ValidStatus';

const innerHeightMobile = '26.8rem';
const innerHeight = '34.4rem';

export const ValidStatusLottieWrapper = styled(LottieAnimation)<
  Omit<ValidStatusProps, 'state'>
>`
  height: ${innerHeight};
  width: ${innerHeight};

  ${mobileStyles} {
    height: ${innerHeightMobile};
    width: ${innerHeightMobile};
  }
`;

export const ValidStatusLabel = styled(Label)<{ $isError: boolean }>`
  width: ${innerHeight};
  ${({ $isError }) => $isError && `padding: 0 6rem 0`};

  ${mobileStyles} {
    width: ${innerHeightMobile};
    ${({ $isError }) => $isError && `padding: 0 3rem 0`};
  }
`;
