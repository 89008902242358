import { useEffect } from 'react';
import { Spinner } from '@facephi/ui-react';
import { useLoadAsset, useVariables } from '../../hooks';
import { Asset } from '../../states';
import { EmptyFilesError } from '../icons';

type IProps = {
  asset: Asset;
  testId?: string;
};

export const ImageLoad = ({ asset, testId }: IProps) => {
  const isBase64Asset =
    /^data:((?:\w+\/(?:(?!;).)+)?)((?:;[\w=]*[^;])*),(.+)$/.test(asset.url);
  const { trackingUrlAssets } = useVariables();
  const { resourceUrl, loadingResource, cancelSource, hasError } = useLoadAsset(
    `${isBase64Asset ? '' : trackingUrlAssets}${asset.url}`,
    asset.contentType
  );

  useEffect(() => {
    return () => cancelSource();
  }, []);

  return loadingResource ? (
    <Spinner size="S" insideComponent type="block" />
  ) : hasError ? (
    <EmptyFilesError />
  ) : (
    <img
      src={isBase64Asset ? asset.url : resourceUrl}
      alt={asset.type}
      data-test={testId}
    />
  );
};
