import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { ISignType } from '../model';

const { persistAtom } = recoilPersist();

export const signState = atom<boolean>({
  key: 'signState',
  default: false,
  effects_UNSTABLE: [persistAtom],
});

export const signTypeState = atom<ISignType>({
  key: 'signTypeState',
  default: ISignType.selfie,
  effects_UNSTABLE: [persistAtom],
});
