import { Label, useDeviceSize } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router-dom';
import { FacialAuthenticationStatus, RoutesName } from '../../states';
import { CardResults, CardResultsContent } from '../cards';
import { CardList, DataList } from '../cards/CardList';
import { GoBackHeader } from '../goBackHeader';
import { Banner } from './Banner';
import { ocrMock } from './MockData';
import { SelfieValidations } from './SelfieValidation';
import { VerificationCardWrapper } from './Styles';

export const Verification = () => {
  const { t } = useTranslation();
  const { isMobile } = useDeviceSize();
  const { operationId } = useParams();

  const dataMRZ: DataList[] = [
    {
      title: 'asdsa',
      value:
        'IDARG35214173<6<<<<<<<<<<<9005031F34050653ARG<<<<<<8CAMPOSREYES<<SOFIA<<<<<<',
    },
    {
      title: 'asdsa',
      value:
        'IDARG35214173<6<<<<<<<<<<<9005031F34050653ARG<<<<<<8CAMPOSREYES<<SOFIA<<<<<<',
    },
    {
      title: 'title 3',
      value: 'value 3',
    },
    {
      title: 'title 4',
      value: 'value 4',
    },
  ];

  return (
    <>
      <GoBackHeader
        route={generatePath(RoutesName.results, {
          operationId,
        })}
        linkLabel="Go back"
        title="Verification"
      />
      <Banner />
      <CardResults title={t('BIOMETRIC VALIDATION') as string}>
        <CardResultsContent
          flexDirection="column"
          alignItems={isMobile ? 'start' : 'center'}
          rowGap="1.6"
        >
          <Label fontSize="21" semibold>
            {t('FACE VERIFICATION')}
          </Label>

          <SelfieValidations
            assets={ocrMock.documents}
            isDetail
            facialState={FacialAuthenticationStatus.positive}
          />
        </CardResultsContent>
      </CardResults>

      <VerificationCardWrapper
        columnGap={isMobile ? '0' : '0.8'}
        rowGap={isMobile ? '0.8' : '0'}
        forwardedAs="section"
        flexDirection={isMobile ? 'column' : 'row'}
        alignItems={'baseline'}
      >
        <CardList
          title={t('Data obtained')}
          subTitle={t('DATA VERIFICATION') as string}
          data={dataMRZ}
        />
        <CardList
          title={t('PDF Detail')}
          subTitle={t('DOCUMENT') as string}
          data={dataMRZ}
        />
      </VerificationCardWrapper>
    </>
  );
};
