import { useEffect, useMemo } from 'react';
import { FlexContainer, useDeviceSize } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useOperation } from '../../providers';
import { RoutesName, StatusOperations } from '../../states';
import { documentValidationState } from '../../states/atoms';
import { flattenDeepObject } from '../../utils';
import { CardList, DataList } from '../cards/CardList';
import { GoBackHeader } from '../goBackHeader';
import { Banner } from './Banner';

export const Security = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { operationId } = useParams();

  const { isMobile } = useDeviceSize();
  const { morphologicalValidation } = useOperation();
  const documentValidation = useRecoilValue(documentValidationState);

  useEffect(() => {
    if (!documentValidation) {
      navigate(RoutesName.notFound, { replace: true });
    }
  }, [documentValidation]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getValues = (values?: Record<string, unknown>): DataList[] | [] => {
    const data = flattenDeepObject(values, false);
    return values
      ? Object.entries(data).map(([title, value]) => ({
          title,
          value: '' + value,
        }))
      : [];
  };

  const data: DataList[] | undefined = useMemo(() => {
    return [
      {
        title: 'Status',
        value: t(morphologicalValidation.status || ''),
        valueStatus:
          morphologicalValidation.status === StatusOperations.SUCCEEDED
            ? 'success400'
            : 'error400',
      },
      ...getValues(morphologicalValidation.data?.data),
    ];
  }, [morphologicalValidation.data]);

  return (
    <>
      <GoBackHeader
        route={generatePath(RoutesName.results, {
          operationId,
        })}
        linkLabel="Go back"
        title="Morphological"
      />
      <Banner />
      <FlexContainer
        columnGap={isMobile ? '0' : '0.8'}
        rowGap={isMobile ? '0.8' : '0'}
        as="section"
        flexDirection={isMobile ? 'column' : 'row'}
      >
        <CardList
          title={t('Test detail')}
          subTitle={'Tests'}
          data={data}
          columnsPerRow={
            isMobile ||
            (!morphologicalValidation.data && !morphologicalValidation.loading)
              ? '1'
              : '2'
          }
          loading={morphologicalValidation.loading}
          error={morphologicalValidation.error}
          showEmptyState
        />
      </FlexContainer>
    </>
  );
};
