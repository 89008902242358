import { ReactNode } from "react";
import { ErrorFallback } from "./ErrorFallback";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";

interface IProps {
  children: ReactNode;
};


export const ErrorBoundary = ({ children }: IProps) =>
  <SentryErrorBoundary fallback={<ErrorFallback />}>
    {children}
  </SentryErrorBoundary>;