import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();
export const termsState = atom<string>({
  key: 'termsState',
  default: '',
  effects_UNSTABLE: [persistAtom],
});

export const termsAcceptedState = atom<boolean>({
  key: 'termsAcceptedState',
  default: false,
  effects_UNSTABLE: [persistAtom],
});
