/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-explicit-any */
export const flattenDeepObject = (input: any, onlyOneKey: boolean) => {
  const result: any = {};
  for (const key in input) {
    if (!input.hasOwnProperty(key)) {
      continue;
    }
    if (typeof input[key] === 'object') {
      const subFlatObject = flattenDeepObject(input[key], onlyOneKey);
      for (const subkey in subFlatObject) {
        if (onlyOneKey) {
          result[subkey] = subFlatObject[subkey];
        } else {
          result[key + '_' + subkey] = subFlatObject[subkey];
        }
      }
    } else {
      result[key] = input[key] as any;
    }
  }
  return result;
};
