/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { v4 } from 'uuid';
import { useAuth } from '../providers';
import { AuthName } from '../states/constants';
import { useLogger } from './useLogger';
import { useVariables } from './useVariables';

export enum RequestMethods {
  post = 'post',
  get = 'get',
}

export type RequestData = {
  [key: string]: string | boolean;
};

export type RequestOptions = {
  method: RequestMethods;
  params?: any;
  data?: any;
  headers?: {
    [key: string]: string | boolean | undefined;
  };
};

export function useRequest(baseUrl?: string, auth = true) {
  const { apiUrl, appScope, appScopeVersion } = useVariables();
  const { logout, handleRefreshToken } = useAuth();
  const { captureException } = useLogger();

  const requestBasic = (url: string, options: RequestOptions) =>
    axios({
      method: options.method,
      url: `${baseUrl || apiUrl}${url}`,
      data: options.data,
      params: options.params,
      headers: {
        ...options.headers,
        Authorization: auth
          ? `Bearer ${localStorage.getItem(AuthName.token)}`
          : '',
      },
    })
      .then((response) => response.data)
      .catch((error) => {
        captureException(error, {
          operation: 'requestBasic',
          url,
          options,
        });
        throw new Error(error);
      });

  const request = (url: string, options: RequestOptions, withHeaders = false) =>
    axios({
      method: options.method,
      url: `${baseUrl || apiUrl}${url}`,
      params: options.params,
      data: options.data,
      headers: {
        ...options.headers,
        Authorization: auth
          ? `Bearer ${localStorage.getItem(AuthName.token)}`
          : '',
        'correlation-id': v4(),
        appscope: appScope as string,
        'appscope-version': appScopeVersion as string,
      },
    })
      .then((response) => (withHeaders ? response : response.data))
      .catch((error) => {
        captureException(error, {
          operation: 'request',
          url,
          options,
        });
        if (error.response.status === 401) {
          return handleRefreshToken()
            .catch(() => {
              logout();
            })
            .then(async () => {
              const config = error.config;
              config.headers['Authorization'] = `Bearer ${localStorage.getItem(
                AuthName.token
              )}`;
              return axios.request(config).then((response) => response.data);
            });
        } else {
          throw new Error(error);
        }
      });

  return {
    request,
    requestBasic,
  };
}
