import { Label, useDeviceSize } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import preResults from '../assets/json/pre-results.json';
import { Layout, Lottie } from '../components';
import { LabelsWrapper, TransitionWrapper } from '../components';
import { ErrorBoundary } from '../components';
import { RoutesName } from '../states';
import { operationIdState } from '../states/atoms/operationId';

const Transition = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const operationId = useRecoilValue(operationIdState);
  const { isMobile } = useDeviceSize();

  const handleOnComplete = () => {
    navigate(
      generatePath(RoutesName.results, {
        operationId,
      })
    );
  };

  return (
    <Layout padding>
      <ErrorBoundary>
        <TransitionWrapper
          flexDirection="column"
          alignItems="center"
          rowGap="3.2"
        >
          <Label
            fontSize={isMobile ? '24' : '32'}
            semibold
            data-test="services-title"
            textAlign="center"
            id="loading_screen_title"
          >
            {t('We are compiling the results!')}
          </Label>
          <Lottie
            loop={false}
            animation={preResults}
            style={{ maxHeight: `${isMobile ? '24rem' : '36rem'}` }}
            preserveAspectRatio="xMidYMid meet"
            onComplete={handleOnComplete}
          />
          <LabelsWrapper
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            rowGap="0.8"
          >
            <Label
              semibold
              fontSize="18"
              textAlign="center"
              id="loading_screen_text1"
            >
              {t('Your data is safe')}
            </Label>
            <Label fontSize="16" textAlign="center" id="loading_screen_text2">
              {t(
                'This technology has already been used by more than 300 million users.'
              )}
            </Label>
          </LabelsWrapper>
        </TransitionWrapper>
      </ErrorBoundary>
    </Layout>
  );
};

export default Transition;
