import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { TypeDocument } from '../constants';

const { persistAtom } = recoilPersist();
export const documentTypeState = atom<TypeDocument>({
  key: 'documentTypeState',
  default: TypeDocument.custom,
  effects_UNSTABLE: [persistAtom],
});
