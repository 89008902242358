import { useEffect } from 'react';
import { useSdk } from '@facephi/sdk-web';
import { useRecoilValue } from 'recoil';
import {
  ErrorBoundary,
  Layout,
  Results as ResultsPage,
  SimpleResults as SimpleResultsPage,
} from '../components';
import { ResultsScreenType } from '../states';
import { resultsScreenState, recordOnboardingState } from '../states/atoms';

const Results = () => {
  const { stopVideo } = useSdk();
  const resultsScreen = useRecoilValue(resultsScreenState);
  const recordOnboarding = useRecoilValue(recordOnboardingState);

  useEffect(() => {
    recordOnboarding && stopVideo();
  }, []);

  return (
    <Layout
      justifyContent="flex-start"
      alignItems="center"
      flexDirection="column"
    >
      <ErrorBoundary>
        {resultsScreen === ResultsScreenType.detail ? (
          <ResultsPage />
        ) : (
          <SimpleResultsPage />
        )}
      </ErrorBoundary>
    </Layout>
  );
};

export default Results;
