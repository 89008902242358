/* eslint-disable @typescript-eslint/no-explicit-any */
export function useVariables() {
  const authUrl: string | undefined = (window as any)._env_
    .REACT_APP_AUTH_API_URL;
  const appApiId: string | undefined = (window as any)._env_
    .REACT_APP_AUTH_APPLICATION_ID;
  const appTesting: string | undefined =
    process.env.REACT_APP_TEST || (window as any)._env_.REACT_APP_TEST;
  const apiUrl: string | undefined = (window as any)._env_.REACT_APP_API_URL;
  const appScope: string | undefined = (window as any)._env_.REACT_APP_SCOPE;
  const appScopeVersion: string | undefined = (window as any)._env_
    .REACT_APP_SCOPE_VERSION;
  const trackingUrl: string | undefined = (window as any)._env_
    .REACT_APP_API_TRACKING;
  const trackingUrlAssets: string | undefined = (window as any)._env_
    .REACT_APP_API_ASSETS;
  const clientId: string | undefined = (window as any)._env_
    .REACT_APP_CLIENTID_TRACKING;
  const clientSecret: string | undefined = (window as any)._env_
    .REACT_APP_CLIENTSECRET_TRACKING;
  const tenantId: string | undefined = (window as any)._env_
    .REACT_APP_TENANT_ID;
  const trackingAuthUrl: string | undefined = (window as any)._env_
    .REACT_APP_AUTH_TRACKING_URL;
  const apiClients: string | undefined = (window as any)._env_
    .REACT_APP_API_CLIENTS;
  const apiBackoffice: string | undefined = (window as any)._env_
    .REACT_APP_API_BACKOFFICE;
  const appVersion: string | undefined = process.env.REACT_APP_VERSION;
  const videoApiKey: string | undefined = (window as any)._env_
    .REACT_APP_VIDEO_API_KEY;
  const cloudApi: string | undefined = (window as any)._env_
    .REACT_APP_CLOUD_API;
  const inspectletId: string | undefined = (window as any)._env_
    .REACT_APP_INSPECTLET_ID;
  const videoAssistanceUrl: string | undefined = (window as any)._env_
    .REACT_APP_VIDEO_ASSISTANCE_WS_URL;
  const videoAssistanceWsApiKey: string | undefined = (window as any)._env_
    .REACT_APP_VIDEO_ASSISTANCE_WS_API_KEY;
  const apiProviders: string | undefined = (window as any)._env_
    .REACT_APP_API_PROVIDERS;
  const posthogToken: string | undefined = (window as any)._env_
    .REACT_APP_POSTHOG_TOKEN;
  const posthogApi: string | undefined = (window as any)._env_
    .REACT_APP_POSTHOG_API;
  const posthogHost: string | undefined = (window as any)._env_
    .REACT_APP_POSTHOG_HOST;
  const apiDemoUrl: string | undefined = (window as any)._env_
    .REACT_APP_API_DEMO_URL;
  const licenseKeyFirstParty: string | undefined = (window as any)._env_
    .REACT_APP_LICENSE_KEY_FIRST_PARTY;
  const licenseKeyThirdParty: string | undefined = (window as any)._env_
    .REACT_APP_LICENSE_KEY;
  const wiremockUrl: string | undefined =
    process.env.REACT_APP_WIREMOCK_URL ||
    (window as any)._env_.REACT_APP_WIREMOCK_URL;
  const demoBackend: string | undefined =
    process.env.REACT_APP_DEMO_BACKEND ||
    (window as any)._env_.REACT_APP_DEMO_BACKEND;

  return {
    authUrl,
    appApiId,
    apiUrl,
    appScope,
    appScopeVersion,
    appTesting: !!appTesting,
    trackingUrl,
    trackingUrlAssets,
    clientId,
    clientSecret,
    tenantId,
    trackingAuthUrl,
    apiClients,
    apiBackoffice,
    appVersion,
    videoApiKey,
    cloudApi,
    inspectletId,
    videoAssistanceUrl,
    videoAssistanceWsApiKey,
    apiProviders,
    posthogToken,
    posthogApi,
    posthogHost,
    apiDemoUrl,
    licenseKeyFirstParty,
    licenseKeyThirdParty,
    wiremockUrl,
    demoBackend,
  };
}
