import { createContext, useContext, useEffect, useState } from 'react';
import {
  SdkContext,
  SdkContextProps,
  SdkProvider,
  TypeFamily,
} from '@facephi/sdk-web';
import { useToast } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { VideoAssistance } from '../components';
import { useVariables } from '../hooks';
import { useAuth, useConfig } from '../providers';
import { operationIdState } from '../states/atoms/operationId';
import { DEFAULT_COUNTRY } from '../states/constants';

type IProps = {
  children: React.ReactNode;
};

type ContextProps = {
  helpVideoAssistance: (id: string) => void;
};

const Ctx = createContext<ContextProps>({
  helpVideoAssistance: () => {},
});

export const ExtendedSdkProvider = ({ children }: IProps) => {
  const {
    appTesting,
    trackingUrl,
    trackingUrlAssets,
    clientId,
    trackingAuthUrl,
    clientSecret,
    videoApiKey,
    cloudApi,
    videoAssistanceUrl,
    videoAssistanceWsApiKey,
    posthogToken,
    posthogHost,
  } = useVariables();
  const { getWorkflow } = useConfig();
  const { email, hasVideoAssistance, tenantDefault } = useAuth();
  const { t, i18n } = useTranslation();
  const { toastManager } = useToast();
  const [videoAssistanceToast, setvideoAssistanceToast] = useState(false);
  const operationId = useRecoilValue(operationIdState);
  const [key, setKey] = useState(0);

  useEffect(() => {
    setvideoAssistanceToast(false);
  }, [operationId]);

  useEffect(() => {
    setKey((key) => key + 1);
  }, [tenantDefault]);

  const handleVideoAssistance = ({
    id,
    isActivated,
    start,
  }: {
    id: string;
    isActivated: boolean;
    start(id: string): void;
  }) => {
    if (!videoAssistanceToast && hasVideoAssistance && !isActivated) {
      setvideoAssistanceToast(true);
      toastManager({
        type: 'default',
        message: t('Do you need help with your onboarding?'),
        onClick: () => {
          start(id);
        },
        buttonMessage: t('Call').toUpperCase(),
      });
    }
  };

  return (
    <SdkProvider
      key={key}
      bundlePath={`${window.location.origin}/widgets`}
      videoAssistanceOptions={
        hasVideoAssistance
          ? {
              wsUrl: videoAssistanceUrl || '',
              language: i18n.language,
              caller: email,
              wsApiKey: videoAssistanceWsApiKey || '',
            }
          : undefined
      }
      videoOptions={{
        apiKey: videoApiKey || '',
        baseUrl: cloudApi || '',
        autoStart: false,
      }}
      workflowOptions={{
        bundlePath: `${process.env.PUBLIC_URL}/widgets`,
        workflow: getWorkflow(),
        apiUrl: '',
        testing: appTesting,
        autoStart: false,
        defaultCountry: DEFAULT_COUNTRY,
      }}
      customerId={email}
      trackingOptions={{
        trackingUrl: trackingUrl || '',
        trackingUrlAssets: trackingUrlAssets || '',
        clientId: clientId || '',
        authUrl: trackingAuthUrl || '',
        clientSecret: clientSecret || '',
        tenantId: tenantDefault || '',
        type: TypeFamily.onboarding,
        disabled: appTesting || !trackingUrl,
      }}
      metricsOptions={{
        token: posthogToken || '',
        host: posthogHost || '',
        type: TypeFamily.onboarding,
        clientId: clientId || '',
      }}
    >
      <SdkContext.Consumer>
        {(ctx: SdkContextProps) => (
          <Ctx.Provider
            value={{
              helpVideoAssistance: (id: string) =>
                handleVideoAssistance({
                  id,
                  isActivated: ctx.activatedVideoAssistance,
                  start: ctx.startVideoAssistance,
                }),
            }}
          >
            <VideoAssistance />
            {children}
          </Ctx.Provider>
        )}
      </SdkContext.Consumer>
    </SdkProvider>
  );
};

export const useExtendedSdk = () => useContext(Ctx);
