import { FacialAuthenticationStatus } from './results';
export type ResponseFacialAuthentication = {
  type?: string;
  similarity: number;
  authStatus: FacialAuthenticationStatus;
};

export type ResponseLiveness = {
  type?: string;
  diagnostic: LivenessStatusResponse;
  description?: string;
  diagnosticValue?: number;
};

export type ResponseAntispoof = {
  attack: boolean;
};

export enum LivenessStatusResponse {
  Spoof = 'Spoof',
  None = 'None',
  Uncertain = 'Uncertain',
  Live = 'Live',
  NoneBecauseBadQuality = 'NoneBecauseBadQuality',
  NoneBecauseFaceTooClose = 'NoneBecauseFaceTooClose',
  NoneBecauseFaceNotFound = 'NoneBecauseFaceNotFound',
  NoneBecauseFaceTooSmall = 'NoneBecauseFaceTooSmall',
  NoneBecauseAngleTooLarge = 'NoneBecauseAngleTooLarge',
  NoneBecauseImageDataError = 'NoneBecauseImageDataError',
  NoneBecauseInternalError = 'NoneBecauseInternalError',
  NoneBecauseImagePreprocessError = 'NoneBecauseImagePreprocessError',
  NoneBecauseTooManyFaces = 'NoneBecauseTooManyFaces',
  NoneBecauseFaceTooCloseToBorder = 'NoneBecauseFaceTooCloseToBorder',
  NoneBecauseFaceCropped = 'NoneBecauseFaceCropped',
  NoneBecauseLicenseError = 'NoneBecauseLicenseError',
  NoneBecauseFaceOccluded = 'NoneBecauseFaceOccluded',
}
