import BackImage from '../../assets/img/back.png';
import FrontImage from '../../assets/img/dni-frente.png';
import SelfieImage from '../../assets/img/selfie.png';
import { MimeType } from '../../states';

export const ocrMock = {
  lastUpdateTime: 'date',
  currentStep: 'Login',
  steps: 'step',
  status: 'PENDING',
  country: 'ESPAÑA',
  coords: {
    latitude: 38.336951,
    longitude: -0.507384,
  },
  documents: [
    {
      contentType: MimeType.png,
      type: 'Front',
      url: FrontImage,
    },
    {
      contentType: MimeType.png,
      type: 'Back',
      url: BackImage,
    },
    {
      contentType: MimeType.png,
      type: 'Selfie',
      url: SelfieImage,
    },
  ],
  ocrData: {
    'Back/MRZ/Opt1': 'CONSECTEUR ADIPISCING ELIT',
    'Back/MRZ/Opt2':
      'IDARG35214173<6<<<<<<<<<<<9005031F34050653ARG<<<<<<8CAMPOSREYES<<SOFIA<<<<<<',
    'Back/MRZ/Opt3': 'ICONSECTEUR',
  },
  secutiryData: {
    'Estado de verificación': 'VERIFICADO',
    'Estado de escaneo': 'EXISTOSO',
    'Fuente de escaneo': 'SDK',
    'Posiciones de Datos': 'OK',
    'Validación de documento': 'OK',
    'Validación Holograma': 'OK',
    'Verificación Código MRZ': 'OK',
    'Verificación Microimpresión': 'OK',
    'Verificación de características': 'OK',
    'Verificación de Firma': 'OK',
    'Tipo de Identificación': 'Documento de Identidad',
    'Subtipo de Identificación': 'DNI',
    País: 'ARG',
    'Motivo de rechazo': 'No hay',
  },
  personalData: {
    Nombre: 'Sofía',
    Apellido: 'Reyes Campos',
    Nacionalidad: 'ESP',
    Dni: '71579781D',
    Nacimiento: '03/05/1990',
    Pais: 'Argentina',
    Direccion: 'CALLE ANTIGUA 2 P03 AK',
    Ciudad: 'Alicante',
  },
};
