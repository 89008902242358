import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useToast } from '@facephi/ui-react';
import axios from 'axios';
import jwt, { Jwt, JwtPayload } from 'jsonwebtoken';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  RequestMethods,
  useOperationId,
  useVariables,
  useLogger,
} from '../hooks';
import { useLog } from '../providers';
import { livenessValidationState, termsAcceptedState } from '../states/atoms';
import { Storage, endPoints, AuthName } from '../states/constants';
import {
  ClaimsList,
  ClaimsType,
  CLAIMS_TOKEN,
  LoginDto,
  TokenGrantType,
} from '../states/model';

type IProps = {
  children: React.ReactNode;
};

type ContextProps = {
  logged: boolean;
  logout(): void;
  terms: boolean;
  loadingTerms: boolean;
  termsText?: string;
  handleLogin(user: LoginDto): void;
  acceptTerms(): void;
  checkTermsAccepted(): void;
  handleRefreshToken(): Promise<boolean>;
  refreshToken: string | null;
  error: string | null;
  token: string | null;
  tokenValid: boolean;
  email?: string;
  canDisableLiveness?: boolean;
  hasVideoAssistance: boolean;
  hasAntifraud: boolean;
  hasPad: boolean;
  tenantDefault?: string;
  demoVersion: string;
  initError: boolean;
};

const Ctx = createContext<ContextProps>({
  logged: false,
  terms: false,
  loadingTerms: false,
  termsText: '',
  logout: () => {},
  handleLogin: () => {},
  acceptTerms: () => {},
  checkTermsAccepted: () => {},
  handleRefreshToken: () => new Promise((resolve) => resolve(true)),
  error: null,
  token: null,
  tokenValid: false,
  refreshToken: null,
  canDisableLiveness: false,
  hasVideoAssistance: false,
  hasAntifraud: false,
  hasPad: false,
  demoVersion: '',
  initError: false,
});

export const AuthProvider = ({ children }: IProps) => {
  const { clearStorage } = useOperationId();
  const {
    authUrl,
    appApiId,
    apiDemoUrl,
    clientId,
    trackingAuthUrl,
    clientSecret,
    wiremockUrl,
    appTesting,
  } = useVariables();
  const { toastManager } = useToast();
  const { t } = useTranslation();
  const { setInspectletTag } = useLog();
  const [tenantDefault, setTenantDefault] = useState<string>();

  const [logged, setLogged] = useState<boolean>(
    localStorage.getItem(Storage.token) ? true : false
  );
  const [terms, setTerms] = useRecoilState(termsAcceptedState);
  const setLivenessValidation = useSetRecoilState(livenessValidationState);
  const [loadingTerms, setLoadingTerms] = useState<boolean>(true);
  const [termsText, setTermsText] = useState<string>();
  const [claims, setClaims] = useState<ClaimsType>();

  const [token, setToken] = useState<string | null>(
    localStorage.getItem(Storage.token)
  );
  const [refreshToken, setRefreshToken] = useState<string | null>(
    localStorage.getItem(Storage.refreshToken)
  );

  const [error, setError] = useState<string | null>(null);

  const [demoVersion, setDemoVersion] = useState<string>('');
  const [initError, setInitError] = useState<boolean>(false);

  const { captureException, captureMessage, setUser } = useLogger();

  const fetchingDemoVersion = useRef<boolean>(false);
  const fetchingTrackingToken = useRef<boolean>(false);

  const isLogged = (token: string, refreshToken: string) => {
    localStorage.setItem(Storage.token, token);
    localStorage.setItem(Storage.refreshToken, refreshToken);
    setLogged(true);
  };

  const logout = () => {
    localStorage.removeItem(Storage.token);
    localStorage.removeItem(Storage.refreshToken);
    clearStorage();
    setLogged(false);
    setTenantDefault(undefined);
  };

  const checkTermsAccepted = () => {
    axios({
      method: RequestMethods.get,
      url: `${apiDemoUrl}${endPoints.TermsAcceptance}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem(AuthName.token)}`,
        'x-demo-app-version': demoVersion,
      },
      params: {
        userId: claims?.[ClaimsList.email],
      },
    })
      .then((response) => {
        const { userId } = response.data;
        if (userId && logged) {
          setTerms(true);
          setLoadingTerms(false);
        }
      })
      .catch(() => {
        setLoadingTerms(false);
        if (appTesting) {
          logged &&
            toastManager({
              duration: 3500,
              type: 'error',
              canClose: true,
              message: t('T&C not accepted yet'),
              testId: 'toast-get-accepted-ko',
            });
        }
      });
  };

  const acceptTerms = () => {
    axios({
      method: RequestMethods.post,
      url: `${apiDemoUrl}${endPoints.TermsAcceptance}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem(AuthName.token)}`,
        'x-demo-app-version': demoVersion,
      },
      data: {
        userId: claims?.[ClaimsList.email],
      },
    })
      .then((response) => {
        const { userId } = response.data;
        setTerms(true);
        if (appTesting) {
          toastManager({
            duration: 3500,
            type: 'success',
            message: `${t('T&C accepted successfully')}: ${userId}`,
            testId: 'toast-accepted-ok',
          });
        }
      })
      .catch((error) => {
        captureException(error as Error, { operation: 'acceptingTAC' });
        if (appTesting) {
          toastManager({
            duration: 3500,
            type: 'error',
            message: t('Something went wrong with the acceptance of T&C'),
            testId: 'toast-accepted-ko',
          });
        }
      });
  };

  const getClaims = (tokenInfo: JwtPayload) =>
    Object.entries(tokenInfo).reduce((acc, [key, value]) => {
      if (key.includes(CLAIMS_TOKEN)) {
        acc[key.replace(CLAIMS_TOKEN, '')] = value;
      }
      return acc;
    }, {} as ClaimsType);

  const handleRefreshToken = (): Promise<boolean> =>
    new Promise((resolve, reject) => {
      axios
        .post(`${authUrl}/auth/token`, {
          application_api_id: appApiId,
          grant_type: TokenGrantType.RefreshToken,
          refresh_token: refreshToken,
        })
        .then((response) => {
          if (
            !!localStorage.getItem(Storage.token) &&
            !!localStorage.getItem(Storage.refreshToken)
          ) {
            setToken(response.data.access_token);
            setRefreshToken(response.data.refresh_token);

            isLogged(response.data.access_token, response.data.refresh_token);
            resolve(true);
          }
        })
        .catch(() => {
          reject(false);
        });
    });

  const handleLogin = async (user: LoginDto) => {
    try {
      const url = wiremockUrl ? `${wiremockUrl}/auth` : `${authUrl}/auth/token`;
      const response = await axios
        .post(url, {
          application_api_id: appApiId,
          grant_type: TokenGrantType.PasswordRealm,
          ...user,
        })
        .then((response) => {
          return response.data;
        });
      setToken(response.access_token);
      setRefreshToken(response.refresh_token);

      isLogged(response.access_token, response.refresh_token);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setError(error.response?.data.title);
      }
    }
  };

  const tokenValid = useMemo(() => {
    if (token) {
      const decodeToken: Jwt | null = jwt.decode(token, { complete: true });
      const dateNow = new Date();
      return (
        ((decodeToken?.payload as JwtPayload)?.exp || 0) * 1000 >
        dateNow.getTime()
      );
    }

    return false;
  }, [token]);

  useEffect(() => {
    setError(null);
  }, [logged]);

  useEffect(() => {
    if (token) {
      const tokenInfo: JwtPayload = jwt.decode(token) as JwtPayload;

      const claims = getClaims(tokenInfo);

      if (claims.tenant) {
        if (claims[ClaimsList.tenant] instanceof Array) {
          setTenantDefault(claims[ClaimsList.tenant][0] as string);
        }
      }

      setClaims(claims);
      setInspectletTag(['identify', claims[ClaimsList.email]]);
      setInspectletTag(['tagSession', { email: claims[ClaimsList.email] }]);
      setUser({
        email: claims[ClaimsList.email],
      });
    }
  }, [token]);

  useEffect(() => {
    if (
      !demoVersion &&
      logged &&
      !fetchingDemoVersion.current &&
      !wiremockUrl
    ) {
      fetchingDemoVersion.current = true;
      captureMessage('Fetching demo version');
      axios({
        method: RequestMethods.get,
        url: `${apiDemoUrl}${endPoints.Versions}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem(AuthName.token)}`,
        },
      })
        .then((response) => {
          const { latest } = response.data;
          setDemoVersion(latest);
          fetchingDemoVersion.current = false;
        })
        .catch((error) => {
          captureException(error as Error, { operation: 'fetchDemoVersion' });
          if (error?.response?.status === 401) {
            handleRefreshToken()
              .then(() => {
                const config = error.config;
                config.headers[
                  'Authorization'
                ] = `Bearer ${localStorage.getItem(AuthName.token)}`;
                axios.request(config).then((response) => {
                  const { latest } = response.data;
                  setDemoVersion(latest);
                  fetchingDemoVersion.current = false;
                });
              })
              .catch(() => {
                fetchingDemoVersion.current = false;
                setInitError(true);
              });
          } else {
            fetchingDemoVersion.current = false;
            setInitError(true);
            throw new Error(error);
          }
        });
    }
    return () => setInitError(false);
  }, [logged]);

  useEffect(() => {
    if (demoVersion) {
      axios({
        method: RequestMethods.get,
        url: `${apiDemoUrl}${endPoints.Terms}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem(AuthName.token)}`,
          'x-demo-app-version': demoVersion,
        },
      })
        .then((response) => {
          const { content } = response.data;
          setTermsText(content);
        })
        .catch((error) => {
          captureException(error as Error, { operation: 'fetchingTAC' });
          if (appTesting) {
            toastManager({
              duration: 3500,
              type: 'error',
              canClose: true,
              message: t('Error while trying to retrieve TAC'),
              testId: 'toast-get-accepted-ko',
            });
          }
        });
    }
  }, [demoVersion]);

  useEffect(() => {
    if (termsText && claims?.[ClaimsList.email]) {
      checkTermsAccepted();
    }
  }, [termsText, claims]);

  useEffect(() => {
    if (appTesting) {
      setLivenessValidation(false);
    } else if (!claims?.[ClaimsList.liveness]) {
      setLivenessValidation(!claims?.[ClaimsList.liveness]);
    }
  }, [claims]);

  useEffect(() => {
    if (logged && !fetchingTrackingToken.current) {
      const params = new URLSearchParams();
      params.append('grant_type', TokenGrantType.ClientCredentials);
      fetchingTrackingToken.current = true;
      axios({
        method: RequestMethods.post,
        url: `${trackingAuthUrl}`,
        data: params,
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization:
            'Basic ' +
            Buffer.from(`${clientId}:${clientSecret}`).toString('base64'),
        },
        withCredentials: true,
      })
        .then((response) => {
          fetchingTrackingToken.current = false;
          localStorage.setItem(
            Storage.trackingToken,
            response.data.access_token
          );
        })
        .catch((e) => {
          if (!process.env.REACT_APP_TEST) {
            fetchingTrackingToken.current = false;
            captureMessage(
              'Error when trying to retrieve tracking token from SDK'
            );
            captureException(e);
            setInitError(true);
          }
        });
    }

    return () => setInitError(false);
  }, [logged]);

  return (
    <Ctx.Provider
      value={{
        logged,
        logout,
        handleLogin,
        terms,
        loadingTerms,
        termsText,
        error,
        handleRefreshToken,
        token,
        tokenValid,
        email: '' + claims?.[ClaimsList.email],
        acceptTerms,
        refreshToken,
        canDisableLiveness: !!claims?.[ClaimsList.liveness],
        hasVideoAssistance: !!claims?.[ClaimsList.videoAssistance],
        hasAntifraud: !!claims?.[ClaimsList.antifraud],
        hasPad: !!claims?.[ClaimsList.pad],
        tenantDefault,
        demoVersion,
        initError,
        checkTermsAccepted,
      }}
    >
      {children}
    </Ctx.Provider>
  );
};

export const useAuth = () => useContext(Ctx);
