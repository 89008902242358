import { DeviceSize } from '../states/constants';

export const desktopStyles = () => {
  return `@media (min-width: ${DeviceSize.desktop}px)`;
};

export const mobileStyles = () => {
  return `@media (max-width: ${DeviceSize.mobile}px)`;
};

export const HEADER_HEIGHT = '7rem';
