import {
  CardBase,
  EmptyState,
  FlexContainer,
  GridContainer,
  Label,
  setColorTheme,
  Spinner,
} from '@facephi/ui-react';
import styled from 'styled-components/macro';
import { desktopStyles, mobileStyles } from '../../styles';
import { setStatusStyles } from '../results/Styles';

export const CardGridStyles = styled(FlexContainer).attrs({
  flexDirection: 'column',
  rowGap: '1.6',
})<{
  $gridColumn?: string;
  $gridRow?: string;
  $alignSelf?: string;
  order?: string;
}>`
  border-radius: 1.2rem;
  background: ${setColorTheme('white')};
  box-shadow: 0 5px 10px 0 ${setColorTheme('grey100')};
  position: relative;
  overflow: hidden;
  order: ${({ order }) => order};

  ${desktopStyles} {
    justify-content: space-between;
    grid-column: ${({ $gridColumn }) => $gridColumn};
    grid-row: ${({ $gridRow }) => $gridRow};
    align-self: ${({ $alignSelf }) => $alignSelf};
  }
`;

export const CardGridInfoBar = styled(FlexContainer)<{
  alignItems?: string;
  background?: string;
  $type?: string;
}>`
  align-items: ${({ alignItems }) => alignItems || 'center'};
  background-color: ${({ background }) => background || setColorTheme('white')};
  padding: 0.4rem 1.6rem;
  width: 100%;
  color: ${setColorTheme('white')};
  margin-top: 1.6rem;

  a {
    text-decoration: underline;
  }

  ${({ $type }) => setStatusStyles($type)}
`;

export const EmptyStateContent = styled(EmptyState)<{ $error?: boolean }>`
  div {
    margin-top: unset;
  }
  button {
    margin-bottom: 3rem;
  }

  ${({ $error }) =>
    !$error &&
    `
    > div {
      row-gap: 1.6rem;
    }
    
    p {
      font-size: 1.8rem;
    }
  `}
  ${mobileStyles} {
    svg {
      width: 85%;
      height: auto;
    }
  }
`;

export const CardListHeader = styled(FlexContainer)`
  min-height: 8.4rem;
  padding: 1.6rem;

  p {
    color: ${setColorTheme('grey500')};
  }
`;

export const CardListElement = styled(FlexContainer)<{ $isRow?: boolean }>`
  padding: 1.2rem 1.6rem;
  border-top: 1px solid ${setColorTheme('grey100')};

  p {
    word-break: break-word;
    &:first-of-type {
      color: ${setColorTheme('grey500')};
      ${({ $isRow }) => $isRow && `word-break: unset;`}
    }
  }
`;

export const CardResultsStyles = styled(FlexContainer).attrs({
  flexDirection: 'column',
  rowGap: '1.6',
})<{
  status?: string;
}>`
  border-radius: 1.2rem;
  background: ${setColorTheme('white')};
  box-shadow: 0 5px 10px 0 ${setColorTheme('grey100')};
  padding: 1.6rem;
  position: relative;
  overflow: hidden;

  ${({ status }) => setStatusStyles(status)}
`;

export const CardResultsHeaderTitle = styled(Label).attrs({
  fontSize: '14',
  semibold: true,
})`
  color: ${setColorTheme('grey500')};
  text-transform: uppercase;
`;

export const CardResultsHeaderSpinner = styled(Spinner)`
  max-width: 2.8rem;

  > * {
    position: relative;
    width: auto;
  }
`;

export const CardResultsContent = styled(FlexContainer)`
  height: 100%;
`;

export const CardListStyles = styled(CardBase)<{ fitContent?: boolean }>`
  ${({ fitContent }) => fitContent && `height: fit-content`};
  flex: 1;
`;

export const CardListList = styled(GridContainer)<{
  $columnsPerRow?: number | string;
}>`
  grid-template-columns: repeat(1, 1fr);

  ${desktopStyles} {
    grid-template-columns: repeat(
      ${({ $columnsPerRow }) => $columnsPerRow},
      1fr
    );
  }
`;

export const CardResultsSimple = styled(CardBase)`
  border-radius: 1.2rem;
  padding: 6rem 2.4rem 1.6rem;

  ${mobileStyles} {
    padding: 1.6rem 2.4rem 3rem;

    ${CardResultsHeaderTitle} {
      margin-right: auto;
      margin-bottom: 1.6rem;
    }
  }
`;
export const CardGridSmallHeader = styled(FlexContainer)`
  padding: 1.6rem;
  padding-bottom: 0;
`;

export const CardGridSmallStatus = styled.div`
  position: relative;

  .status-icon {
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
    height: auto;
  }
`;

export const CardListContentWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  p {
    color: ${setColorTheme('grey700')};
  }
`;
