import { Layout } from '../components';
import { ResultDetailWrapper, Verification } from '../components/results';

const ResultsVerification = () => {
  return (
    <Layout
      padding
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
    >
      <ResultDetailWrapper flexDirection="column">
        <Verification />
      </ResultDetailWrapper>
    </Layout>
  );
};

export default ResultsVerification;
