import { Dispatch, useEffect, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FaceCapture, IFPhiExtractionFinishEvent } from '@facephi/sdk-web';
import { useDeviceSize } from '@facephi/ui-react';
import AgoraRTC from 'facephi-agora-rtc-react';
import { useTranslation } from 'react-i18next';
import { useOperation } from '../../providers';
import { ActionRequest } from '../../reducers';
import { SELPHI_SDK, StatusOperations } from '../../states';
import { StatusTypeProps } from '../results';
import { ValidatingIdentity } from '../validating';
import { ValidStatus } from '../validStatus';
import { SlefieSignHeader } from './SelfieSignHeader';
import {
  DeniedActions,
  DeniedButton,
  SelfieSignResult,
  SelfieSignWrapper,
} from './Styles';
import { TimeoutStatus } from './TimeoutStatus';

type Props = {
  onCancel(state: boolean, documentSigned?: StatusTypeProps): void;
  onSign(bestImage: string): Promise<void>;
  onClose(): void;
  showSignProcess: boolean;
  signatureAttemps: number;
  showSelphi: boolean;
  setShowSelphi: Dispatch<boolean>;
};

const SelfieSign = ({
  onCancel,
  showSignProcess,
  onClose,
  onSign,
  signatureAttemps,
  showSelphi,
  setShowSelphi,
}: Props) => {
  const { i18n, t } = useTranslation();
  const { documentSignature, dispatchDocumentSignature } = useOperation();

  const [showHeader, setShowHeader] = useState<boolean>(false);
  const { isMobile } = useDeviceSize();

  const targetNode = document.querySelector('div[class*="SelfieSignWrapper"]');
  useEffect(() => {
    const config = { attributes: false, childList: true, subtree: true };
    const callback = (mutationList: MutationRecord[]) => {
      for (const mutation of mutationList) {
        setShowHeader(
          mutation.addedNodes[0]?.nodeName === SELPHI_SDK ||
            mutation.nextSibling?.nodeName === SELPHI_SDK
        );
      }
    };

    const observer = new MutationObserver(callback);

    targetNode && observer.observe(targetNode, config);

    return () => {
      setShowHeader(false);
      observer.disconnect();
    };
  }, [targetNode]);

  useEffect(() => {
    if (!documentSignature.error) {
      configTrack();
    } else {
      setShowSelphi(false);
    }
  }, [documentSignature.error]);

  useEffect(() => {
    setShowSelphi(showSignProcess);
  }, [showSignProcess]);

  const configTrack = async () => {
    try {
      const track = await AgoraRTC.createCameraVideoTrack({
        facingMode: 'user',
        encoderConfig: '720p_6',
      });
      track.stop();
    } finally {
      setShowSelphi(true);
    }
  };

  const onExtractionTimeout = () => {
    dispatchDocumentSignature({
      type: ActionRequest.SET_DATA,
      payload: { signed: null },
    });
    setShowSelphi(false);
  };

  const onUserCancel = () => {
    dispatchDocumentSignature({
      type: ActionRequest.SET_ERROR,
    });
  };

  const onUserRetry = () => {
    dispatchDocumentSignature({
      type: ActionRequest.RESET,
    });
    setShowSelphi(true);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onExtractionFinish = async (event: any) => {
    onSign(event.detail.bestImage.src);
  };

  const showResults =
    !documentSignature.loading &&
    !showSelphi &&
    (documentSignature.status === StatusOperations.DENIED ||
      documentSignature.status === StatusOperations.SUCCEEDED ||
      documentSignature.status === StatusOperations.CONNECTION_ERROR);

  const validStatusMessage =
    documentSignature.status === StatusOperations.CONNECTION_ERROR ||
    documentSignature.status === StatusOperations.DENIED
      ? 'The document could not be signed'
      : 'You have signed the document correctly';

  const isTimeout =
    documentSignature.status === StatusOperations.DENIED &&
    documentSignature.data?.signed === null;

  return (
    <SelfieSignWrapper
      flexDirection="column"
      alignItems="center"
      justifyContent={isMobile ? 'space-between' : 'center'}
    >
      {showResults ? (
        <>
          <SlefieSignHeader
            facephiLogo={!isMobile}
            readyToShow={true}
            onClose={onClose}
          />
          <SelfieSignResult flexDirection="column" alignItems="center">
            {isTimeout ? (
              <TimeoutStatus />
            ) : (
              <ValidStatus
                state={
                  documentSignature.status ===
                    StatusOperations.CONNECTION_ERROR ||
                  documentSignature.status === StatusOperations.DENIED
                    ? 'denied'
                    : 'success'
                }
                message={t(validStatusMessage) || undefined}
              />
            )}
            {(documentSignature.status === StatusOperations.DENIED ||
              documentSignature.status === StatusOperations.CONNECTION_ERROR) &&
              signatureAttemps < 3 && (
                <DeniedActions justifyContent="center" isTimeout={isTimeout}>
                  {!isTimeout && (
                    <DeniedButton
                      variant="outline"
                      onClick={() => onCancel(false, documentSignature.status)}
                    >
                      {t('Cancel')}
                    </DeniedButton>
                  )}
                  <DeniedButton
                    onClick={onUserRetry}
                    isTimeout={isTimeout}
                    testId="retry"
                  >
                    {t('Try again')}
                  </DeniedButton>
                </DeniedActions>
              )}
          </SelfieSignResult>
        </>
      ) : documentSignature.loading ? (
        <ValidatingIdentity />
      ) : showSelphi ? (
        <>
          <SlefieSignHeader
            infoIcon
            facephiLogo
            readyToShow={showHeader}
            onClose={onClose}
          />
          <FaceCapture
            bundlePath={`${process.env.PUBLIC_URL}/widgets/selphi`}
            className="sdk-widget selphi-widget"
            language={i18n.language.split('-')[0]}
            tutorial={false}
            interactible={false}
            cropFactor={1.7}
            onExtractionFinish={onExtractionFinish}
            onExtractionTimeout={onExtractionTimeout}
            onUserCancel={onUserCancel}
            onLivenessErrorButtonClick={onExtractionTimeout}
            onTimeoutErrorButtonClick={onExtractionTimeout}
            disableApiCallback
          />
        </>
      ) : null}
    </SelfieSignWrapper>
  );
};

export default SelfieSign;
