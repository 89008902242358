import {
  FlexContainer,
  OptionMenu,
  OptionMenuItem,
  setColorTheme,
  setSizeLabel,
} from '@facephi/ui-react';
import styled from 'styled-components/macro';
import { HEADER_HEIGHT, mobileStyles } from '../../styles';

export const HeaderStyles = styled(FlexContainer)`
  min-height: ${HEADER_HEIGHT};
  height: ${HEADER_HEIGHT};
  background-color: ${setColorTheme('grey900')};
  width: 100%;
  padding: 0 1.6rem;
`;

export const ButtonLanguage = styled(FlexContainer)<{ $isOpen: boolean }>`
  border-radius: ${({ $isOpen }) => ($isOpen ? '0.8rem 0.8rem 0 0' : '0.8rem')};
  min-height: 4.8rem;
  padding: 1.5rem;
  background-color: ${setColorTheme('grey900')};
  ${setSizeLabel('14')};
  text-transform: uppercase;
  color: ${setColorTheme('white')};

  span {
    min-width: 1.8rem;
  }
`;

export const OptionMenuItemHeader = styled(OptionMenuItem)<{
  semibold?: boolean;
}>`
  height: 4.4rem;
  background-color: ${setColorTheme('grey900')};
  ${setSizeLabel('14')};
  ${({ semibold }) => semibold && 'font-weight: 600'};

  :nth-child(n) {
    border-top: 1px solid ${setColorTheme('grey700')};
  }

  :hover {
    background-color: ${setColorTheme('grey900')};
    text-shadow: unset;
  }

  > svg {
    min-width: 2.4rem;
  }
`;

export const OptionMenuHeader = styled(OptionMenu)<{
  language?: boolean;
  $isOpen?: boolean;
  isMobile?: boolean;
}>`
  position: absolute;
  right: 0;
  border: none;
  border-radius: ${({ language, $isOpen, isMobile }) =>
    language && $isOpen && !isMobile ? ' 0 0 0.8rem 0.8rem' : '0.8rem'};
  box-shadow: none;
  background-color: ${setColorTheme('grey900')};

  ${OptionMenuItemHeader} {
    text-transform: ${({ language }) => (language ? 'uppercase' : 'inherit')};
  }

  span {
    min-width: 1.8rem;
  }
`;

export const LanguageMenuWrapper = styled(FlexContainer)<{
  $asLayout?: boolean;
}>`
  padding: 2.4rem;
  background-color: ${setColorTheme('grey50')};
  column-gap: 2rem;

  ${mobileStyles} {
    padding: 0.8rem;
    background-color: ${({ $asLayout }) =>
      setColorTheme($asLayout ? 'grey50' : 'white')};
    > p {
      margin-left: 0.8rem;
    }
  }
`;
