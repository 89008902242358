import { useMemo } from 'react';
import { Label, useDeviceSize } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SimpleResultsLottieData from '../../assets/json/SimpleResults.json';
import { useOperation } from '../../providers';
import {
  Operation,
  RoutesName,
  StatusTypeList,
  StatusOperations,
} from '../../states';
import {
  CardResults,
  CardResultsContent,
  CardResultsHeaderTitle,
  CardResultsSimple,
} from '../cards';
import { Banner } from './Banner';
import { onboardingIcon } from './Results';
import { ResultsButton, ResultsWrapper, SimpleResultsLottie } from './Styles';

export const SimpleResults = () => {
  const { t } = useTranslation();
  const { isMobile } = useDeviceSize();
  const navigate = useNavigate();
  const { operation: operationData, pending } = useOperation();

  const operation: Operation = useMemo(
    () =>
      operationData
        ? { ...operationData.data }
        : { assets: [], currentStep: '' },
    [operationData]
  );

  return (
    <ResultsWrapper flexDirection="column" rowGap="1.6">
      <Label fontSize={isMobile ? '12' : '32'} semibold textAlign="center">
        {t('Results')}
      </Label>

      <Banner margin={false} />

      <CardResults
        title={t('ONBOARDING RESULT') as string}
        status={operation.status}
        icon={onboardingIcon(
          pending ? StatusTypeList.IN_PROGRESS : operation.status
        )}
      >
        <CardResultsContent
          flexDirection="column"
          alignItems={isMobile ? 'start' : 'center'}
          rowGap="0.4"
        >
          <Label fontSize="21" semibold upperCase>
            {t(
              !pending && operation.status
                ? operation.status
                : 'Onboarding in progress'
            )}
          </Label>

          <Label fontSize="12">
            {t(
              !pending
                ? `${operation.status}-description`
                : 'We are analyzing the information'
            )}
          </Label>
        </CardResultsContent>
      </CardResults>

      {!pending && !operationData.error && (
        <CardResultsSimple
          flexDirection="column"
          alignItems="center"
          rowGap="0.8"
        >
          {isMobile && (
            <CardResultsHeaderTitle textAlign="left">
              {t('ADVANCED DETAILS')}
            </CardResultsHeaderTitle>
          )}
          {/* TODO: CHANGE LOTTIE JSON */}
          <SimpleResultsLottie
            loop={true}
            animation={SimpleResultsLottieData}
            preserveAspectRatio="xMidYMid meet"
          />
          {/* TODO: CHANGE LOTTIE JSON */}
          <Label fontSize={isMobile ? '16' : '21'} semibold textAlign="center">
            {operation.status === StatusOperations.SUCCEEDED &&
              t('Consult the details of the Onboarding in our platform')}
            {operation.status === StatusOperations.DENIED &&
              t('Check the reason on our platform')}
          </Label>

          <Label fontSize="14" textAlign="center">
            {t('Use the same login as in Onboarding.')}
          </Label>
        </CardResultsSimple>
      )}

      <ResultsButton
        size="L"
        onClick={() => {
          navigate(RoutesName.home);
        }}
        variant="filled"
      >
        {t('Finish')}
      </ResultsButton>
    </ResultsWrapper>
  );
};
