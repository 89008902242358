export const BrazilFlag = () => {
  return (
    <svg
      width="26"
      height="18"
      viewBox="0 0 26 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="26" height="18" rx="2" fill="#05AB41" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 8.99921L13.2501 15.5668L24.5 8.99921L13.2501 2.43164L2 8.99921Z"
        fill="#FDD216"
      />
      <ellipse cx="13.25" cy="9.00037" rx="4.25" ry="4.13513" fill="#053087" />
      <mask
        id="mask0_1219_56538"
        maskUnits="userSpaceOnUse"
        x="9"
        y="4"
        width="9"
        height="10"
      >
        <ellipse cx="13.25" cy="9.00037" rx="4.25" ry="4.13513" fill="white" />
      </mask>
      <g mask="url(#mask0_1219_56538)">
        <path
          d="M5.26026 7.32656C14.5086 8.5774 23.2674 11.3553 23.2529 13.4182"
          stroke="white"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};
