export type GovernmentPersonalInfo = {
  fullName?: string;
  documentNumber?: string | number;
  verificationDigit?: string | number;
  code?: string | number;
  description?: string;
  cpfAvailable?: string;
  specimen?: string;
  mainTransactionId?: string;
  reprintedCardTransactionId?: string;
  registrationDate?: string;
  deliveryDate?: string;
  expiryDate?: string;
  maritalStatus?: string;
  sex?: string;
  marriedSurname?: string;
  dateOfBirth?: string;
  placeOfBirth?: string;
  address?: string;
  number?: string;
  monoblock?: string;
  floor?: string;
  apartment?: string;
  neighbourhood?: string;
  zipCode?: string;
  phase?: string;
  urbanisation?: string;
  buildingBlock?: string;
  indoor?: string;
  lot?: string;
  block?: string;
  district?: string;
  city?: string;
  municipality?: string;
  province?: string;
  department?: string;
  country?: string;
  continent?: string;
  nationality?: string;
  cuil?: string | number;
  cui?: string | number;
  deathStatus?: string | number;
  deathDate?: string;
  message?: string;
  continentUbigeo?: string | number;
  birthContinentUbigeo?: string | number;
  countryUbigeo?: string | number;
  birthCountryUbigeo?: string | number;
};
export const governmentValitionKeys: { [key in string]: string[] } = {
  fullName: [
    'nome',
    'nombres',
    'apellido',
    'apellidoPaterno',
    'apellidoMaterno',
  ],
  documentNumber: ['ni', 'id_ciudadano', 'dni'],
  verificationDigit: ['dver'],
  code: ['codigo'],
  description: ['descricao'],
  cpfAvailable: ['cpf_disponivel'],
  specimen: ['ejemplar'],
  mainTransactionId: ['id_tramite_principal'],
  reprintedCardTransactionId: ['id_tramite_tarjeta_reimpresa'],
  registrationDate: ['fechaInscripcion'],
  deliveryDate: ['emision', 'fechaExpedicion'],
  expiryDate: ['vencimiento', 'fechaCaducidad'],
  maritalStatus: ['civilStatus'],
  sex: ['sexo'],
  marriedSurname: ['apellidoCasada'],
  dateOfBirth: ['nascimento', 'fecha_nacimiento', 'fechaNacimiento'],
  placeOfBirth: [
    'distritoNacimiento',
    'provinciaNacimiento',
    'departamentoNacimiento',
    'paisNacimiento',
  ],
  address: ['calle', 'direccion'],
  number: ['numero'],
  monoblock: ['monoblock'],
  floor: ['piso'],
  apartment: ['departamento'],
  neighbourhood: ['barrio'],
  zipCode: ['codigo_postal'],
  phase: ['etapaDireccion'],
  urbanisation: ['urbDireccion'],
  buildingBlock: ['blkDireccion'],
  indoor: ['intDireccion'],
  lot: ['lotDireccion'],
  block: ['mznDireccion'],
  district: ['distDomicilio'],
  city: ['ciudad'],
  municipality: ['municipio'],
  province: ['provincia', 'provDomicilio'],
  department: ['dptoDomicilio'],
  country: ['pais', 'addrCountry'],
  continent: ['addrContinent'],
  nationality: ['nacionalidad'],
  cuil: ['cuil'],
  cui: ['cui'],
  deathStatus: ['mensaje_fallecido'],
  deathDate: ['fecha_fallecimiento'],
  message: ['mensaje'],
  continentUbigeo: ['ubigeoContinent'],
  birthContinentUbigeo: ['ubigeoContinentNacimiento'],
  countryUbigeo: ['ubigeoCountry'],
  birthCountryUbigeo: ['ubigeoCountryNacimiento'],
};
export type GovernmentValidationDto = {
  succeed: boolean;
  summary: {
    facialMatching?: boolean;
    digitalMatching?: boolean;
  };
  data: GovernmentPersonalInfo;
  image?: string;
  details: object;
  source: GovernmentServices;
  mode: string;
};
export const datesKeys = [
  'dateOfBirth',
  'deathDate',
  'registrationDate',
  'deliveryDate',
  'expiryDate',
];
export enum GovernmentServices {
  serpro = 'Serpro',
  renaper = 'Renaper',
  reniec = 'Reniec',
}
export const fullNameKey = 'fullName';

export enum VerificationResults {
  verificationResult = 'Verification result',
  VALIDATION_CONNECTION_ERROR = 'VALIDATION_CONNECTION_ERROR',
  VALIDATION_PROVEN_CONTENT = 'VALIDATION_PROVEN_CONTENT',
  VALIDATION_NOT_PROVEN_CONTENT = 'VALIDATION_NOT_PROVEN_CONTENT'
};
