export const SpainFlag = () => {
  return (
    <svg
      width="26"
      height="18"
      viewBox="0 0 26 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="26" height="18" rx="2" fill="white" />
      <mask
        id="mask0_1219_56502"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="26"
        height="18"
      >
        <path
          d="M0 2C0 0.89543 0.895431 0 2 0H24C25.1046 0 26 0.895431 26 2V18H0V2Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1219_56502)">
        <path
          d="M0 2C0 0.895429 0.895431 0 2 0H24C25.1046 0 26 0.895431 26 2V4.8H0V2Z"
          fill="#DD172C"
        />
      </g>
      <mask
        id="mask1_1219_56502"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="26"
        height="18"
      >
        <path
          d="M0 0H26V16C26 17.1046 25.1046 18 24 18H2C0.89543 18 0 17.1046 0 16V0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_1219_56502)">
        <path
          d="M0 13.1992H26V15.9992C26 17.1038 25.1046 17.9992 24 17.9992H2C0.89543 17.9992 0 17.1038 0 15.9992V13.1992Z"
          fill="#DD172C"
        />
      </g>
      <mask
        id="mask2_1219_56502"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="26"
        height="18"
      >
        <rect width="26" height="18" rx="3.51" fill="white" />
      </mask>
      <g mask="url(#mask2_1219_56502)">
        <rect y="4.80078" width="26" height="8.4" fill="#FFD133" />
      </g>
      <mask
        id="mask3_1219_56502"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="26"
        height="18"
      >
        <rect width="26" height="18" rx="3.51" fill="white" />
      </mask>
      <g mask="url(#mask3_1219_56502)">
        <path
          d="M6.80859 8.40039H8.04669V9.00039H6.80859V8.40039Z"
          fill="#FFEDB1"
        />
      </g>
      <mask
        id="mask4_1219_56502"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="26"
        height="18"
      >
        <rect width="26" height="18" rx="3.51" fill="white" />
      </mask>
      <g mask="url(#mask4_1219_56502)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.73858 8.09961C7.81634 8.09961 7.88536 8.12846 7.93255 8.17824C7.9801 8.22839 8.00406 8.29801 7.99759 8.3732V8.3732L7.84259 10.1759C7.82142 10.4222 7.70088 10.645 7.52453 10.8103C7.34865 10.9751 7.11614 11.0839 6.86535 11.098C6.57793 11.0969 6.31819 10.9954 6.1237 10.8297C5.92877 10.6636 5.79926 10.4328 5.77718 10.1759V10.1759L5.62218 8.3732C5.61567 8.29743 5.63862 8.22747 5.68616 8.17733C5.73337 8.12754 5.80285 8.09961 5.8812 8.09961V8.09961H7.73858Z"
          stroke="#A41517"
          strokeWidth="1.17"
        />
      </g>
      <mask
        id="mask5_1219_56502"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="26"
        height="18"
      >
        <rect width="26" height="18" rx="3.51" fill="white" />
      </mask>
      <g mask="url(#mask5_1219_56502)">
        <path
          d="M5.57227 9H8.04846V9.6H7.42941L6.81036 10.8L6.19131 9.6H5.57227V9Z"
          fill="#A41517"
        />
      </g>
      <mask
        id="mask6_1219_56502"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="26"
        height="18"
      >
        <rect width="26" height="18" rx="3.51" fill="white" />
      </mask>
      <g mask="url(#mask6_1219_56502)">
        <rect
          x="3.71484"
          y="7.19922"
          width="1.2381"
          height="4.2"
          fill="#A41517"
        />
      </g>
      <mask
        id="mask7_1219_56502"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="26"
        height="18"
      >
        <rect width="26" height="18" rx="3.51" fill="white" />
      </mask>
      <g mask="url(#mask7_1219_56502)">
        <rect
          x="8.66602"
          y="7.19922"
          width="1.2381"
          height="4.2"
          fill="#A41517"
        />
      </g>
      <mask
        id="mask8_1219_56502"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="26"
        height="18"
      >
        <rect width="26" height="18" rx="2" fill="white" />
      </mask>
      <g mask="url(#mask8_1219_56502)">
        <path
          d="M5.57227 6.6C5.57227 6.26863 5.84672 6 6.19073 6H7.43C7.77156 6 8.04846 6.26632 8.04846 6.6V6.89719C8.04846 7.06443 7.90823 7.2 7.73985 7.2H5.88087C5.71043 7.2 5.57227 7.06699 5.57227 6.89719V6.6Z"
          fill="#A41517"
        />
      </g>
    </svg>
  );
};
