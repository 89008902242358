import { Layout } from '../components';
import { OCR, ResultDetailWrapper } from '../components/results';

const ResultsOCR = () => {
  return (
    <Layout
      padding
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
    >
      <ResultDetailWrapper flexDirection="column">
        <OCR />
      </ResultDetailWrapper>
    </Layout>
  );
};

export default ResultsOCR;
