import { ReactNode } from 'react';
import {
  FlexContainer,
  Icon,
  IconName,
  useDeviceSize,
} from '@facephi/ui-react';

type Props = {
  children: ReactNode;
  iconName: IconName;
  testName?: string;
};

export const ServiceSection = ({ children, iconName, testName }: Props) => {
  const { isMobile } = useDeviceSize();

  return (
    <FlexContainer
      columnGap={isMobile ? '2.4' : '3.2'}
      as="section"
      justifyContent="space-between"
    >
      <Icon
        iconName={iconName}
        size="72"
        weight="thin"
        color="grey600"
        testId={testName}
        className={testName}
      />
      <FlexContainer flex="1" flexDirection="column" rowGap="0.8">
        {children}
      </FlexContainer>
    </FlexContainer>
  );
};
