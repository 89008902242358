/* eslint-disable @typescript-eslint/no-explicit-any */
import { AssetsType, useTracking, TrackingSteps } from '@facephi/sdk-web';
import { useRecoilValue } from 'recoil';
import { v4 } from 'uuid';
import {
  RequestMethods,
  useFacialValidation,
  useRequest,
  useVariables,
  useLogger,
} from '../hooks';
import { useOperation } from '../providers';
import {
  endPoints,
  ResponseFacialAuthentication,
  ResponseLiveness,
} from '../states';
import { livenessValidationState } from '../states/atoms';

type OcrParameters = {
  back?: string;
  front: string;
  country: string;
  operationId: string;
  demoVersion: string;
};

export const useWidgetOperations = () => {
  const { apiDemoUrl } = useVariables();
  const { requestBasic: demoRequest } = useRequest(apiDemoUrl);
  const {
    setFacialCapture,
    setMatchingResult,
    setLivenessResult,
    setAntispoofResult,
  } = useOperation();
  const { trackingAsset } = useTracking();
  const { onValidateFacial, onValidateLiveness, onValidateSpoof } =
    useFacialValidation();
  const { captureException, captureMessage } = useLogger();
  const livenessValidation = useRecoilValue(livenessValidationState);

  const makeOcr = async ({
    back,
    front,
    country,
    operationId,
    demoVersion,
  }: OcrParameters) => {
    try {
      const responseOcr: any = await demoRequest(endPoints.Onboardings.Ocr, {
        method: RequestMethods.post,
        headers: {
          'x-demo-app-version': demoVersion,
          'x-inphinite-operationid': operationId,
        },
        data: {
          front,
          back,
          country,
          decompose: true,
        },
      });

      try {
        trackingAsset(
          `data:image/png;base64,${responseOcr?.DECOMPOSED?.FACE}`,
          AssetsType.documentFacialCapture,
          v4(),
          TrackingSteps.selphidWidget
        );
      } catch (e: any) {
        captureMessage(`Error in POST to ${endPoints.Onboardings.Ocr}`);
        captureException(e, { operation: 'trackingAsset after OCR' });
      }
      setFacialCapture(responseOcr?.DECOMPOSED?.FACE);
      return responseOcr;
    } catch (e: any) {
      captureException(e, {
        operation: 'makeOcr',
        url: endPoints.Onboardings.Ocr,
        operationId: operationId,
        country,
      });
      throw new Error(e);
    }
  };

  const makeAuthentication = async (
    facial: string,
    selfie: string,
    operation: string,
    session: string
  ) => {
    try {
      const verifications: [
        Promise<ResponseFacialAuthentication>,
        Promise<ResponseLiveness>?
      ] = [onValidateFacial(facial, selfie, operation, session)];
      if (livenessValidation)
        verifications.push(onValidateLiveness(selfie, operation, session));

      const [responseFacial, responseLiveness] = await Promise.all(
        verifications
      );

      setMatchingResult(responseFacial);
      setLivenessResult(responseLiveness);
      return true;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      captureException(error, { operation: 'makeAuthentication' });
      console.log('Error: ', error.message);
      throw new Error(error);
    }
  };

  const makeAntispoofing = async (
    encryptedLivenessRaw: Blob | File,
    operation: string,
    session: string
  ) => {
    try {
      const responseAntispoof = await onValidateSpoof(
        encryptedLivenessRaw,
        operation,
        session
      );

      setAntispoofResult(responseAntispoof);
    } catch (error: any) {
      captureException(error, { operation: 'makeAntispoofing' });
      throw new Error(error);
    }
  };

  return { makeAuthentication, makeOcr, makeAntispoofing };
};
