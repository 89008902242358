import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { VideoContracting, TrackingSignatureValue, useTracking } from '@facephi/sdk-web';
import { Spinner, useToast } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useParams } from 'react-router-dom';
import { useVariables, useFacialValidation } from '../../hooks';
import { useOperation } from '../../providers';
import { ActionRequest } from '../../reducers';
import { ISignType, StatusOperations } from '../../states';
import { StatusTypeProps } from '../results';
import SelfieSign from './SelfieSign';
import {
  ModalSignActions,
  ModalSignContent,
  ModalSignButton,
  ModalSignStyles,
} from './Styles';

type Props = {
  show?: boolean;
  onChangeShow(show: boolean): void;
  className?: string;
  onCancel(): void;
  signType: string;
  signatureAttemps: number;
  setSignatureAttemps: Dispatch<SetStateAction<number>>;
};

export const ModalSign = ({
  show,
  onChangeShow,
  onCancel,
  signatureAttemps,
  setSignatureAttemps,
  signType,
}: Props) => {
  const { operationId } = useParams();
  const { t, i18n } = useTranslation();
  const { cloudApi, videoApiKey } = useVariables();
  const { dispatchDocumentSignature, documentSignature, selfie } =
    useOperation();
  const { onSignDocument, onValidateTransfer } = useFacialValidation();
  const { toastManager } = useToast();

  const [numPages, setNumPages] = useState(1);
  const [bottom, setBottom] = useState<boolean>(false);
  const [showSignProcess, setShowSignProcess] = useState<boolean>(false);
  const [signatureResult, setSignatureResult] = useState<boolean>(false);
  const [showSelphi, setShowSelphi] = useState<boolean>(false);
  const { changeOperationId } = useTracking();

  let timeout: NodeJS.Timeout;

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    if (
      Math.round(
        event.currentTarget.scrollHeight - event.currentTarget.scrollTop
      ) -
        100 <=
      event.currentTarget.clientHeight
    )
      setBottom(true);
  };

  const signWithVideo = async (recordResult?: boolean | object) => {
    dispatchDocumentSignature({
      type: ActionRequest.START_REQUEST,
    });
    if (typeof recordResult === 'boolean' && recordResult) {
      setSignatureResult(recordResult);
      onCancel();
    };
    
    if(typeof recordResult === 'object') {
      setSignatureAttemps(signatureAttemps +1);
      dispatchDocumentSignature({
        type: ActionRequest.RESET
      });
    }

    if(!recordResult) {
       dispatchDocumentSignature({
        type: ActionRequest.RESET
      });
    }
    
  };

  const closeModalWithError = (millisecs: number) => {
    timeout = setTimeout(() => {
      onCancel();
      toastManager({
        type: 'error',
        message: t('You have exceeded 3 attempts'),
        subMessage: t('Finish the process and try again') as string,
        iconName: 'HandPalm',
        canClose: true,
      });
    }, millisecs);
  };

  const signWithSelfie = async (bestImage: string): Promise<void> => {
    dispatchDocumentSignature({
      type: ActionRequest.START_REQUEST,
    });
    try {
      const signatureResponse = await onValidateTransfer(bestImage, selfie);
      if (signatureResponse) {
        setSignatureResult(signatureResponse);
      } else {
        dispatchDocumentSignature({
          type: ActionRequest.SET_DATA,
          payload: { signed: signatureResponse },
        });
        setSignatureAttemps(signatureAttemps + 1);
      }
    } catch (error) {
      dispatchDocumentSignature({
        type: ActionRequest.SET_ERROR,
      });
    } finally {
      setShowSelphi(false);
    }
  };

  const handleShowSignProcess = (
    state: boolean,
    signatureStatus?: StatusTypeProps
  ) => {
    if (signatureStatus === StatusOperations.STARTED && !documentSignature.loading ||
    signatureStatus === StatusOperations.DENIED &&
    signatureAttemps !== 3) {
      dispatchDocumentSignature({ type: ActionRequest.RESET });
    }
    setShowSignProcess(state);
    setBottom(state);
  };

  useEffect(() => {
    changeOperationId(operationId as string);
  }, []);

  useEffect(() => {
    if (!show) {
      handleShowSignProcess(false, documentSignature.status);
    }
  }, [show]);

  useEffect(() => {
    const milisecs = signType === ISignType.selfie ? 2000 : 0;
    if (signatureAttemps === 3 || signatureResult) {
      !milisecs && onCancel();
      dispatchDocumentSignature({type: ActionRequest.START_REQUEST});

      const result: TrackingSignatureValue = signatureResult ?
        TrackingSignatureValue.succeeded :
        TrackingSignatureValue.error;
      onSignDocument(result)
        .then(() => {
          dispatchDocumentSignature({
            type: ActionRequest.SET_DATA,
            payload: { signed: signatureResult },
          })}
        )
        .catch(() => {
          dispatchDocumentSignature({
            type: ActionRequest.SET_ERROR,
          });
        }).finally(() =>  {
          setShowSelphi(false)
          !signatureResult && closeModalWithError(milisecs);
        });
    }
    return () => clearTimeout(timeout);
  }, [signatureAttemps, signatureResult]);

  return (
    <>
      {showSignProcess && signType === ISignType.videoRecording && (
        <VideoContracting
          apiUrl={`wss://${cloudApi}identification`}
          fullScreen
          apiKey={videoApiKey || ''}
          operationId={operationId}
          language={i18n.language}
          onClose={(sign) => {
            setShowSignProcess(false);
            signWithVideo(sign);
          }}
        />
      )}
      <ModalSignStyles show={show} onChangeShow={onChangeShow}>
        {showSignProcess && signType === ISignType.selfie ? (
          <SelfieSign
            onCancel={handleShowSignProcess}
            onSign={signWithSelfie}
            showSignProcess={showSignProcess}
            onClose={onCancel}
            signatureAttemps={signatureAttemps}
            showSelphi={showSelphi}
            setShowSelphi={setShowSelphi}
          />
        ) : (
          <>
            <ModalSignContent onScroll={handleScroll} data-test="sign-pdf">
              <Document
                file={t('sign.pdf')}
                onLoadSuccess={onDocumentLoadSuccess}
                loading={<Spinner />}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    width={900}
                    pageNumber={index + 1}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                  />
                ))}
              </Document>
            </ModalSignContent>

            <ModalSignActions
              rowGap="1"
              columnGap="22"
              justifyContent="space-between"
            >
              <ModalSignButton variant="outline" onClick={onCancel}>
                {t('Go back')}
              </ModalSignButton>

              <ModalSignButton
                disabled={!bottom}
                onClick={() => setShowSignProcess(true)}
                testId="accept-terms"
              >
                {t(`Sign with ${signType}`)}
              </ModalSignButton>
            </ModalSignActions>
          </>
        )}
      </ModalSignStyles>
    </>
  );
};
