export enum RoutesName {
  login = '/login',
  terms = '/terms',
  home = '/',
  configuration = '/configuration',
  workflow = '/workflow',
  transition = '/transition',
  results = '/results/:operationId',
  ocr = '/results/:operationId/ocr',
  security = '/results/:operationId/security',
  verification = '/results/:operationId/verification',
  antiFraud = '/results/:operationId/antiFraud',
  notFound = '/notFound',
  government = '/results/:operationId/government',
  noAuthorized = '/noAuthorized',
}

type ModelingList = {
  title: string;
  active: boolean;
  testId: string;
};

export enum Storage {
  token = 'TOKEN',
  refreshToken = 'REFRESH_TOKEN',
  operationId = 'OPERATION_ID',
  trackingToken = 'TRACKING_TOKEN',
}

export enum DeviceSize {
  mobile = 768,
  desktop = 769,
}

export enum TypeDocument {
  id = 'IDCard',
  driverLicense = 'DriversLicense',
  passport = 'Passport',
  residentID = 'ResidentID',
  custom = 'Custom',
}

export enum TypeDocumentDTO {
  IDCard = 'ID_CARD',
  DriversLicense = 'DRIVING_LICENSE',
  Passport = 'PASSPORT',
  ResidentID = 'RESIDENCE_ID',
  Custom = 'CUSTOM',
}

export const ModelingList: { [key in TypeDocument]: ModelingList } = {
  [TypeDocument.custom]: {
    title: 'Auto',
    active: true,
    testId: 'settings_model_auto_read_option',
  },
  [TypeDocument.id]: {
    title: 'Identity card',
    active: true,
    testId: 'settings_model_id_card_option',
  },
  [TypeDocument.driverLicense]: {
    title: 'Drivers license',
    active: true,
    testId: 'settings_model_drive_license_option',
  },
  [TypeDocument.passport]: {
    title: 'Passport',
    active: true,
    testId: 'settings_model_passport_option',
  },
  [TypeDocument.residentID]: {
    title: 'Residence card',
    active: true,
    testId: 'settings_model_foreign_card_option',
  },
};

export enum AuthName {
  token = 'TOKEN',
  refreshToken = 'REFRESH_TOKEN',
}

export const REQUIRED_VALIDATION = 'This field is required';

export const endPoints = {
  Geolocation: '/api/ReverseGeocoding',
  AntiFraud: '/api/antifraud',
  Jumio: {
    Start: '/api/jumio/start',
    Status: '/api/jumio/status',
    Data: '/api/jumio/data',
  },
  PAD: '/api/PAD',
  MorphologicalValidation: '/api/security',
  Template: '/api/selphid/template',
  Onboardings: {
    Create: '/api/onboardings',
    Check: '/api/onboardings/check',
    Detail: '/api/onboardings',
    Results: '/api/onboardings/results',
    Ocr: '/api/ocr',
    Finish: '/api/onboardings/finish',
  },
  Authenticate: {
    Matching: '/api/authenticate/matching',
    Liveness: '/api/authenticate/liveness',
    Antispoofing: '/api/authenticate/antispoof',
  },
  Versions: '/api/versions',
  Governmental: '/api/security/governmental',
  Terms: '/api/tac',
  TermsAcceptance: '/api/tac/acceptance',
  Workflows: {
    Start: '/workflows',
  },
};

export const DOCUMENT_FACIAL = 'DOCUMENT_FACIAL_CAPTURE';
export const DOCUMENT_SELFIE = 'SELFIE';

export const ACTIVE_COUNTRIES = [
  'AE',
  'AR',
  'BO',
  'BR',
  'CL',
  'CO',
  'KR',
  'CR',
  'EC',
  'SV',
  'ES',
  'GT',
  'HN',
  'LT',
  'MX',
  'NI',
  'PA',
  'PE',
  'PT',
  'DO',
  'UY',
  'VE',
  'GB',
];

export const DEFAULT_COUNTRY = 'XXX';

export const REFUSAL_REASON = 'Refusal Reason';

export const SELPHI_SDK = 'FACEPHI-SELPHI';

export const DEFAULT_TIMEZONE = 'Europe/Madrid';
