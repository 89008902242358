import { createContext, useContext, useEffect, useState } from 'react';
import { useToast } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { RequestMethods, useRequest } from '../hooks/useRequest';
import { useLog } from '../providers';
import { endPoints } from '../states';

type IProps = {
  children: React.ReactNode;
};

type ContextProps = {
  loading: boolean;
  geoCountry?: string;
  error: boolean;
  geolocate(): void;
};

const Ctx = createContext<ContextProps>({
  loading: false,
  error: true,
  geolocate: () => {},
});

export const GeolocationProvider = ({ children }: IProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [geoCountry, setGeoCountry] = useState();

  const { request } = useRequest();
  const { t } = useTranslation();
  const { toastManager } = useToast();
  const { setInspectletTag } = useLog();

  const toastError = () => {
    toastManager({
      type: 'error',
      message: t('we cant find your location'),
      duration: 2000,
    });
    setError(true);
  };

  const getGeolocation = async (latitude: number, longitude: number) => {
    try {
      const response = await request(endPoints.Geolocation, {
        method: RequestMethods.get,
        params: { latitude, longitude },
      });
      const data = response;
      setGeoCountry(data);
    } catch {
      toastError();
    }
  };

  const geolocationSuccess = async (position: {
    coords: { latitude: number; longitude: number };
  }) => {
    const {
      coords: { latitude, longitude },
    } = position;

    await getGeolocation(latitude, longitude);
    setLoading(false);
  };

  const geolocate = () => {
    if (!loading && !geoCountry) {
      setLoading(true);
      navigator.geolocation.getCurrentPosition(geolocationSuccess, toastError);
    }
  };

  useEffect(() => {
    setInspectletTag(['tagSession', { location: geoCountry }]);
  }, [geoCountry]);

  return (
    <Ctx.Provider
      value={{
        loading,
        geoCountry,
        error,
        geolocate,
      }}
    >
      {children}
    </Ctx.Provider>
  );
};

export const useGeolocation = () => useContext(Ctx);
