import { ReactNode, useEffect, useMemo } from 'react';
import {
  CardBase,
  FlexContainer,
  GridContainer,
  Label,
  useDeviceSize,
  useTimezone,
} from '@facephi/ui-react'; // TODO: Review these changes when the endpoint is working again
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useOperation } from '../../providers';
import { Operation, RoutesName } from '../../states';
import { antifraudState } from '../../states/atoms';
import { CardListHeader } from '../cards';
import { CardList, DataList } from '../cards/CardList';
import { GoBackHeader } from '../goBackHeader';
import { Banner } from './Banner';
import { AntiFraudImg } from './Styles';

const IMAGE_TITLE: { [key in string]: string } = {
  BackDocument: 'DOCUMENT_BACK',
  FrontDocument: 'DOCUMENT_FRONT',
  Selfie: 'SELFIE',
};

export const PreviousOnboardings = () => {
  const { t } = useTranslation();
  const { operationId } = useParams();
  const { isMobile } = useDeviceSize();
  const navigate = useNavigate();
  const {
    operation: operationData,
    antifraud: { data: antifraudData, loading },
  } = useOperation();
  const { formatTimezone } = useTimezone();
  const antifraud = useRecoilValue(antifraudState);

  useEffect(() => {
    if (!antifraud) {
      navigate(-1);
    }
  }, [antifraud]);

  const isLoading = useMemo(() => {
    return loading || !antifraudData;
  }, [antifraudData]);

  const operation: Operation = useMemo(
    () =>
      operationData
        ? { ...operationData.data }
        : { assets: [], currentStep: '' },
    [operationData]
  );

  const dataInfo: DataList[] = [
    {
      title: 'Reason for rejection',
      value: antifraudData?.matches.length
        ? t('PREVIOUS ONBOARDING ALREADY REGISTERED')
        : t('NO HAVE'),
      valueStatus: antifraudData?.matches.length ? 'error400' : 'success400',
    },
    {
      title: 'Digital onboarding date',
      value: operation.creationTime
        ? formatTimezone(operation.creationTime, 'DD-MM-YYYY')
        : '',
    },
  ];

  const CardImages = ({
    title,
    children,
    rowGapDesktop = '2.4',
  }: {
    title: string;
    children: ReactNode;
    rowGapDesktop?: string;
  }) => {
    return (
      <CardBase>
        <CardListHeader
          flexDirection="column"
          rowGap={isMobile ? '1.6' : rowGapDesktop}
          flex="1"
        >
          <Label fontSize="12" semibold upperCase>
            {t(title)}
          </Label>
          <FlexContainer
            as="section"
            alignItems="center"
            columnGap="3.2"
            rowGap="3.2"
            flexDirection={isMobile ? 'column' : 'row'}
          >
            {children}
          </FlexContainer>
        </CardListHeader>
      </CardBase>
    );
  };

  const getCaptureDocuments = () => {
    return Object.entries(antifraudData?.matches[0].assets || {}).map(
      ([type, image]) => (
        <FlexContainer as="figure" flexDirection="column" rowGap="1.6" flex="1">
          <AntiFraudImg src={`data:image/png;base64,${image}`} alt={type} />
          <Label fontSize="12" textAlign="center" as="figcaption">
            {t(IMAGE_TITLE[type])}
          </Label>
        </FlexContainer>
      )
    );
  };

  return (
    <>
      <GoBackHeader
        route={generatePath(RoutesName.results, {
          operationId,
        })}
        linkLabel="Go back"
        title="AntiFraud"
      />
      <Banner />
      <GridContainer
        gridTemplateColumns="1fr"
        gridTemplateRows="auto"
        gap="1.6"
        as="section"
      >
        <CardList
          title={t('ANTI-FRAUD CHECK')}
          subTitle={t('Results') as string}
          data={dataInfo}
          columnsPerRow="1"
          showEmptyState
          loading={isLoading}
        />
        {!isLoading && (
          <CardImages title="Captured images">
            {getCaptureDocuments()}
          </CardImages>
        )}
      </GridContainer>
    </>
  );
};
