import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { ResultsScreenType } from '../model';

const { persistAtom } = recoilPersist();
export const resultsScreenState = atom<ResultsScreenType>({
  key: 'resultsScreenState',
  default: ResultsScreenType.detail,
  effects_UNSTABLE: [persistAtom],
});
