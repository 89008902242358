/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo, useState, useEffect } from 'react';
import { useTracking } from '@facephi/sdk-web';
import {
  Dropdown,
  FlexContainer,
  GridContainer,
  Label,
  useDeviceSize,
  useToast,
  Icon,
  IconName,
  InphiniteThemeColors,
} from '@facephi/ui-react';
import JSZip from 'jszip';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
  useDownloadBase64,
  useLoadAsset,
  useRequest,
  useVariables,
  RequestMethods,
  useLogger,
} from '../../hooks';
import {
  useGeolocation,
  useOperation,
  useExtendedSdk,
  useAuth,
} from '../../providers';
import {
  Asset,
  DOCUMENT_FACIAL,
  DOCUMENT_SELFIE,
  FacialAuthenticateResult,
  Operation,
  RoutesName,
  endPoints,
  TrackingFamily,
  FacialAuthenticationStatus,
  FacialLivenessDiagnostic,
  StatusTypeList,
  circleIconData,
  IVideoAssistanceType,
  GovernmentServices,
  MimeType,
  GovernmentCountryCodes,
  internalProviders,
  StatusOperations,
} from '../../states';
import {
  AMLListState,
  antifraudState,
  documentValidationState,
  geolocationState,
  logImagesState,
  previousOnboardingsValidationState,
  signState,
  signTypeState,
  recordOnboardingState,
  livenessValidationState,
  governmentValidationState,
  behaviourCaptureState,
  videoAssistanceState,
  videoAssistanceTypeState,
  antispoofingState,
} from '../../states/atoms';
import { getCountryISO3 } from '../../utils';
import {
  CardGridMap,
  CardGridSmall,
  CardResults,
  CardResultsContent,
  CardResultsHeaderSpinner,
} from '../cards';
import { CardList, DataList } from '../cards/CardList';
import { ModalSign } from '../sign/ModalSign';
import { Banner } from './Banner';
import { ImageLoad } from './ImageLoad';
import { SelfieValidations } from './SelfieValidation';
import { StatusIcon, StatusTypeProps } from './StatusIcon';
import {
  ButtonLinkStyles,
  ResultsButton,
  ResultsDataImg,
  ResultsDataImgWrapper,
  ResultsDataWrapper,
  ResultsWrapper,
} from './Styles';

type StatusIcon = StatusTypeProps | undefined;

type DownloadImages = {
  name: string;
  url: string;
};

const getLabelColor = (status: any) => {
  let color = 'grey700';
  switch (status) {
    case StatusOperations.SUCCEEDED:
      color = 'success400';
      break;
    case StatusOperations.DENIED:
      color = 'error400';
      break;
    case StatusOperations.CONNECTION_ERROR:
      color = 'warning400';
      break;
    default:
      color = 'grey700';
  }

  return color;
};

export const onboardingIcon = (
  statusType?: StatusTypeProps
): React.ReactElement | undefined => {
  let iconName;
  let iconColor;
  switch (statusType) {
    case StatusTypeList.SUCCEEDED:
      iconName = circleIconData['success'].iconName;
      iconColor = circleIconData['success'].iconColor;
      break;

    case StatusTypeList.DENIED:
      iconName = circleIconData['error'].iconName;
      iconColor = circleIconData['error'].iconColor;
      break;

    case StatusTypeList.PENDENT:
    case StatusOperations.CONNECTION_ERROR:
    case StatusOperations.EXPIRED:
      iconName = circleIconData['warning'].iconName;
      iconColor = circleIconData['warning'].iconColor;
      break;

    case StatusTypeList.IN_PROGRESS:
    case StatusTypeList.STARTED:
      iconName = undefined;
      iconColor = undefined;
      break;
  }
  return iconName ? (
    <Icon
      iconName={iconName}
      color={iconColor}
      size="32"
      weight="fill"
      testId="onboarding_card_result_icon"
      className="onboarding_card_result_icon"
    />
  ) : (
    <CardResultsHeaderSpinner genericSpinner size="S" insideComponent />
  );
};

export const Results = () => {
  const { t } = useTranslation();
  const { isMobile } = useDeviceSize();
  const navigate = useNavigate();
  const { operationId } = useParams();
  const { geoCountry } = useGeolocation();
  const antifraud = useRecoilValue(antifraudState);
  const documentValidation = useRecoilValue(documentValidationState);
  const previousOnboardingsValidation = useRecoilValue(
    previousOnboardingsValidationState
  );
  const liveness = useRecoilValue(livenessValidationState);
  const governmentValidation = useRecoilValue(governmentValidationState);
  const logImages = useRecoilValue(logImagesState);
  const geolocation = useRecoilValue(geolocationState);
  const AMLList = useRecoilValue(AMLListState);
  const sign = useRecoilValue(signState);
  const signType = useRecoilValue(signTypeState);
  const recordOnboarding = useRecoilValue(recordOnboardingState);
  const { apiDemoUrl, trackingUrlAssets } = useVariables();
  const [checkingsNumber, setCheckingsNumber] = useState<number>();
  const behaviour = useRecoilValue(behaviourCaptureState);
  const videoAssistance = useRecoilValue(videoAssistanceState);
  const videoAssistanceType = useRecoilValue(videoAssistanceTypeState);
  const antispoofingActivated = useRecoilValue(antispoofingState);
  const { helpVideoAssistance } = useExtendedSdk();
  const { captureMessage, captureException } = useLogger();

  const {
    ocrData,
    pending,
    operation: operationData,
    antifraud: antifraudData,
    morphologicalValidation,
    previousOnboardings: previousOnboardingsData,
    governmentValidation: governmentValidationData,
    governmentCard,
    workflow,
    shouldCheckGovernment,
    governmentCountry,
    finishedOperation,
    setFinishedOperation,
    documentSignature,
    matchingResult,
    livenessResult,
    antispoofResult,
  } = useOperation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [facialAuthenticateResult, setFacialAuthenticateResult] =
    useState<[FacialAuthenticateResult]>();
  const { getImageUrl } = useLoadAsset();
  const { download } = useDownloadBase64();
  const { toastManager } = useToast();
  const [statusState, setStatusState] = useState<StatusTypeProps>();
  const [pendingState, setStatusPending] = useState<boolean>(true);
  const { requestBasic: demoRequest } = useRequest(apiDemoUrl);
  const { demoVersion } = useAuth();
  const [listCheckings, setListCheckings] = useState<any[]>([]);
  const [signatureAttemps, setSignatureAttemps] = useState<number>(0);
  const { changeOperationId } = useTracking();

  const {
    loading: govLoading,
    status: govStatus,
    data: govData,
  } = governmentValidationData;

  const handleFinish = () => {
    changeOperationId('');
    navigate(RoutesName.home);
  };

  const operation: Operation = useMemo(
    () =>
      operationData
        ? { ...operationData.data }
        : { assets: [], currentStep: '' },
    [operationData]
  );

  const documents: Asset[] = useMemo(
    () =>
      operation && operation.assets
        ? operation.assets.filter((asset: any) =>
            asset.contentType.includes('image')
          )
        : [],
    [operation]
  );

  const downloadImages = async () => {
    const zip = new JSZip();
    try {
      const images = ['DOCUMENT_BACK', 'DOCUMENT_FRONT', 'SELFIE'].reduce(
        (acc, type) => {
          const image = documents.find((item) => item.type === type);
          if (image) {
            acc.push({
              name: type,
              url: trackingUrlAssets + image.url,
            });
          }
          return acc;
        },
        [] as DownloadImages[]
      );
      if (images.length) {
        const data = await Promise.all(
          images.map((item) => getImageUrl(item.url))
        );
        images.forEach((item, index: number) => {
          zip.file(
            `${item.name}.${data[index].type.split('/')[1]}`,
            data[index]
          );
        });
        const content = await zip.generateAsync({ type: 'blob' });
        download(URL.createObjectURL(content), `images_${operationId}`);
      }
    } catch {
      toastManager({
        duration: 3000,
        type: 'error',
        message: t(
          'Something went wrong with the download of images, try another time'
        ),
      });
    }
  };

  const coordinates = useMemo(
    () =>
      operation.coordinates
        ? [
            {
              lat: operation.coordinates.latitude,
              lng: operation.coordinates.longitude,
              name: 'marker',
            },
          ]
        : undefined,
    [operation]
  );

  const resultIcon = (statusType?: StatusTypeProps, negative?: boolean) => {
    return statusType ? (
      <StatusIcon status={statusType} invert={negative} />
    ) : null;
  };

  const governmentFacialResult = operation.facialAuthenticateResultList?.find(
    (facialResult: FacialAuthenticateResult) =>
      Object.values(GovernmentServices).includes(
        facialResult.source as GovernmentServices
      )
  );

  const facialResultList: FacialAuthenticateResult[] = useMemo(() => {
    const ownFacialResultList = Array.from(
      operation.facialAuthenticateResultList || []
    );

    if (!governmentFacialResult && Object.keys(govData || {}).length) {
      ownFacialResultList?.push({
        assetsUrl: [],
        authStatus: FacialAuthenticationStatus[
          govData?.succeed ? 'positive' : 'negative'
        ].toUpperCase() as FacialAuthenticationStatus,
        similarity: 0,
        source: govData?.source || '',
      });
    }

    return ownFacialResultList;
  }, [operation.facialAuthenticateResultList, govData]);

  const renderFacialResultListDropdown = () => {
    const changeHandler = (provider: string) => {
      const selectedProvider = facialResultList?.find(
        (option: FacialAuthenticateResult) => option.source === provider
      );
      selectedProvider && setFacialAuthenticateResult([selectedProvider]);
    };

    if (facialResultList && facialResultList.length >= 2) {
      const dropdownOptions = facialResultList?.map(
        (option: FacialAuthenticateResult) => ({
          name: option.source,
          value: option.source,
        })
      );

      return (
        <Dropdown
          options={dropdownOptions}
          onChange={changeHandler}
          placeholder={facialAuthenticateResult?.[0].source}
        />
      );
    }
  };

  const selfieValidationAssets: Asset[] | undefined = useMemo(() => {
    const opreationAssets = Array.from(operation.assets || []);

    if (governmentFacialResult?.assetsUrl.length || govData?.image) {
      opreationAssets?.push({
        type: DOCUMENT_FACIAL,
        url: governmentFacialResult?.assetsUrl[0] || govData?.image || '',
        contentType: MimeType.jpeg,
        source: governmentFacialResult?.source || govData?.source,
      });
    }
    return opreationAssets;
  }, [operation.assets, governmentFacialResult, govData?.image]);

  useEffect(() => {
    if (facialResultList?.length) {
      const governmentMatchingResult = facialResultList.find(
        (result) => result.source !== internalProviders.demoBackend
      );
      setFacialAuthenticateResult([
        governmentMatchingResult || facialResultList[0],
      ]);
    }
  }, [facialResultList]);

  const getLoadingDocuments = () =>
    Array.from({ length: 4 }).map((_, index) => (
      <ResultsDataImg key={index}>
        <Skeleton height={70} width={70} borderRadius={8} />
        <div style={{ marginTop: '0.8rem' }}>
          <Skeleton width="65%" height={16} borderRadius={8} />
        </div>
      </ResultsDataImg>
    ));

  const getDocuments = () =>
    documents.map((item, index) => (
      <ResultsDataImg
        key={index}
        bigImg={item.type !== DOCUMENT_SELFIE && item.type !== DOCUMENT_FACIAL}
      >
        <ImageLoad asset={item} />
        <Label fontSize="12">{t(item.type)}</Label>
      </ResultsDataImg>
    ));

  const personalInformation: DataList[] | undefined =
    ocrData?.personalInformation &&
    Object.entries(ocrData.personalInformation)
      .filter(([key, value]) => key !== '__typename' && value)
      .map((item) => ({
        title: item[0],
        value: item[1],
      }));

  const statusNationality = useMemo(() => {
    if (!pending && !ocrData?.personalInformation?.nationality) {
      return;
    }

    return !ocrData?.personalInformation?.nationality
      ? StatusTypeList.IN_PROGRESS
      : ocrData?.personalInformation?.nationality ===
        getCountryISO3(geoCountry || '')
      ? StatusTypeList.SUCCEEDED
      : StatusTypeList.DENIED;
  }, [geoCountry, ocrData]);

  const getAMLOrder = () => {
    if (geolocation || documentValidation) {
      return '1';
    }
  };

  const governementServiceIcon: IconName = useMemo(() => {
    switch (governmentCountry) {
      case GovernmentCountryCodes.ARG:
        return 'RenaperIcon';
      case GovernmentCountryCodes.PER:
        return 'ReniecIcon';
      case GovernmentCountryCodes.BRA:
        return 'SerproIcon';
      default:
        return 'FingerprintSimple';
    }
  }, [governmentCountry]);

  // evaulate necessary security checks
  useEffect(() => {
    const list: any[] = [];
    facialAuthenticateResult?.length &&
      list.push(facialAuthenticateResult[0].authStatus);
    liveness && list.push(livenessResult?.diagnostic?.toUpperCase());
    governmentValidation && shouldCheckGovernment && list.push(govStatus);
    geolocation && statusNationality && list.push(statusNationality);
    antifraud && list.push(antifraudData?.status);
    documentValidation && list.push(morphologicalValidation?.status);
    previousOnboardingsValidation && list.push(previousOnboardingsData?.status);
    antispoofingActivated &&
      list.push(
        antispoofResult && typeof antispoofResult?.attack === 'boolean'
          ? antispoofResult?.attack
            ? StatusTypeList.DENIED
            : StatusTypeList.SUCCEEDED
          : StatusOperations.CONNECTION_ERROR
      );
    checkingsNumber &&
      list.length === checkingsNumber &&
      setListCheckings(list);
  }, [
    operation,
    facialAuthenticateResult,
    governmentValidationData,
    statusNationality,
    antifraudData,
    morphologicalValidation,
    previousOnboardingsData,
    checkingsNumber,
    antispoofResult,
  ]);

  useEffect(() => {
    let count = 1;

    liveness && count++;
    governmentValidation && shouldCheckGovernment && count++;
    geolocation && count++;
    antifraud && count++;
    documentValidation && count++;
    previousOnboardingsValidation && count++;
    antispoofingActivated && count++;
    setCheckingsNumber(count);
  }, [shouldCheckGovernment]);

  // operation status calculation and store through the correspondent endpoint
  useEffect(() => {
    if (operationData?.error) {
      setStatusPending(false);
      setStatusState(StatusOperations.CONNECTION_ERROR);
    } else if (checkingsNumber && listCheckings.length === checkingsNumber) {
      let status: StatusTypeProps;
      if (
        (liveness &&
          listCheckings[1]?.toUpperCase() !==
            FacialLivenessDiagnostic.live.toUpperCase()) ||
        (facialAuthenticateResult?.length &&
          listCheckings[0]?.toUpperCase() !=
            FacialAuthenticationStatus.positive.toUpperCase())
      ) {
        status = StatusOperations.DENIED;
      } else {
        status =
          listCheckings.length > 0 &&
          listCheckings.every(
            (data) =>
              data == StatusOperations.SUCCEEDED ||
              data == StatusTypeList.VALIDATE ||
              data == FacialAuthenticationStatus.positive.toUpperCase() ||
              data == FacialLivenessDiagnostic.live.toUpperCase()
          )
            ? StatusOperations.SUCCEEDED
            : listCheckings.some(
                (data) =>
                  data == StatusOperations.DENIED ||
                  data == StatusOperations.CANCELLED ||
                  data == FacialAuthenticationStatus.negative.toUpperCase()
              )
            ? StatusOperations.DENIED
            : listCheckings.length > 0 &&
              listCheckings.some(
                (data) => data == StatusOperations.CONNECTION_ERROR
              )
            ? StatusOperations.DENIED
            : listCheckings.some((data) => data == StatusOperations.STARTED)
            ? StatusTypeList.IN_PROGRESS
            : StatusTypeList.DENIED;
      }
      setStatusPending(false);
      setStatusState(status);
      if (
        status === StatusTypeList.DENIED &&
        videoAssistance &&
        videoAssistanceType === IVideoAssistanceType.error
      ) {
        helpVideoAssistance(operationId as string);
      }
    }
  }, [listCheckings, operationData]);

  useEffect(() => {
    if (
      statusState &&
      statusState !== StatusTypeList.IN_PROGRESS &&
      demoVersion &&
      operation &&
      (!operation.status ||
        operation.status == StatusOperations.CONNECTION_ERROR) &&
      workflow &&
      !finishedOperation
    ) {
      setFinishedOperation(true);
      (async () => {
        try {
          statusState !== StatusOperations.CONNECTION_ERROR &&
            (await demoRequest(endPoints.Onboardings.Finish, {
              method: RequestMethods.post,
              headers: {
                'x-demo-app-version': demoVersion,
                'x-inphinite-operationid': operationId,
              },
              data: {
                status: statusState,
                family: TrackingFamily.ONBOARDING,
                reason: null,
                hasVideo: recordOnboarding,
              },
            }));
        } catch (e: any) {
          captureMessage(
            `Error in POST to ${endPoints.Onboardings.Finish} with status: ${statusState} and family Onboarding`
          );
          captureException(e, {
            operation: 'finish',
            status: statusState,
            id: operationId,
          });
          setStatusPending(false);
          setStatusState(StatusOperations.CONNECTION_ERROR);
          throw new Error(e);
        }
      })();
    }
  }, [statusState, demoVersion, operation]);

  const viewSignButton: boolean = useMemo(
    () =>
      operation
        ? sign &&
          signType &&
          statusState === StatusOperations.SUCCEEDED &&
          !!workflow
        : false,
    [statusState]
  );

  const disableSignButton =
    documentSignature.loading ||
    documentSignature.data?.signed ||
    signatureAttemps >= 3;

  const documentSignatureStatus = documentSignature.loading
    ? StatusTypeList.STARTED
    : documentSignature.status === StatusTypeList.STARTED
    ? StatusTypeList.PENDENT
    : documentSignature.status === StatusTypeList.SUCCEEDED
    ? StatusTypeList.SAVED
    : documentSignature.status;

  return (
    <ResultsWrapper flexDirection="column" rowGap="1.6">
      <Label
        fontSize={isMobile ? '12' : '32'}
        semibold
        textAlign="center"
        testId="results_section_title_text"
        id="results_section_title_text"
      >
        {t('Results')}
      </Label>

      <Banner margin={false} />

      <CardResults
        title={t('ONBOARDING RESULT') as string}
        status={
          pendingState
            ? StatusTypeList.IN_PROGRESS
            : statusState || StatusOperations.CONNECTION_ERROR
        }
        icon={onboardingIcon(
          pendingState
            ? StatusTypeList.IN_PROGRESS
            : statusState || StatusOperations.CONNECTION_ERROR
        )}
        testId="onboarding-result"
        idTitle="onboarding_card_result_title"
      >
        <CardResultsContent
          flexDirection="column"
          alignItems={isMobile ? 'start' : 'center'}
          rowGap="0.4"
        >
          <Label
            fontSize="21"
            semibold
            upperCase
            color={getLabelColor(statusState) as InphiniteThemeColors}
            id="onboarding_card_result_text"
            testId="onboarding_card_result_text"
          >
            {t(
              pendingState
                ? 'Onboarding in progress'
                : statusState || StatusOperations.CONNECTION_ERROR
            )}
          </Label>

          {statusState !== StatusTypeList.SUCCEEDED &&
            statusState !== StatusTypeList.DENIED && (
              <Label
                fontSize="12"
                id="onboarding_card_result_alert_text"
                testId="onboarding_card_result_alert_text"
              >
                {t(
                  pendingState
                    ? 'We are analyzing the information'
                    : `${
                        statusState || StatusOperations.CONNECTION_ERROR
                      }-description`
                )}
              </Label>
            )}
        </CardResultsContent>
      </CardResults>

      <CardResults
        title={t('BIOMETRIC VALIDATION') as string}
        dropdown={renderFacialResultListDropdown()}
      >
        <CardResultsContent
          flexDirection="column"
          alignItems={isMobile ? 'start' : 'center'}
          rowGap="1.6"
        >
          <Label fontSize="21" semibold upperCase>
            {t('FACE VERIFICATION')}
          </Label>

          <SelfieValidations
            loading={pendingState || govLoading}
            assets={selfieValidationAssets}
            facialState={
              facialAuthenticateResult?.length &&
              facialAuthenticateResult[0].authStatus
            }
            provider={
              facialAuthenticateResult?.length &&
              facialAuthenticateResult[0].source
            }
            livenessState={operation.facialLivenessResult?.diagnostic}
            operationStatus={statusState}
            antispoofValidation={antispoofResult}
          />
        </CardResultsContent>
      </CardResults>

      <ResultsDataWrapper
        flexDirection={isMobile ? 'column' : 'row'}
        columnGap="1.6"
        rowGap="1.6"
      >
        <CardList
          title={t('Obtained data')}
          subTitle={
            ocrData?.personalInformation
              ? `${ocrData.personalInformation.name || ''} ${
                  ocrData.personalInformation.surname || ''
                }`
              : ''
          }
          data={personalInformation as DataList[]}
          isRow
          loading={ocrData?.personalInformation ? false : pendingState}
          testId="obtained-data"
        />

        <CardResults title={t('IMAGES OBTAINED') as string}>
          <CardResultsContent>
            <ResultsDataImgWrapper
              wrap={isMobile ? 'no-wrap' : 'wrap'}
              justifyContent="flex-start"
              alignItems="flex-start"
              testId="images-obtained"
            >
              {documents.length
                ? getDocuments()
                : pendingState
                ? getLoadingDocuments()
                : null}
            </ResultsDataImgWrapper>
          </CardResultsContent>
          {logImages && (
            <FlexContainer justifyContent="flex-end">
              <ButtonLinkStyles
                variant="text"
                color="primary"
                size="S"
                semiBold={false}
                onClick={downloadImages}
              >
                {t('Download images')}
              </ButtonLinkStyles>
            </FlexContainer>
          )}
        </CardResults>
      </ResultsDataWrapper>

      <GridContainer
        gap="1.6"
        gridTemplateColumns={coordinates || isMobile ? '' : '1fr 1fr'}
      >
        {/* MAP */}
        {geolocation && (
          <CardGridMap
            gridRow="1/span 15"
            gridColumn="2"
            alignSelf="stretch"
            title={t('NATIONALITY VS LOCATION') as string}
            iconStatus={
              statusNationality && resultIcon(statusNationality, false)
            }
            coordinates={coordinates}
            message={ocrData?.personalInformation?.nationality}
            pending={pendingState}
          />
        )}
        {/* OCR: EXTRACTED DATA */}
        <CardGridSmall
          title={t('READING OF THE DOCUMENT') as string}
          gridRow="auto/span 5"
          gridColumn="1"
          alignSelf={'stretch'}
          order={''}
          iconStatus={resultIcon(
            pendingState
              ? StatusTypeList.IN_PROGRESS
              : statusState || StatusOperations.CONNECTION_ERROR,
            false
          )}
          navigateTo={generatePath(RoutesName.ocr, {
            operationId,
          })}
          message="Extracted data"
          icon="IdentificationCard"
          status={statusState || StatusOperations.CONNECTION_ERROR}
          loading={pendingState}
          testId="ocr-extracted-data"
        />
        {/* Morphological validation */}
        {documentValidation && (
          <CardGridSmall
            title={t('SECURITY') as string}
            gridRow="auto/span 5"
            alignSelf={'stretch'}
            iconStatus={resultIcon(morphologicalValidation.status, false)}
            navigateTo={generatePath(RoutesName.security, {
              operationId,
            })}
            message="Morphological"
            icon="LockKey"
            loading={morphologicalValidation.loading}
            status={morphologicalValidation.status}
            testId="morphologicalValidation-card"
          />
        )}
        {/* Government Services */}
        {governmentCard && (
          <CardGridSmall
            message="Government"
            gridRow="auto/span 5"
            alignSelf={'stretch'}
            iconStatus={resultIcon(govStatus, false)}
            title={t('SECURITY') as string}
            icon={governementServiceIcon}
            loading={govLoading}
            navigateTo={generatePath(RoutesName.government, {
              operationId,
            })}
            status={govStatus}
            testId="governmentServices"
          />
        )}

        {/* AML */}
        {AMLList && (
          <CardGridSmall
            title={t('BANNED LIST') as string}
            gridRow="auto/span 5"
            gridColumn={getAMLOrder()}
            alignSelf={geolocation ? 'flex-start' : 'stretch'}
            order={geolocation ? '' : '5'}
            iconStatus={resultIcon(statusState, false)}
            message="AML Validation"
            icon="UserList"
            loading={pendingState}
            status={statusState}
          />
        )}
        {/* Antifraud */}
        {antifraud && (
          <CardGridSmall
            title={t('SECURITY') as string}
            gridRow="auto/span 5"
            iconStatus={resultIcon(antifraudData.status, false)}
            message="BLOCKED LISTS"
            icon="XCircle"
            loading={antifraudData.loading}
            status={antifraudData.status}
            testId="antifraud-card"
          />
        )}
        {/* PreviousOnboardings */}
        {previousOnboardingsValidation && (
          <CardGridSmall
            title={t('SECURITY') as string}
            gridRow="auto/span 5"
            iconStatus={resultIcon(previousOnboardingsData.status, false)}
            message="Previous onboarding"
            icon="UserCircle"
            loading={previousOnboardingsData.loading}
            status={previousOnboardingsData.status}
            testId="previousOnboardings-card"
          />
        )}
        {/* Document Signature */}
        {viewSignButton && (
          <CardGridSmall
            title={t('Signature').toUpperCase()}
            gridRow="auto/span 5"
            iconStatus={resultIcon(
              pendingState || documentSignature.loading
                ? StatusTypeList.IN_PROGRESS
                : documentSignatureStatus,
              false
            )}
            message="Digital document"
            icon="FileText"
            loading={documentSignature.loading || pendingState}
            status={documentSignatureStatus}
            testId="documentSignature-card"
          />
        )}
        {/* Behaviour validation */}
        {/* TO DO adjust status to validation */}
        {behaviour && (
          <CardGridSmall
            title={t('Validation') as string}
            gridRow="auto/span 5"
            iconStatus={resultIcon('SUCCEEDED', false)}
            message="Behaviour"
            icon="Strategy"
            loading={false}
            status="SUCCEEDED"
            testId="behaviour-card"
          />
        )}
      </GridContainer>

      <ResultsButton
        color="primary"
        variant={viewSignButton ? 'outline' : 'filled'}
        $transparent={viewSignButton}
        size="L"
        onClick={handleFinish}
        testId="results_finish_button"
      >
        {t('Finish')}
      </ResultsButton>

      {viewSignButton && (
        <>
          <ResultsButton
            color="primary"
            size="L"
            upperCase
            onClick={() => {
              setShowModal(true);
            }}
            disabled={pendingState || disableSignButton}
          >
            {t('sign document')}
          </ResultsButton>

          <ModalSign
            show={showModal}
            onChangeShow={(showModal) => setShowModal(showModal)}
            onCancel={() => setShowModal(false)}
            signatureAttemps={signatureAttemps}
            setSignatureAttemps={setSignatureAttemps}
            signType={signType}
          />
        </>
      )}
    </ResultsWrapper>
  );
};
