import { Label } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { Hourglass } from '../icons';
import { TimeoutLabelsWrapper, TimeoutStatusWrapper } from './Styles';

export const TimeoutStatus = () => {
  const { t } = useTranslation();
  return (
    <TimeoutStatusWrapper flexDirection="column" alignItems="center">
      <Hourglass />
      <TimeoutLabelsWrapper flexDirection="column" alignItems="center">
        <Label fontSize="24" semibold>
          {t('Time exceeded')}
        </Label>
        <Label textAlign="center">
          {t(
            'Keep your face inside the circle and looking straight ahead until the picture is taken'
          )}
        </Label>
      </TimeoutLabelsWrapper>
    </TimeoutStatusWrapper>
  );
};
