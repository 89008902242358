import { Button, FlexContainer, Modal, setColorTheme } from '@facephi/ui-react';
import styled from 'styled-components/macro';
import { desktopStyles, mobileStyles } from '../../styles';

// MODAL PDF
export const ModalSignContent = styled(FlexContainer)`
  overflow-y: auto;
  margin-top: 1rem;
  margin-bottom: 12.6rem;

  ${desktopStyles} {
    margin-top: 2rem;
  }

  & canvas {
    width: 100% !important;
    height: auto !important;
  }
`;

export const ModalSignStyles = styled(Modal)`
  width: 70vw;
  max-width: 99.6rem;
  min-height: 66vh;
  max-height: 68rem;
  border-radius: 2.4rem;
  overflow-x: clip;

  ${mobileStyles} {
    height: 90vh;
    max-height: 57.6rem;
    border-radius: 1.2rem;
  }
`;

export const ModalSignActions = styled(FlexContainer)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${setColorTheme('white')};
  padding: 1rem;
  flex-direction: column;

  ${desktopStyles} {
    flex-direction: row;
    padding: 3.2rem;
  }
`;

export const ModalSignButton = styled(Button).attrs({
  fullWidth: true,
  color: 'primary',
  size: 'L',
})`
  ${desktopStyles} {
    max-width: 30.6rem;
  }
`;

// MODAL SELFIE
export const SelfieSignWrapper = styled(FlexContainer)`
  height: 100%;
  width: 100%;

  .selphi-widget {
    padding: 0 2rem 0;
  }
`;

export const SelfieSignResult = styled(FlexContainer)`
  padding: 2.2rem;
  row-gap: 1.6rem;
  flex: 1;
  width: 100%;

  ${desktopStyles} {
    row-gap: 3.2rem;
  }
}
`;

export const DeniedActions = styled(FlexContainer)<{ isTimeout?: boolean }>`
  width: 36.3%;
  column-gap: 0.8rem;
  margin-top: auto;

  ${mobileStyles} {
    ${({ isTimeout }) => isTimeout && 'margin-top: 1.6rem'};
    flex-direction: column;
    row-gap: 0.8rem;
    width: 100%;
  }
`;

export const DeniedButton = styled(Button).attrs({
  fullWidth: true,
  size: 'L',
  upperCase: true,
})<{ isTimeout?: boolean }>`
  min-width: 16.8rem;

  ${desktopStyles} {
    ${({ isTimeout }) => isTimeout && 'width: 9.6rem'};
  }
`;

export const SelfieSignHeaderWrapper = styled(FlexContainer)`
  width: 100%;
  padding: 1.5rem;
`;

export const GraphicWrapper = styled(FlexContainer)<{ $hidden?: boolean }>`
  visibility: ${({ $hidden }) => ($hidden ? 'hidden' : 'visible')};
`;

export const TimeoutStatusWrapper = styled(FlexContainer)`
  padding-top: 2.6rem;
`;

export const TimeoutLabelsWrapper = styled(FlexContainer)`
  row-gap: 0.8rem;
  margin-top: 2.4rem;
`;
