import { ReactNode } from 'react';
import { FlexContainerProps } from '@facephi/ui-react';
import { Header } from '../header';
import { MainStyles } from './Styles';

type Props = FlexContainerProps & {
  children: ReactNode;
  className?: string;
  negative?: boolean;
  padding?: boolean;
};

export const Layout = ({
  children,
  className,
  justifyContent = 'center',
  negative,
  padding,
  flexDirection,
  wrap,
  alignItems,
  columnGap,
  rowGap,
  flex,
  testId,
  css,
  ref,
}: Props) => {
  return (
    <>
      <Header />
      <MainStyles
        forwardedAs="main"
        className={className}
        flex={flex || '1'}
        justifyContent={justifyContent}
        $negative={negative}
        $padding={padding}
        alignItems={alignItems}
        flexDirection={flexDirection}
        wrap={wrap}
        columnGap={columnGap}
        rowGap={rowGap}
        testId={testId}
        css={css}
        ref={ref}
      >
        {children}
      </MainStyles>
    </>
  );
};
