import { FlexContainer } from '@facephi/ui-react';
import styled from 'styled-components/macro';
import { desktopStyles, mobileStyles } from '../../styles';

export const ServicesWrapper = styled(FlexContainer)`
  ${desktopStyles} {
    padding: 3rem 0;
    max-width: 53rem;
  }

  > form {
    width: 100%;
    ${desktopStyles} {
      padding: 0 4.4rem;
    }
    > section {
      width: 100%;
    }

    > button {
      ${mobileStyles} {
        margin-top: auto;
      }
    }
  }
`;

export const DropdownFlagsWrapper = styled.div`
  div[data-test='input-container'] {
    > svg {
      position: absolute;
      inset: 0.8rem 1.7rem;
      + input {
        padding-left: 5rem;
      }
    }
  }
`;
