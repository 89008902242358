import * as yup from 'yup';
import { REQUIRED_VALIDATION, TypeDocument } from '../constants';
import { LoginDto } from './auth';
import { ServiceDto } from './service';

export const LoginSchema: yup.ObjectSchema<LoginDto> = yup.object({
  username: yup
    .string()
    .required(REQUIRED_VALIDATION)
    .email('Enter a valid email'),
  password: yup.string().required(REQUIRED_VALIDATION),
});

export const ServiceSchema: yup.ObjectSchema<ServiceDto> = yup.object({
  country: yup.string().required(REQUIRED_VALIDATION),
  document: yup
    .string()
    .oneOf(Object.values(TypeDocument))
    .required(REQUIRED_VALIDATION),
});
