import { useEffect } from 'react';
import { useSdk, VideoAssistanceEvents } from '@facephi/sdk-web';
import { useToast } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';

export const VideoAssistance = () => {
  const { eventSystem } = useSdk();
  const { t } = useTranslation();
  const { toastManager } = useToast();

  useEffect(() => {
    const videoAssistanceHangUp = eventSystem.subscribe(
      VideoAssistanceEvents.hangUp,
      () => {
        toastManager({
          duration: 3000,
          type: 'success',
          message: t('Video assistance completed successfully'),
        });
      }
    );

    const videoAssistanceError = eventSystem.subscribe(
      VideoAssistanceEvents.error,
      () => {
        toastManager({
          duration: 3000,
          type: 'error',
          message: t(
            'Something went wrong with video assistance, try another time'
          ),
        });
      }
    );

    return () => {
      eventSystem.unsubscribe(videoAssistanceHangUp);
      eventSystem.unsubscribe(videoAssistanceError);
    };
  }, []);

  return null;
};
