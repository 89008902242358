export const GenericError = ({
  width,
  height,
}: {
  width?: string;
  height?: string;
}) => (
  <svg
    width={width || '500'}
    height={height || '501'}
    viewBox="0 0 500 501"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M454.65 301.628C463.434 250.098 470.629 197.672 467.171 145.515C462.392 73.4492 427.772 44.6693 418.077 40.6496C386.513 25.7455 326.724 19.6191 233.967 100.889C188.749 140.507 126.044 156.045 85.0247 199.994C11.3132 278.971 32.2723 367.915 46.2987 405.551H427.163C440.304 372.289 448.627 336.975 454.65 301.628Z"
      fill="#F3F4F9"
    />
    <path
      d="M235.063 481.608C205.312 481.608 177.317 478.681 156.243 473.364C128.29 466.314 122.422 457.773 122.422 451.854C122.422 445.935 128.29 437.394 156.243 430.343C177.32 425.027 205.312 422.1 235.063 422.1C264.815 422.1 292.81 425.027 313.884 430.343C341.837 437.394 347.705 445.935 347.705 451.854C347.705 457.773 341.837 466.314 313.884 473.364C292.807 478.681 264.815 481.608 235.063 481.608Z"
      fill="#1D2C4D"
    />
    <path
      d="M274.09 478.627L272.062 365.22C272.342 363.594 273.71 362.375 275.362 362.304C283.069 361.967 292.939 357.757 298.062 353.863C304.576 348.912 308.8 343.269 311.716 335.623L297.002 240.524L293.652 218.885L241.666 226.928L185.646 475.972C185.646 475.972 232.699 482.941 274.094 478.623L274.09 478.627Z"
      fill="white"
    />
    <path
      d="M274.091 479.462C273.116 479.462 272.317 478.681 272.299 477.703L270.271 365.253C270.271 365.141 270.279 365.03 270.297 364.919C270.716 362.476 272.812 360.62 275.284 360.513C282.636 360.19 292.087 356.156 296.977 352.437C303.218 347.694 307.245 342.313 310.04 334.986C310.391 334.062 311.426 333.599 312.351 333.95C313.275 334.302 313.737 335.337 313.386 336.261C310.341 344.247 305.945 350.116 299.141 355.289C293.81 359.341 283.488 363.741 275.434 364.092C274.682 364.124 274.037 364.661 273.851 365.385L275.875 477.635C275.893 478.624 275.105 479.44 274.116 479.458C274.105 479.458 274.094 479.458 274.084 479.458L274.091 479.462Z"
      fill="#1D2C4D"
    />
    <path
      d="M307.483 364.622C316.156 363.134 322.798 359.667 322.32 356.876C321.841 354.085 314.423 353.028 305.75 354.515C297.077 356.002 290.435 359.47 290.913 362.261C291.392 365.052 298.81 366.109 307.483 364.622Z"
      fill="#BA9DF9"
    />
    <path
      d="M300.034 366.349C298.587 366.349 297.229 366.263 296 366.088C291.346 365.425 290.103 363.727 289.88 362.419C289.655 361.111 290.264 359.098 294.431 356.923C297.329 355.408 301.295 354.168 305.598 353.43C309.901 352.692 314.053 352.538 317.288 353C321.942 353.663 323.186 355.361 323.408 356.669C323.633 357.976 323.024 359.99 318.858 362.165C315.959 363.68 311.993 364.92 307.69 365.658C305.018 366.116 302.406 366.349 300.034 366.349ZM313.247 354.888C311.001 354.888 308.514 355.11 305.956 355.551C301.868 356.253 298.128 357.417 295.423 358.829C292.901 360.147 291.887 361.401 291.998 362.057C292.109 362.716 293.485 363.558 296.304 363.959C299.328 364.389 303.244 364.243 307.328 363.54C311.416 362.838 315.157 361.674 317.862 360.262C320.384 358.944 321.398 357.69 321.287 357.034C321.176 356.375 319.8 355.533 316.98 355.132C315.848 354.97 314.591 354.892 313.244 354.892L313.247 354.888Z"
      fill="#1D2C4D"
    />
    <path
      d="M303.835 224.46L264.229 231.251C263.244 231.42 262.582 232.355 262.751 233.34L283.858 356.433C284.027 357.418 284.963 358.079 285.947 357.91L325.553 351.119C326.538 350.95 327.2 350.015 327.031 349.03L305.924 225.937C305.755 224.952 304.82 224.291 303.835 224.46Z"
      fill="#BA9DF9"
    />
    <path
      d="M285.663 359.702C283.943 359.702 282.417 358.463 282.116 356.711L260.999 233.62C260.838 232.671 261.053 231.718 261.608 230.933C262.164 230.148 262.991 229.625 263.941 229.464L303.545 222.671C304.49 222.51 305.447 222.725 306.232 223.28C307.016 223.836 307.539 224.663 307.701 225.613L328.817 348.704C328.978 349.653 328.764 350.606 328.208 351.391C327.653 352.175 326.825 352.698 325.876 352.86L286.272 359.652C286.068 359.688 285.864 359.706 285.663 359.706V359.702ZM304.172 226.215L264.546 232.993L285.649 356.105L325.274 349.327C325.274 349.327 325.292 349.313 325.288 349.305L304.172 226.215Z"
      fill="#1D2C4D"
    />
    <path
      d="M294.145 226.122L273.918 229.59L295.637 356.249L315.864 352.781L294.145 226.122Z"
      fill="#E4D7FE"
    />
    <path
      d="M295.659 358.015C294.803 358.015 294.047 357.399 293.896 356.525L272.167 229.866C271.999 228.891 272.654 227.963 273.629 227.799L293.857 224.327C294.326 224.248 294.806 224.356 295.193 224.631C295.58 224.907 295.845 225.323 295.924 225.792L317.654 352.451C317.732 352.921 317.625 353.401 317.349 353.788C317.073 354.175 316.658 354.44 316.188 354.519L295.96 357.99C295.856 358.008 295.756 358.015 295.655 358.015H295.659ZM276.001 231.03L297.124 354.157L313.82 351.294L292.696 228.168L276.001 231.034V231.03Z"
      fill="#1D2C4D"
    />
    <path
      d="M254.962 20.916L242.498 23.0549L244.902 41.3553L248.342 48.1123L273.743 196.175L283.209 194.552L257.807 46.4858L258.796 38.9728L254.962 20.916Z"
      fill="#CCD4E5"
    />
    <path
      d="M273.743 197.966C273.374 197.966 273.012 197.851 272.708 197.636C272.321 197.36 272.056 196.945 271.977 196.476L246.622 48.6784L243.308 42.165C243.214 41.9823 243.154 41.7888 243.128 41.5846L240.724 23.2842C240.599 22.3312 241.251 21.4463 242.197 21.285L254.661 19.1497C255.607 18.9885 256.517 19.6012 256.718 20.5434L260.551 38.6002C260.594 38.8009 260.601 39.0051 260.576 39.2057L259.623 46.4535L284.979 194.251C285.147 195.225 284.491 196.153 283.517 196.318L274.051 197.941C273.951 197.959 273.85 197.966 273.747 197.966H273.743ZM246.64 40.8179L249.939 47.299C250.022 47.4602 250.079 47.6322 250.108 47.8078L275.205 194.107L281.138 193.09L256.041 46.7903C256.012 46.6147 256.008 46.432 256.03 46.2529L256.979 39.0409L253.569 22.9725L244.497 24.5274L246.636 40.8143L246.64 40.8179Z"
      fill="#1D2C4D"
    />
    <path
      d="M295.053 173.226L255.447 180.017C254.462 180.186 253.801 181.122 253.97 182.106L255.301 189.868C255.47 190.853 256.405 191.514 257.39 191.345L296.996 184.554C297.981 184.385 298.642 183.45 298.473 182.465L297.142 174.704C296.974 173.719 296.038 173.057 295.053 173.226Z"
      fill="#BA9DF9"
    />
    <path
      d="M257.083 193.143C256.345 193.143 255.625 192.917 255.012 192.48C254.228 191.925 253.705 191.097 253.543 190.148L252.211 182.388C251.874 180.431 253.196 178.565 255.152 178.228L294.756 171.435C296.712 171.099 298.579 172.417 298.915 174.377L300.248 182.137C300.409 183.086 300.194 184.039 299.639 184.824C299.084 185.608 298.256 186.132 297.307 186.293L257.703 193.086C257.499 193.121 257.291 193.139 257.087 193.139L257.083 193.143ZM295.383 174.986L255.758 181.764L257.072 189.546L296.698 182.767C296.698 182.767 296.715 182.753 296.712 182.746L295.379 174.986H295.383Z"
      fill="#1D2C4D"
    />
    <path
      d="M291.187 185.55L263.195 190.35L270.038 230.252L298.029 225.452L291.187 185.55Z"
      fill="#1D2C4D"
    />
    <path
      d="M270.047 232.023C269.19 232.023 268.434 231.406 268.284 230.532L261.437 190.632C261.358 190.162 261.466 189.682 261.742 189.295C262.018 188.908 262.433 188.643 262.903 188.564L290.895 183.764C291.869 183.595 292.797 184.251 292.962 185.225L299.808 225.126C299.887 225.595 299.78 226.075 299.504 226.462C299.228 226.849 298.813 227.114 298.343 227.193L270.351 231.994C270.247 232.012 270.147 232.019 270.047 232.019V232.023ZM265.271 191.793L271.512 228.164L295.971 223.969L289.73 187.597L265.271 191.793Z"
      fill="#1D2C4D"
    />
    <path
      d="M297.902 214.456L266.492 219.842L268.327 230.545L299.738 225.159L297.902 214.456Z"
      fill="#BA9DF9"
    />
    <path
      d="M268.341 232.317C267.484 232.317 266.728 231.701 266.578 230.827L264.74 220.121C264.661 219.652 264.769 219.172 265.045 218.785C265.32 218.398 265.736 218.133 266.205 218.054L297.615 212.666C298.085 212.587 298.565 212.694 298.952 212.97C299.339 213.246 299.604 213.662 299.683 214.131L301.517 224.836C301.685 225.811 301.03 226.739 300.055 226.903L268.645 232.292C268.541 232.31 268.441 232.317 268.341 232.317ZM268.574 221.286L269.802 228.458L297.68 223.675L296.451 216.503L268.574 221.286Z"
      fill="#1D2C4D"
    />
    <path
      d="M295.398 199.859L263.988 205.245L265.824 215.948L297.234 210.562L295.398 199.859Z"
      fill="#BA9DF9"
    />
    <path
      d="M265.836 217.717C265.467 217.717 265.105 217.603 264.801 217.388C264.414 217.112 264.149 216.696 264.07 216.227L262.235 205.522C262.067 204.547 262.723 203.619 263.697 203.455L295.107 198.066C295.576 197.987 296.057 198.095 296.443 198.371C296.83 198.647 297.096 199.062 297.174 199.532L299.009 210.237C299.177 211.211 298.521 212.139 297.547 212.304L266.137 217.692C266.037 217.71 265.936 217.717 265.832 217.717H265.836ZM266.069 206.686L267.298 213.859L295.175 209.076L293.946 201.903L266.069 206.686Z"
      fill="#1D2C4D"
    />
    <path
      d="M292.896 185.259L261.486 190.645L263.322 201.347L294.732 195.962L292.896 185.259Z"
      fill="#BA9DF9"
    />
    <path
      d="M263.332 203.118C262.963 203.118 262.601 203.003 262.297 202.788C261.91 202.512 261.645 202.097 261.566 201.627L259.731 190.922C259.563 189.948 260.219 189.02 261.193 188.855L292.603 183.467C293.073 183.388 293.553 183.495 293.94 183.771C294.326 184.047 294.592 184.463 294.67 184.932L296.505 195.637C296.673 196.612 296.018 197.539 295.043 197.704L263.633 203.093C263.533 203.111 263.432 203.118 263.329 203.118H263.332ZM263.565 192.087L264.794 199.259L292.671 194.476L291.442 187.304L263.565 192.087Z"
      fill="#1D2C4D"
    />
    <path
      d="M287.301 357.676L324.202 351.348L324.763 354.618C324.932 355.603 324.27 356.538 323.285 356.707L289.951 362.423C288.965 362.592 288.03 361.931 287.861 360.946L287.301 357.676Z"
      fill="#526080"
    />
    <path
      d="M289.665 363.504C288.286 363.504 287.064 362.512 286.824 361.107L286.262 357.836C286.161 357.252 286.555 356.697 287.139 356.597L324.042 350.266C324.321 350.216 324.611 350.284 324.844 350.449C325.077 350.614 325.235 350.864 325.285 351.144L325.847 354.415C326.116 355.984 325.059 357.478 323.494 357.747L290.16 363.465C289.995 363.493 289.83 363.508 289.669 363.508L289.665 363.504ZM288.562 358.531L288.942 360.742C289.01 361.14 289.389 361.412 289.791 361.344L323.125 355.626C323.526 355.558 323.795 355.178 323.726 354.777L323.347 352.566L288.565 358.531H288.562Z"
      fill="#1D2C4D"
    />
    <path
      d="M243.712 24.0401C246.101 37.9589 249.272 42.4265 252.156 41.9321C255.04 41.4377 256.541 36.1675 254.155 22.2487C254.152 22.2201 254.145 22.1878 254.141 22.1592L243.697 23.9505C243.701 23.9792 243.708 24.0114 243.712 24.0401Z"
      fill="#AFB8CC"
    />
    <path
      d="M302.785 228.3C299.03 229.787 290.822 234.677 286.078 238.543C275 247.059 279.618 262.221 285.566 263.031C288.737 263.382 292.889 261.519 295.644 260.38C303.458 257.141 306.414 256.012 313.651 251.437C321.024 246.776 331.805 233.047 327.061 223.757C323.396 217.624 323.156 211.967 299.406 213.231L301.334 223.858L304.694 223.399L302.785 228.3Z"
      fill="white"
    />
    <path
      d="M322.611 243.885C325.105 244.745 336.208 246.98 335.735 262.099C335.387 273.249 313.708 287.099 309.932 288.69C306.159 290.284 301.985 290.868 297.865 291.438C292.312 292.208 286.759 292.975 281.205 293.745C279.364 294 277.386 294.236 275.652 293.419C273.306 292.312 272.134 289.596 271.88 287.099C271.235 280.808 275.058 274.954 280.27 271.626C285.483 268.297 290.091 267.975 296.414 267.362C299.578 261.469 304.619 256.593 310.591 253.572"
      fill="white"
    />
    <path
      d="M330.311 272.938C333.711 275.367 336.978 279.788 336.717 288.132C336.369 299.281 314.69 313.132 310.914 314.723C307.141 316.317 302.967 316.901 298.847 317.47C293.294 318.241 289.317 319.183 282.101 320.731C280.281 321.121 278.257 320.641 276.634 319.455C274.542 317.922 272.997 314.966 272.743 312.469C272.098 306.178 275.09 300.951 280.303 297.622C285.516 294.294 291.545 292.055 297.865 291.442"
      fill="white"
    />
    <path
      d="M305.55 316.484C301.24 317.527 295.002 318.652 290.95 320.454C287.034 322.195 283.774 324.484 282.498 328.601C281.66 331.309 281.796 335.548 285.913 338.303C290.287 340.936 298.542 338.446 300.656 338.106C308.717 336.801 311.024 335.734 316.975 333.007C324.524 329.271 327.558 324.162 330.618 318.694C335.319 310.171 334.133 302.114 331.396 298.854"
      fill="white"
    />
    <path
      d="M301.759 247.526C300.445 245.437 297.75 244.559 295.364 245.415C291.491 246.802 285.741 249.729 286.386 254.924C286.998 259.846 289.331 260.91 289.331 260.91C289.331 260.91 291.799 262.387 302.849 256.805C305.048 255.694 303.547 250.37 301.759 247.526Z"
      fill="#CCD4E5"
    />
    <path
      d="M286.383 264.869C286.039 264.869 285.702 264.851 285.369 264.815C285.355 264.815 285.341 264.815 285.326 264.808C282.181 264.378 279.372 261.175 278.168 256.647C276.645 250.921 277.634 242.792 284.972 237.139C289.618 233.359 297.121 228.856 301.237 227.014C301.588 225.502 302.312 222.772 303.226 221.457C303.788 220.644 304.906 220.443 305.719 221.006C306.533 221.568 306.733 222.686 306.171 223.499C305.619 224.291 304.877 227.046 304.544 228.659C304.422 229.25 304.01 229.744 303.448 229.966C299.922 231.36 291.897 236.118 287.214 239.933C287.2 239.944 287.189 239.955 287.175 239.966C281.217 244.548 280.404 251.111 281.632 255.729C282.435 258.746 284.144 261.014 285.796 261.258C288.282 261.523 291.936 259.993 294.358 258.983L296.257 258.194C303.15 255.342 306.006 254.16 312.702 249.929C319.542 245.605 329.57 232.736 325.5 224.628C322.049 218.856 318.725 213.418 295.297 214.629C294.308 214.679 293.466 213.919 293.416 212.931C293.366 211.942 294.125 211.1 295.114 211.05C306.572 210.458 314.39 211.368 319.721 213.909C324.69 216.28 326.775 219.766 328.613 222.84C328.635 222.872 328.652 222.908 328.67 222.944C334.027 233.431 322.372 248.055 314.622 252.953C307.668 257.349 304.523 258.649 297.633 261.501L295.745 262.286C293.097 263.393 289.565 264.869 286.398 264.869H286.383Z"
      fill="#1D2C4D"
    />
    <path
      d="M293.827 281.819C292.437 279.644 289.384 278.677 286.69 279.419C282.624 280.536 277.146 282.768 277.464 287.186C277.765 291.331 279.779 292.571 279.779 292.571C279.779 292.571 281.979 294.237 294.246 291.134C297.105 290.41 295.762 284.843 293.827 281.819Z"
      fill="#CCD4E5"
    />
    <path
      d="M278.529 295.777C277.346 295.777 276.092 295.605 274.892 295.039C272.262 293.799 270.471 290.901 270.099 287.282C269.439 280.848 273.054 274.109 279.306 270.114C284.551 266.764 289.227 266.256 295.081 265.69C295.242 265.407 295.429 265.12 295.615 264.83C296.299 263.766 297.216 262.519 298.194 261.311C300.143 258.911 303.207 255.601 306.725 253.451C307.57 252.935 308.67 253.2 309.186 254.046C309.702 254.891 309.437 255.991 308.592 256.507C303.687 259.502 299.198 265.675 298.194 267.456C298.162 268.036 297.85 268.581 297.327 268.892C296.987 269.097 296.614 269.186 296.253 269.172L295.794 269.215C290.026 269.77 285.859 270.171 281.227 273.131C276.103 276.402 273.13 281.812 273.653 286.913C273.839 288.733 274.659 290.973 276.408 291.797C277.626 292.37 279.123 292.223 280.951 291.969L297.61 289.661C301.562 289.113 305.65 288.547 309.229 287.035C313.324 285.305 333.631 271.845 333.936 262.039C334.334 249.374 326.208 246.873 322.74 245.806C322.457 245.72 322.213 245.644 322.016 245.576C321.081 245.254 320.583 244.233 320.905 243.298C321.228 242.363 322.249 241.865 323.184 242.187C323.349 242.244 323.553 242.305 323.793 242.381C327.308 243.462 337.999 246.755 337.515 262.15C337.128 274.503 314.532 288.683 310.619 290.335C306.607 292.03 302.282 292.628 298.101 293.208L281.441 295.516C280.585 295.634 279.578 295.773 278.518 295.773L278.529 295.777Z"
      fill="#1D2C4D"
    />
    <path
      d="M296.611 308.345C295.365 306.142 292.57 304.816 289.912 305.26C285.337 306.027 277.852 308.252 278.834 314.041C279.228 316.37 280.034 317.889 280.808 318.857C281.628 319.881 282.993 320.34 284.305 320.028C286.881 319.419 292.147 318.18 297.332 316.976C299.528 316.463 298.231 311.208 296.608 308.341L296.611 308.345Z"
      fill="#CCD4E5"
    />
    <path
      d="M300.589 328.279C299.83 326.351 297.637 325.305 295.434 325.757C291.969 326.466 286.932 328.157 286.674 332.27C286.903 336.412 288.935 337.178 288.935 337.178C288.935 337.178 290.468 338.962 299.751 335.974C301.854 335.297 301.621 330.894 300.589 328.279Z"
      fill="#CCD4E5"
    />
    <path
      d="M280.807 322.657C278.987 322.657 277.149 322.048 275.576 320.898C273.176 319.143 271.277 315.75 270.962 312.651C270.277 305.976 273.33 299.947 279.342 296.109C284.985 292.505 291.333 290.273 297.693 289.653C298.674 289.557 299.552 290.277 299.649 291.262C299.746 292.247 299.025 293.121 298.04 293.218C292.24 293.784 286.439 295.826 281.269 299.126C276.372 302.254 273.978 306.926 274.527 312.282C274.742 314.381 276.103 316.839 277.694 318.007C278.908 318.895 280.417 319.257 281.728 318.978C282.882 318.731 283.953 318.498 284.96 318.283C290.327 317.122 293.873 316.352 298.603 315.696C302.554 315.148 306.642 314.582 310.222 313.07C314.317 311.34 334.624 297.879 334.928 288.073C335.125 281.818 333.219 277.214 329.271 274.391C328.465 273.814 328.279 272.697 328.855 271.89C329.432 271.084 330.55 270.898 331.356 271.475C336.34 275.036 338.744 280.657 338.511 288.185C338.124 300.538 315.528 314.718 311.615 316.37C307.603 318.064 303.278 318.663 299.097 319.243C294.5 319.881 291.007 320.637 285.719 321.78C284.709 321.998 283.638 322.231 282.48 322.478C281.932 322.596 281.373 322.654 280.811 322.654L280.807 322.657Z"
      fill="#1D2C4D"
    />
    <path
      d="M291.312 341.299C289.051 341.299 286.805 340.93 284.992 339.837C284.967 339.823 284.945 339.809 284.92 339.791C280.18 336.624 279.678 331.658 280.789 328.072C282.398 322.873 286.676 320.398 290.222 318.818C293.501 317.359 297.976 316.36 301.92 315.479C303.067 315.221 304.152 314.981 305.13 314.744C306.094 314.511 307.061 315.102 307.294 316.063C307.527 317.023 306.936 317.994 305.976 318.226C304.965 318.47 303.869 318.717 302.705 318.975C298.921 319.821 294.636 320.777 291.684 322.092C287.385 324.005 285.149 326.112 284.218 329.132C283.867 330.272 283.021 334.18 286.883 336.792C289.972 338.612 295.98 337.286 298.871 336.649C299.502 336.509 299.996 336.402 300.38 336.337C308.176 335.076 310.354 334.076 316.183 331.407L316.215 331.393C323.495 327.778 326.326 322.719 329.067 317.822C333.42 309.925 332.255 302.649 330.034 300.005C329.396 299.249 329.497 298.117 330.253 297.483C331.008 296.845 332.141 296.945 332.775 297.701C334.512 299.772 335.541 303.079 335.594 306.776C335.637 309.718 335.082 314.325 332.194 319.563C329.321 324.697 326.071 330.508 317.777 334.617C317.763 334.625 317.745 334.632 317.727 334.639L317.67 334.664C311.694 337.401 309.222 338.537 300.949 339.877C300.67 339.923 300.193 340.027 299.641 340.149C297.553 340.611 294.418 341.303 291.319 341.303L291.312 341.299Z"
      fill="#1D2C4D"
    />
    <path
      d="M253.299 202.774C253.299 202.774 255.957 203.709 259.089 207.142C260.468 208.653 262.858 208.6 264.133 207.002C266.734 203.738 270.449 198.912 271.252 197.013C272.517 194.022 270.532 190.969 267.01 190.013C267.01 190.013 262.112 192.736 253.299 202.778V202.774Z"
      fill="white"
    />
    <path
      d="M185.646 475.975C188.33 441.61 199.483 360.276 204.56 320.866C205.327 314.908 207.652 297.6 207.659 296.901C207.748 290.005 216.902 255.897 222.352 241.42C228.435 225.254 240.552 212.715 252.991 201.154C257.699 196.779 262.514 192.466 268.028 189.058C270.432 187.572 273.152 186.225 276.197 186.565C281.098 187.117 285.207 192.1 286.089 196.94C286.967 201.781 285.329 206.37 283.19 210.379C279.665 216.986 274.764 222.65 269.898 228.264C265.757 233.04 259.505 242.856 257.015 251.251"
      fill="white"
    />
    <path
      d="M253.299 202.774C253.299 202.774 255.957 203.709 259.089 207.142C260.468 208.653 262.858 208.6 264.133 207.002C266.734 203.738 270.449 198.912 271.252 197.013C272.517 194.022 270.532 190.969 267.01 190.013C267.01 190.013 262.112 192.736 253.299 202.778V202.774Z"
      fill="#CCD4E5"
    />
    <path
      d="M185.647 477.767C185.59 477.767 185.533 477.767 185.472 477.76C184.487 477.663 183.767 476.789 183.86 475.804C188.342 429.497 201.146 323.582 205.879 296.654C207.373 282.424 215.492 254.547 220.672 240.789C227.103 223.704 240.094 210.695 251.771 199.843C256.385 195.554 261.369 191.069 267.083 187.536C269.652 185.949 272.766 184.38 276.395 184.788C282.339 185.455 286.889 191.33 287.849 196.626C288.666 201.118 287.684 205.758 284.768 211.229C281.135 218.039 276.108 223.836 271.246 229.443C266.901 234.455 261.036 243.978 258.725 251.767C258.442 252.716 257.446 253.257 256.496 252.974C255.547 252.691 255.006 251.695 255.289 250.746C257.743 242.477 263.945 232.388 268.538 227.096C273.271 221.64 278.161 215.997 281.604 209.541C284.119 204.823 284.983 200.921 284.32 197.263C283.607 193.344 280.221 188.822 275.994 188.346C273.457 188.063 271.117 189.252 268.968 190.581C263.529 193.946 258.696 198.295 254.211 202.465C242.835 213.038 230.185 225.692 224.029 242.051C218.913 255.647 210.888 283.162 209.44 297.088C209.437 297.131 209.43 297.17 209.423 297.213C204.708 323.976 191.91 429.859 187.428 476.148C187.339 477.072 186.557 477.767 185.647 477.767Z"
      fill="#1D2C4D"
    />
    <path
      d="M235.064 481.608C213.994 481.608 193.476 480.114 175.727 477.291C174.749 477.137 174.082 476.216 174.24 475.241C174.394 474.263 175.315 473.597 176.289 473.755C193.855 476.549 214.18 478.029 235.064 478.029C247.675 478.029 268.008 476.685 278.595 475.779C279.58 475.696 280.447 476.424 280.533 477.413C280.619 478.401 279.884 479.265 278.899 479.351C268.248 480.261 247.787 481.611 235.064 481.611V481.608Z"
      fill="#1D2C4D"
    />
  </svg>
);
