import { FlexContainer, Modal, setColorTheme } from '@facephi/ui-react';
import styled from 'styled-components/macro';

export const ModalLogoutStyles = styled(Modal)`
  max-width: 36rem;

  div: nth-of-type(3) {
    button: first-of-type {
      color: ${setColorTheme('primary400')};
    }
  }
`;

export const ModalLogoutContent = styled(FlexContainer)`
  padding: 0 3.2rem 3.2rem;
`;
