import { FlexContainer, setColorTheme } from '@facephi/ui-react';
import styled from 'styled-components/macro';
import { desktopStyles } from '../../styles/utils';

export const Error404Container = styled(FlexContainer)`
  padding: 1.6rem;
  width: 100%;

  p {
    max-width: 45rem;
    margin: 2.4rem 0 1rem;

    :nth-of-type(2) {
      margin: 0 0 4rem;
    }
  }

  ${desktopStyles} {
    padding: 0;
  }

  button {
    width: 100%;
    background-color: ${setColorTheme('purple400')};

    ${desktopStyles} {
      width: auto;
    }
  }
`;
