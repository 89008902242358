import { ChangeEvent, useEffect, useState } from 'react';
import { useTracking } from '@facephi/sdk-web';
import {
  Button,
  DropdownFlags,
  FlexContainer,
  Label,
  RadioButton,
  RadioGroup,
  Spinner,
  useDeviceSize,
  useToast,
} from '@facephi/ui-react';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { RequestMethods, useLogger, useVariables } from '../../hooks';
import { useAuth } from '../../providers';
import {
  ModelingList,
  ServiceDto,
  ServiceSchema,
  TypeDocument,
  RoutesName,
} from '../../states';
import {
  documentTypeState,
  countryState,
  operationIdState,
  antifraudState,
  documentValidationState,
  governmentValidationState,
  livenessValidationState,
  previousOnboardingsValidationState,
  recordOnboardingState,
  signState,
  signTypeState,
  videoAssistanceState,
  videoAssistanceTypeState,
  newOperationIdState,
} from '../../states/atoms';
import { AuthName, DEFAULT_COUNTRY, endPoints } from '../../states/constants';
import { generateConfigObject } from '../../utils';
import { ServiceSection } from './ServiceSection';
import { ServicesWrapper, DropdownFlagsWrapper } from './Styles';

export const Services = () => {
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  const { isMobile } = useDeviceSize();
  const [loading, setLoading] = useState<boolean>(false);
  const [documentType, setDocumentType] = useRecoilState(documentTypeState);
  const [country, setCountry] = useRecoilState(countryState);
  const setOperationId = useSetRecoilState(operationIdState);
  const [newOperationId, setNewOperationId] =
    useRecoilState(newOperationIdState);
  const signature = useRecoilValue(signState);
  const signatureType = useRecoilValue(signTypeState);
  const previousOnboardings = useRecoilValue(
    previousOnboardingsValidationState
  );
  const governmentalValidation = useRecoilValue(governmentValidationState);
  const blacklistValidation = useRecoilValue(antifraudState);
  const documentValidation = useRecoilValue(documentValidationState);
  const liveness = useRecoilValue(livenessValidationState);
  const recordOnboarding = useRecoilValue(recordOnboardingState);
  const videoAssistance = useRecoilValue(videoAssistanceState);
  const videoAssistanceType = useRecoilValue(videoAssistanceTypeState);
  const { demoVersion, email } = useAuth();
  const { demoBackend } = useVariables();
  const { toastManager } = useToast();
  const { captureException } = useLogger();
  const { changeOperationId } = useTracking();

  useEffect(() => {
    setOperationId('');
    setNewOperationId('');
    changeOperationId('');
  }, [setOperationId]);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<ServiceDto>({
    resolver: yupResolver(ServiceSchema),
    defaultValues: {
      document: documentType,
      country: DEFAULT_COUNTRY,
    },
  });

  const getNewOperationId = () => {
    if (!newOperationId) {
      const configurationObject = generateConfigObject({
        customerId: email,
        country,
        documentType,
        videoAssistance,
        videoAssistanceType,
        recording: recordOnboarding,
        signature,
        signatureType,
        previousOnboardings,
        governmentalValidation,
        blacklistValidation,
        documentValidation,
        liveness,
      });
      axios({
        method: RequestMethods.post,
        url: `${demoBackend}${endPoints.Workflows.Start}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem(AuthName.token)}`,
          'x-demo-app-version': demoVersion,
          'x-source': 'web',
        },
        data: {
          ...configurationObject,
        },
      })
        .then((response) => {
          setNewOperationId(response.data?.operationId);
          navigate(RoutesName.workflow);
        })
        .catch((error) => {
          captureException(error as Error, { operation: 'Start Workflow' });
          toastManager({
            duration: 3500,
            type: 'error',
            canClose: true,
            message: t('Start workflow error'),
            testId: 'toast-start-workflow-error',
          });
          setLoading(false);
        });
    }
  };

  const onSubmit = () => {
    if (window.location.host.includes('pro')) {
      navigate(RoutesName.workflow);
    } else {
      setLoading(true);
      setTimeout(() => {
        getNewOperationId();
      }, 2000);
    }
  };

  const handleCountryChange = (value: string) => {
    if (country !== value) {
      setCountry(value as string);
    }
  };

  return (
    <FlexContainer alignItems="center">
      <ServicesWrapper
        flexDirection="column"
        rowGap={isMobile ? '3' : '2.5'}
        alignItems="center"
        flex="1"
      >
        <Label
          fontSize={isMobile ? '18' : '32'}
          semibold
          data-test="main_view_title_label"
          id="main_view_title_label"
        >
          {t('Make your onboarding')}
        </Label>
        <FlexContainer
          as="form"
          flexDirection="column"
          rowGap={isMobile ? '2.2' : '2.5'}
          alignItems="center"
          flex="1"
          onSubmit={handleSubmit(onSubmit)}
        >
          {/* Country */}
          <ServiceSection
            iconName="Globe"
            testName="main_view_country_selector_icon"
          >
            <Label
              fontSize={isMobile ? '16' : '18'}
              semibold
              id="main_view_country_selector_label"
              testId="main_view_country_selector_label"
            >
              {t('Which country is your document from')}
            </Label>
            <Controller
              {...register('country')}
              name="country"
              control={control}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              render={({ field }: any) => (
                <DropdownFlagsWrapper>
                  <DropdownFlags
                    {...field}
                    testId="service-country"
                    id="main_view_country_selector_text"
                    locale={i18n.language}
                    onChange={handleCountryChange}
                    errorLabel={
                      errors.country?.message && t(errors.country?.message)
                    }
                    value={country}
                    customOptions={[
                      {
                        icon: 'MapPin',
                        name: t('Autodetect'),
                        value: 'XXX',
                      },
                    ]}
                  />
                </DropdownFlagsWrapper>
              )}
            />
          </ServiceSection>
          {/* Document type */}
          <ServiceSection
            iconName="IdentificationCard"
            testName="main_view_document_selector_icon"
          >
            <Label
              fontSize={isMobile ? '16' : '18'}
              semibold
              id="main_view_document_selector_label"
              testId="main_view_document_selector_label"
            >
              {t('Which document will you use')}
            </Label>
            <RadioGroup
              {...register('document')}
              name="document"
              defaultSelected={documentType}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setDocumentType(e.currentTarget.value as TypeDocument)
              }
              testId="services-documents"
              id="main_view_document_selector_text"
            >
              {Object.entries(ModelingList).map(([id, item]) => (
                <RadioButton
                  key={id}
                  label={t(item.title)}
                  value={id}
                  disabled={!item.active}
                />
              ))}
            </RadioGroup>
          </ServiceSection>
          <Button
            size="L"
            fullWidth
            type="submit"
            disabled={!country || loading}
            testId="main_view_continue_button"
            color="primary"
          >
            {loading ? (
              <Spinner insideComponent size="S" />
            ) : (
              t('Continue').toUpperCase()
            )}
          </Button>
        </FlexContainer>
      </ServicesWrapper>
    </FlexContainer>
  );
};
