import {
  FlexContainer,
  Label,
  RadioGroup,
  setColorTheme,
} from '@facephi/ui-react';
import styled from 'styled-components/macro';
import { desktopStyles } from '../../styles';

export const ConfigurationWrapper = styled(FlexContainer)`
  padding: 1.6rem;
  width: 100%;

  ${desktopStyles} {
    padding: 4rem 0;
    width: 80rem;
  }
`;

export const ConfigurationCard = styled.section`
  background-color: ${setColorTheme('white')};
  border-radius: 1.2rem;
  overflow: hidden;
`;

const HEIGHT = '4rem';
const HEIGHT_MOBILE = '5.6rem';

export const ConfigurationCardHeader = styled(Label)`
  background-color: ${setColorTheme('grey200')};
  padding: 0.8rem 1.6rem;
  min-height: ${HEIGHT};
`;

export const ConfigurationCardElement = styled(FlexContainer)<{
  $boxPadding: string;
  $borderTop?: boolean;
}>`
  padding: ${({ $boxPadding }) => $boxPadding};
  ${({ $borderTop }) =>
    $borderTop && `border-top: solid 0.1rem ${setColorTheme('grey100')}`};
  min-height: ${HEIGHT_MOBILE};
  ${desktopStyles} {
    min-height: ${HEIGHT};
  }
`;

export const ConfigurationCardRadio = styled(RadioGroup)`
  row-gap: 0;

  li {
    padding: 0 1.6rem;
    align-items: center;
    min-height: ${HEIGHT_MOBILE};

    ${desktopStyles} {
      min-height: ${HEIGHT};
    }

    input[type='radio']:disabled + label {
      color: ${setColorTheme('grey300')};
    }
  }
`;
