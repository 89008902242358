/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useContext } from 'react';
import { useVariables } from '../hooks';

type IProps = {
  children: React.ReactNode;
};

type ContextProps = {
  setInspectletTag: (...args: unknown[]) => void;
};

declare global {
  interface Window {
    __insp: any;
    __inspld: any;
  }
}

const Ctx = createContext<ContextProps>({
  setInspectletTag: () => {},
});

export const LogProvider = ({ children }: IProps) => {
  const { inspectletId } = useVariables();

  const setInspectletTag = (...args: unknown[]) => {
    inspectletId ? window.__insp.push(...args) : null;
  };

  if (inspectletId) {
    window.__insp = window.__insp || [];
    setInspectletTag(['wid', inspectletId]);
    setTimeout(() => {
      if (typeof window.__inspld != 'undefined') return;
      window.__inspld = 1;
      const insp = document.createElement('script');
      insp.type = 'text/javascript';
      insp.async = true;
      insp.id = 'inspsync';
      insp.src = `${
        document.location.protocol
      }//cdn.inspectlet.com/inspectlet.js?wid=${inspectletId}&r=${Math.floor(
        new Date().getTime() / 3600000
      )}`;
      const x = document.getElementsByTagName('script')[0];
      x.parentNode?.insertBefore(insp, x);
    }, 0);
  }

  return (
    <Ctx.Provider
      value={{
        setInspectletTag,
      }}
    >
      {children}
    </Ctx.Provider>
  );
};

export const useLog = () => useContext(Ctx);
