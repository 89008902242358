import { Layout } from '../components';
import {
  PreviousOnboardings,
  ResultDetailWrapper,
} from '../components/results';

const ResultsPreviousOnboardings = () => {
  return (
    <Layout
      padding
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
    >
      <ResultDetailWrapper flexDirection="column">
        <PreviousOnboardings />
      </ResultDetailWrapper>
    </Layout>
  );
};

export default ResultsPreviousOnboardings;
