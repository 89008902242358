import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { TypeDocumentDTO } from '../../states';
import { documentTypeState } from '../../states/atoms';
import { BannerStyles } from './Styles';

type Props = {
  margin?: boolean;
};

export const Banner = ({ margin = true }: Props) => {
  const { t } = useTranslation();
  const documentType = useRecoilValue(documentTypeState);

  return (
    <BannerStyles
      fontSize="16"
      negative
      forwardedAs="span"
      $margin={margin}
      id="results_document_type_description_text"
      testId="results_document_type_description_text"
    >
      {t(`${TypeDocumentDTO[documentType]}`)}
    </BannerStyles>
  );
};
