import {
  Button,
  FlexContainer,
  GridContainer,
  InphiniteThemeColors,
  InphiniteThemeBaseColors,
  Label,
  setColorTheme,
  setSizeLabel,
} from '@facephi/ui-react';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components/macro';
import { FacialAuthenticationStatus, StatusTypeList } from '../../states';
import { desktopStyles, mobileStyles } from '../../styles';
import { Lottie } from '../lottie';

export const LinkStyles = styled(Link)`
  ${setSizeLabel('12')};
  color: ${setColorTheme('purple400')};
`;

export const ButtonLinkStyles = styled(Button)`
  text-decoration: underline;
  padding: 0;
  margin-bottom: -1.6rem;
`;

const opacityTransition = keyframes`{
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0.25;
  }
  40% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }`;

export const setStatusStyles = (status?: string, negative?: boolean) => {
  switch (status) {
    case 'NONE':
    case StatusTypeList.IN_PROGRESS:
      return `
        background-color: ${
          negative ? setColorTheme('grey100') : setColorTheme('white')
        };
    `;
    case 'POSITIVE':
    case StatusTypeList.SAVED:
    case StatusTypeList.SUCCEEDED:
    case StatusTypeList.VALIDATE:
      return `
        background-color: ${setColorTheme('success400')};

        *,
        p {
          color: ${setColorTheme('white')};
        }
      `;
    case 'UNCERTAIN':
    case 'EXPIRED':
    case 'CONNECTION_ERROR':
    case 'PENDING':
      return `
      background-color: ${setColorTheme('warning400')};

      *,
      p {
        color: ${setColorTheme('white')};
      }
    `;
    case 'NEGATIVE':
    case 'CANCELLED':
    case StatusTypeList.DENIED:
      return `
      background-color: ${setColorTheme('error400')};

      *,
      p {
        color: ${setColorTheme('white')};
      }
    `;
  }
};

export const ResultsWrapper = styled(FlexContainer)`
  padding: 1.6rem;
  width: 100%;

  ${desktopStyles} {
    padding: 4rem 1.6rem;
    width: 83.2rem;
    max-width: 100%;
  }
`;

export const ResultsInfoBar = styled(Label)<{
  background?: string;
  type?: string;
  $negative?: boolean;
}>`
  background-color: ${({ background }) => background};
  padding: 0.4rem;
  border-radius: 0.6rem;
  width: 100%;
  color: ${({ $negative }) => !$negative && setColorTheme('white')};

  ${({ type, $negative }) => setStatusStyles(type, $negative)}

  ${desktopStyles} {
    padding: 0.8rem;
  }
`;

export const ResultsButton = styled(Button)<{ $transparent?: boolean }>`
  text-transform: uppercase;
  ${({ $transparent }) => $transparent && 'background: transparent;'}
`;

export const ResultsDataWrapper = styled(FlexContainer)`
  ${desktopStyles} {
    > section {
      min-width: 32.6rem;
    }

    > div {
      width: 100%;
    }
  }
`;

export const ResultsData = styled.div`
  margin: 1.6rem 0 -1.6rem;

  ${desktopStyles} {
    min-width: 29.4rem;
    text-align: right;
  }
`;

export const ResultsDataImgWrapper = styled(FlexContainer)`
  overflow: auto;
  margin-right: -1.6rem;
  padding-top: 1.6rem;
  padding-right: 1.6rem;
  gap: 1.6rem;

  img {
    max-height: 13.4rem;
    border-radius: 0.8rem;
  }

  ${desktopStyles} {
    margin-right: 0;
    padding-right: 0;
    width: 100%;
  }
`;

export const ResultsDataImg = styled.div<{ bigImg?: boolean }>`
  text-align: left;

  ${desktopStyles} {
    text-align: center;

    img {
      max-height: unset;

      ${(props) =>
        props.bigImg
          ? `
            width: 18.5rem;
            aspect-ratio: 4/3;
            border-radius: 0.8rem;
          `
          : `
            width: 13rem;
            aspect-ratio: 1/1;
            object-fit: cover;
            border-radius: 3.2rem
          `}
    }
  }
`;

export const ResultsIconWrapper = styled(FlexContainer).attrs({
  alignItems: 'center',
  justifyContent: 'center',
})<{
  background?: InphiniteThemeColors;
}>`
  background-color: ${({ background }) =>
    background && setColorTheme(background)};
  border-radius: 50%;
  width: 2.8rem;
  min-width: 2.8rem;
  aspect-ratio: 1/1;
`;

export const InfoContainer = styled(FlexContainer)<{
  type?: FacialAuthenticationStatus;
}>`
  border-radius: 0.8rem;
  background-color: ${setColorTheme('grey100')};
  padding: 1.6rem;
  text-align: center;
  width: 100%;
  margin: 1.6rem 0;
  animation: ${opacityTransition} 0.8s ease-out 1;
  ${desktopStyles} {
    width: auto;
    max-width: 32.8rem;
  }

  ${({ type }) =>
    type === FacialAuthenticationStatus.positive.toUpperCase() &&
    `background-color: ${setColorTheme('success100')};
   p {
        color: ${setColorTheme('success500')};
      }
    `}
  ${({ type }) =>
    (type === FacialAuthenticationStatus.negative.toUpperCase() ||
      type === FacialAuthenticationStatus.invalidExtractions.toUpperCase() ||
      type === FacialAuthenticationStatus.pose.toUpperCase()) &&
    `background-color: ${setColorTheme('error100')};
     p {
        color: ${setColorTheme('error500')};
      }
    `}
  ${({ type }) =>
    type === FacialAuthenticationStatus.uncertain &&
    `background-color: ${setColorTheme('warning100')};
   p {
        color: ${setColorTheme('warning500')};
      }
    `}
`;

export const FacialContainer = styled(FlexContainer).attrs({
  flexDirection: 'column',
  justifyContent: 'center',
})`
  height: 100%;
  & > * {
    width: 15rem;
    ${mobileStyles} {
      width: 12rem;
    }
  }
  img {
    height: 15rem;
    object-fit: cover;
    border-radius: 3.2rem;
    ${mobileStyles} {
      height: 12rem;
    }
  }
`;

export const BannerStyles = styled(Label)<{ $margin?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.8rem;
  height: 3.2rem;
  background: ${setColorTheme('grey500')};
  border-radius: 0.6rem;
  width: 100%;
  text-transform: uppercase;
  margin: ${({ $margin }) => ($margin ? '1.2rem 0 1.6rem' : '0')};
`;

export const ResultDetailWrapper = styled(FlexContainer)`
  width: 100%;

  ${desktopStyles} {
    padding: 1.6rem 0;
    max-width: 80rem;
  }
`;

export const VerificationCardWrapper = styled(FlexContainer)`
  margin-top: 2.4rem;
`;

export const SelfieValidationResult = styled(FlexContainer)<{
  $generalColor?: InphiniteThemeBaseColors;
}>`
  background-color: ${({ $generalColor }) =>
    setColorTheme(`${$generalColor}100` as InphiniteThemeColors)}};
  padding: 1.2rem 1.6rem;
  border-radius: 0.8rem;
  margin: 0 auto;
  animation: ${opacityTransition} 0.8s ease-out 1;

  ${desktopStyles} {
    max-width: 32.4rem;
  }

  svg {
    min-width: 3.6rem;
    color: ${({ $generalColor }) =>
      setColorTheme(`${$generalColor}400` as InphiniteThemeColors)};
  }

  p {
    color: ${({ $generalColor }) =>
      setColorTheme(
        `${$generalColor}${
          $generalColor === 'grey' ? '700' : '500'
        }` as InphiniteThemeColors
      )};
  }
`;

export const AntiFraudImg = styled.img<{
  isSelfie?: boolean;
}>`
  aspect-ratio: ${(props) => (props.isSelfie ? '1/1' : '4/3')};
  width: 100%;
  border-radius: ${(props) => (props.isSelfie ? '3.2rem' : '0.8rem')};
  ${(props) => props.isSelfie && 'object-fit: cover'};
`;

export const SimpleResultsLottie = styled(Lottie)`
  max-height: 28rem;
  border-radius: 3.2rem;
  margin-bottom: 2.4rem;
  overflow: hidden;

  ${mobileStyles} {
    margin-bottom: 0.8rem;
  }
`;

export const FacialCheckGrid = styled(GridContainer)`
  align-self: center;

  img {
    max-width: 15rem;
  }

  .document-text {
    grid-column-start: 3;
  }

  .selfie-text {
    grid-column-start: 1;
  }

  .asset-text {
    align-self: start;
  }

  svg {
    animation: ${opacityTransition} 0.8s ease-out 1;
  }
`;
