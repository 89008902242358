import { CountryCode } from '@facephi/ui-react';
import countries from '../assets/json/countryCodes.json';

type CountriesType = {
  [key in string]: CountryCode;
};

const getCountries = () => countries as CountriesType;

export const getCountryISO3 = (code: string) => getCountries()[code];
