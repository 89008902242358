/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useOperationId } from '../hooks';
import { useAuth } from '../providers';
import { RoutesName } from '../states';
import { operationIdState } from '../states/atoms/operationId';

type IProps = {
  acceptedTerms?: boolean;
  operation?: boolean;
  operationProvider?: boolean;
  ownOperationId?: boolean;
};

export const ProtectedRoute = ({
  acceptedTerms,
  operation,
  ownOperationId,
}: IProps) => {
  const { logged, terms } = useAuth();
  const { checkOperationIdExist } = useOperationId();
  const { operationId: operationIdQuery } = useParams();
  const [operationId, setOperationId] = useRecoilState(operationIdState);

  const hasTerms = () => !acceptedTerms || terms;
  const hasOperation = () => !operation || !!operationId;
  const hasOperationId = () =>
    !ownOperationId || checkOperationIdExist(operationIdQuery);

  useEffect(() => {
    operationIdQuery && setOperationId(operationIdQuery);
  }, [operationIdQuery]);

  const getComponents = () => {
    return <Outlet />;
  };

  return (
    <>
      {logged ? (
        !hasTerms() ? (
          <Navigate to={RoutesName.terms} />
        ) : !hasOperation() || !hasOperationId() ? (
          <Navigate to={RoutesName.home} />
        ) : (
          getComponents()
        )
      ) : (
        <Navigate to={RoutesName.login} />
      )}
    </>
  );
};
