import { useState, useEffect } from 'react';
import {
  Button,
  FlexContainer,
  LogoOnboarding,
  useDeviceSize,
} from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../providers/AuthProvider';
import { RoutesName } from '../../states';
import { ModalLogout } from '../logout';
import { LanguageSelection } from './LanguageSelection';
import { HeaderStyles } from './Styles';

export const Header = () => {
  const { logout, logged, terms, initError } = useAuth();
  const { t } = useTranslation();

  const { isMobile } = useDeviceSize();
  const navigate = useNavigate();
  const location = useLocation();
  const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);
  const showConfigBtn = location.pathname === RoutesName.home;

  const handleLogout = () => {
    setShowLogoutModal(true);
  };

  const InnerLogo = () => <LogoOnboarding height="3.2rem" width="10.7rem" />;

  const handleConfigClick = () => {
    location.pathname !== RoutesName.terms &&
      navigate(RoutesName.configuration);
  };

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    if (initError) {
      navigate(RoutesName.noAuthorized);
      timeoutId = setTimeout(() => logout(), 3000);
    }
    return () => timeoutId && clearTimeout(timeoutId);
  }, [initError]);

  return (
    <>
      {logged && terms ? (
        <HeaderStyles
          forwardedAs="header"
          alignItems="center"
          justifyContent="space-between"
        >
          <>
            <Link to={RoutesName.home}>
              <InnerLogo />
            </Link>

            <FlexContainer
              alignItems="center"
              columnGap={!isMobile ? '1.6' : 'unset'}
            >
              {showConfigBtn && (
                <Button
                  color="white"
                  iconName="Gear"
                  variant="text"
                  testId="main_view_settings_button"
                  onClick={handleConfigClick}
                >
                  {!isMobile && t('Configuration')}
                </Button>
              )}
              <Button
                color="white"
                variant="text"
                onClick={handleLogout}
                testId="main_view_logout_button"
                iconName="SignOut"
              >
                {!isMobile && t('Exit')}
              </Button>
              <ModalLogout
                show={showLogoutModal}
                onChangeShow={(showModal) => setShowLogoutModal(showModal)}
                onLogout={logout}
              />
            </FlexContainer>
          </>
        </HeaderStyles>
      ) : (
        <LanguageSelection />
      )}
    </>
  );
};
