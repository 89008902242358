import { Icon, LogoFacephi } from '@facephi/ui-react';
import { SelfieSignHeaderWrapper, GraphicWrapper } from './Styles';

export const SlefieSignHeader = ({
  infoIcon,
  facephiLogo,
  readyToShow,
  onClose,
}: {
  infoIcon?: boolean;
  facephiLogo?: boolean;
  readyToShow: boolean;
  onClose?: () => void;
}) => {
  return (
    <>
      {readyToShow && (
        <SelfieSignHeaderWrapper
          justifyContent="space-between"
          alignItems="center"
        >
          <GraphicWrapper $hidden={!infoIcon}>
            <Icon iconName="Info" size="32" weight="light" />
          </GraphicWrapper>
          <GraphicWrapper $hidden={!facephiLogo}>
            <LogoFacephi black={facephiLogo} width="120" height="24" />
          </GraphicWrapper>
          <GraphicWrapper onClick={onClose}>
            <Icon iconName="X" size="32" weight="light" />
          </GraphicWrapper>
        </SelfieSignHeaderWrapper>
      )}
    </>
  );
};
