export const EnglandFlag = () => {
  return (
    <svg
      width="26"
      height="18"
      viewBox="0 0 26 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1219_56550)">
        <path d="M0 0H26V18H0V0Z" fill="#012169" />
        <path
          d="M3.04688 0L12.9594 6.7875L22.8312 0H26V2.325L16.25 9.0375L26 15.7125V18H22.75L13 11.2875L3.29062 18H0V15.75L9.70937 9.075L0 2.4V0H3.04688Z"
          fill="white"
        />
        <path
          d="M17.225 10.5375L26 16.5V18L14.9906 10.5375H17.225ZM9.75 11.2875L9.99375 12.6L2.19375 18H0L9.75 11.2875ZM26 0V0.1125L15.8844 7.1625L15.9656 5.5125L23.9688 0H26ZM0 0L9.70937 6.6H7.27187L0 1.575V0Z"
          fill="#C8102E"
        />
        <path
          d="M9.79062 0V18H16.2906V0H9.79062ZM0 6V12H26V6H0Z"
          fill="white"
        />
        <path
          d="M0 7.2375V10.8375H26V7.2375H0ZM11.0906 0V18H14.9906V0H11.0906Z"
          fill="#C8102E"
        />
      </g>
      <defs>
        <clipPath id="clip0_1219_56550">
          <rect width="26" height="18" rx="2" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
