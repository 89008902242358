import { useTranslation } from 'react-i18next';
import thumbDown from '../../assets/json/thumbDown.json';
import thumbUp from '../../assets/json/thumbUp.json';
import { useDeviceSize } from '../../hooks/useDeviceSize';
import { ValidStatusLabel, ValidStatusLottieWrapper } from './Styles';

export type ValidStatusProps = {
  message?: string;
  state: 'denied' | 'success';
  className?: string;
};

export const ValidStatus = ({
  message,
  state,
  className,
}: ValidStatusProps) => {
  const { t } = useTranslation();
  const { isMobile } = useDeviceSize();

  const animationHand = state === 'success' ? thumbUp : thumbDown;

  return (
    <>
      <ValidStatusLottieWrapper
        className={className}
        loop={true}
        animation={animationHand}
      />
      <ValidStatusLabel
        fontSize={isMobile ? '16' : '18'}
        semibold
        textAlign="center"
        $isError={state === 'denied'}
      >
        {t(`${message}`)}
      </ValidStatusLabel>
    </>
  );
};
