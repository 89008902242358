import React, { ReactElement } from 'react';
import { FlexContainer, IconWrapper, Label } from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { StatusOperations } from '../../states';
import { LinkStyles } from '../results';
import { CardGridSmallProps } from './model';
import {
  CardGridInfoBar,
  CardGridSmallHeader,
  CardGridSmallStatus,
  CardGridStyles,
  CardResultsHeaderTitle,
} from './Styles';

export const CardGridSmall = ({
  title,
  className,
  icon,
  gridColumn,
  gridRow,
  alignSelf,
  order,
  message,
  iconStatus,
  loading,
  navigateTo,
  status,
  testId,
}: CardGridSmallProps) => {
  const { t } = useTranslation();
  return (
    <CardGridStyles
      className={className}
      $gridColumn={gridColumn}
      $gridRow={gridRow}
      $alignSelf={alignSelf}
      order={order}
      title={title}
      testId={testId}
    >
      <>
        <CardGridSmallHeader justifyContent="space-between">
          {/* Message */}
          <FlexContainer flexDirection="column" rowGap="0.8">
            <CardResultsHeaderTitle>{title}</CardResultsHeaderTitle>
            <Label fontSize="21" semibold upperCase>
              {t(message)}
            </Label>
          </FlexContainer>
          {/* Status Icon */}
          <CardGridSmallStatus>
            <IconWrapper
              iconName={icon}
              size="XL"
              shape="circle"
              color={'purple'}
              invertBackground={icon !== 'SerproIcon'}
            />
            {iconStatus &&
              React.cloneElement(iconStatus as ReactElement, {
                className: 'status-icon',
              })}
          </CardGridSmallStatus>
        </CardGridSmallHeader>

        <CardGridInfoBar
          justifyContent={loading || !status ? 'flex-end' : 'space-between'}
          $type={loading ? 'loading' : status}
          testId="card-grid-info-bar"
        >
          {!loading && status && (
            <Label fontSize="16" semibold upperCase testId={status}>
              {t(`VALIDATION_${status}`)}
            </Label>
          )}
          {navigateTo &&
            status !== StatusOperations.CONNECTION_ERROR &&
            !loading && (
              <LinkStyles to={navigateTo}>{t('See more')}</LinkStyles>
            )}
        </CardGridInfoBar>
      </>
    </CardGridStyles>
  );
};
