import { FlexContainer, Label, Map, setColorTheme } from '@facephi/ui-react';
import Skeleton from 'react-loading-skeleton';
import { CardGridMapProps } from './model';
import {
  CardGridInfoBar,
  CardGridSmallHeader,
  CardGridStyles,
  CardResultsContent,
  CardResultsHeaderTitle,
} from './Styles';

export const CardGridMap = ({
  title,
  iconStatus,
  gridColumn,
  gridRow,
  alignSelf,
  order,
  pending,
  coordinates,
  message,
}: CardGridMapProps) => (
  <CardGridStyles
    $gridColumn={gridColumn}
    $gridRow={gridRow}
    $alignSelf={alignSelf}
    order={order}
  >
    <CardGridSmallHeader flexDirection="column">
      <FlexContainer justifyContent="space-between" alignItems="center">
        <CardResultsHeaderTitle>{title}</CardResultsHeaderTitle>
        {iconStatus ? iconStatus : null}
      </FlexContainer>
      <CardResultsContent flexDirection="column">
        {pending ? (
          <Skeleton
            height={215}
            borderRadius={8}
            style={{ margin: '1.6rem 0' }}
          />
        ) : (
          <Map markers={coordinates} />
        )}
      </CardResultsContent>
    </CardGridSmallHeader>

    {pending && (
      <CardGridInfoBar
        background={setColorTheme('grey400')}
        flexDirection="column"
        alignItems="start"
      >
        <>
          <Skeleton
            height={14}
            width={70}
            borderRadius={8}
            style={{ margin: '0.4rem 0 1rem' }}
          />
          <Skeleton
            height={14}
            width={150}
            borderRadius={8}
            style={{ margin: '0 0 0.4rem' }}
          />
        </>
      </CardGridInfoBar>
    )}
    {!pending && message && (
      <CardGridInfoBar
        background={setColorTheme('grey400')}
        flexDirection="column"
        alignItems="start"
      >
        <Label fontSize="16" semibold negative>
          {message}
        </Label>

        <FlexContainer columnGap="0.4" flexDirection="row">
          <Label fontSize="14" negative>
            Lat: {coordinates ? coordinates[0].lat : '-'}
          </Label>
          <Label fontSize="14" negative>
            Lng: {coordinates ? coordinates[0].lng : '-'}
          </Label>
        </FlexContainer>
      </CardGridInfoBar>
    )}
  </CardGridStyles>
);
